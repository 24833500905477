import { actionTypes } from './actionTypes';
import { AppDispatch } from '../store/store';
import { ApiResponseExportPdf } from '../types/store/pdfTypes';
import { callApi } from '../common/api';
import { lockCalculation } from './calculationActions';

export const exportPdf = (calculationId: string, brandId: number, shouldApplyWatermark: boolean) => {
  return async (dispatch: AppDispatch) => {
    const request = {
      filename: `${calculationId}.pdf`,
      calculationId: calculationId,
      showWatermark: shouldApplyWatermark,
    };

    const data = await callApi<ApiResponseExportPdf>(dispatch, 'POST', `/PDF/${calculationId}/${brandId}`, request);

    if (!data) return;

    if (data.uploadSuccess) {
      dispatch(lockCalculation(calculationId));
    }

    dispatch({
      type: actionTypes.EXPORT_PDF,
      payload: {
        export: {
          uploadSuccess: data.uploadSuccess,
          error: data.error,
          url: data.url,
          pdfFile: data.pdfFile,
          fileName: `${calculationId}.pdf`,
          calculationId: calculationId,
        },
      },
    });
  };
};
