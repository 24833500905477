import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Header } from '../Header/Header';
import styles from './Layout.module.scss';
import { apiSelector, currentCalculationBusinessUnitIdSelector, userProfileSelector } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { UnauthenticatedLandingPage } from '../LandingPages/UnauthenticatedLandingPage/UnauthenticatedLandingPage';
import { AccessDeniedLandingPage } from '../LandingPages/AccessDeniedLandingPage/AccessDeniedLandingPage';
import { AuthorisingLandingPage } from '../LandingPages/AuthorisingLandingPage/AuthorisingLandingPage';
import { fetchMasterData } from '../../actions/masterDataActions';
import { fetchUserProfile } from '../../actions/userProfileActions';
import { UserProfileState } from '../../types/store/userProfileTypes';
import { checkIsPowerUser } from '../../common/userProfile';

type Props = {
  children: ReactNode;
};

export function Layout({ children }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);


  const userProfile: UserProfileState = useSelector(userProfileSelector);
  const currentCalculationBusinessUnitId = useSelector(currentCalculationBusinessUnitIdSelector);
  const isPowerUser = checkIsPowerUser(userProfile);

  React.useEffect(() => {
    (async () => {
      if (userProfile && currentCalculationBusinessUnitId) {
        try {
          await dispatch(fetchMasterData(currentCalculationBusinessUnitId));
        } catch (e) {
          const status = (e as any)?.response?.status;

          const message = status === 400 /* Bad Request */
            ? ((e as any)?.response?.data?.message ?? 'Unknown failure while fetching master data.')
            : isPowerUser
              ? 'Unable to fetch master data, please try uploading the previous valid set or contact support for more information.'
              : 'Unable to fetch master data, please contact your system administrator.';

          enqueueSnackbar(
            message,
            {
              variant: 'error',
              persist: true,
              TransitionComponent: Fade,
            }
          );
        }
      }
    })();
  }, [dispatch, enqueueSnackbar, userProfile, currentCalculationBusinessUnitId, isPowerUser]);

  const { isFirstCallSuccessful, isAuthenticated, isAuthorised } = useSelector(apiSelector);

  /**
   * This covers the scenario where the frontend flow is happy that the user is authenticated
   * but the API throws an authentication error. This is unlikely to happen.
   */
  if (!isAuthenticated) {
    return <UnauthenticatedLandingPage />;
  }

  /**
   * This covers the scenario where both the frontend and API are happy that the user is
   * authenticated but they are not in the correct group(s) to be given access to the app.
   */
  if (!isAuthorised) {
    return <AccessDeniedLandingPage />;
  }

  return (
    <React.Fragment>
      {/**
       * This covers the scenario where the user is authenticated but we're waiting to hear back
       * from the API about authorisation.
       */
      !isFirstCallSuccessful && <AuthorisingLandingPage />}

      <Container fixed disableGutters>
        <div className={styles.page}>
          <Container>
            <Header />

            <div data-qa-id="pageContent" className={styles.content}>
              {children}
            </div>
          </Container>
        </div>
      </Container>
    </React.Fragment>
  );
}
