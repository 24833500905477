import React from 'react';

import { LandingPage } from '../../LandingPage/LandingPage';

export const AccessDeniedLandingPage = () => (
  <LandingPage
    title="K.I.C.S. — Access Denied"
    content="You do not have permission to access this application. Please contact the application owner to request access."
    qaIdBase="landingPageAccessDenied"
  />
);
