import { actionTypes } from './actionTypes';
import { AppDispatch } from '../store/store';
import {
  ApiMasterDataLayer,
  MasterDataLayer,
  ApiMasterDataBridgeMaterial,
  MasterDataBridgeMaterial,
  ApiMasterDataMechanicalFasteners,
  MasterDataMechanicalFasteners,
  ApiMasterDataApplicationType,
  MasterDataApplicationType,
  ApiMasterData,
  ApiMasterDataSoilType,
  MasterDataSoilType,
  MasterDataBuildingRegion,
  ApiMasterDataBuildingRegion,
  MasterDataBuildingType,
  ApiMasterDataBuildingType,
  MasterDataRiskLevel,
  ApiMasterDataRiskLevel,
  ApiMasterDataDrainageFx,
  MasterDataDrainageFx,
  ApiMasterDataPrecipitationByPostCodeArea,
  MasterDataPrecipitationByPostCodeArea,
  ApiMasterDataRoofCharacteristics,
  MasterDataRoofCharacteristics,
  MasterData,
  getEmptyMasterData,
} from '../types/store/masterDataTypes';
import { callApi } from '../common/api';

export const fetchMasterData = (businessUnitId: number) => {
  return async (dispatch: AppDispatch) => {
    try {

      dispatch({
        type: actionTypes.SET_IS_LOADING_MASTER_DATA,
        payload: { isLoadingMasterData: true },
      });

      const masterData = await fetchMasterDataFromApi(businessUnitId, dispatch);

      dispatch({
        type: actionTypes.FETCH_MASTER_DATA,
        payload: { masterData: masterData },
      });

    } finally {
      dispatch({
        type: actionTypes.SET_IS_LOADING_MASTER_DATA,
        payload: { isLoadingMasterData: false },
      });
    }
  };
};

async function fetchMasterDataFromApi(businessUnitId: number, dispatch: AppDispatch): Promise<MasterData> {
  console.info(`Fetching Master Data for Business Unit ${businessUnitId}...`);

  const data = await callApi<ApiMasterData>(dispatch, 'GET', `/MasterData/${businessUnitId}`);

  if (!data) {
    console.warn(`Failed to fetch Master Data for Business Unit ${businessUnitId}`);
    return getEmptyMasterData();
  }

  console.info(`Fetched Master Data for Business Unit ${businessUnitId}`);

  const masterLayers = data.layers.map(
    (layer: ApiMasterDataLayer): MasterDataLayer => ({
      id: layer.id,
      isActive: layer.isActive,
      name: layer.name,
      shortCode: layer.shortCode,
      thermalConductivity: layer.thermalConductivity,
      defaultThickness: layer.thicknessMillimetres,
      insideEmissivity: layer.insideEmissivity,
      outsideEmissivity: layer.outsideEmissivity,
      layerMaterialType: layer.layerMaterialType,
      isCustom: false,
      vapourResistance: layer.vapourResistance,
      vapourResistivity: layer.vapourResistivity,
      isFloorInsulation: layer.isFloorInsulation,
      vr1: layer.vr1,
      vr2: layer.vr2,
      vr3: layer.vr3,
      vr4: layer.vr4,
      vrBreak1: layer.vrBreak1,
      vrBreak2: layer.vrBreak2,
      vrBreak3: layer.vrBreak3,
      bridgeMaterial: layer.bridgeMaterial
        ? {
            id: layer.bridgeMaterial.id,
            name: layer.bridgeMaterial.name,
            bridgeDescription: layer.bridgeMaterial.bridgeDescription,
            bridgeThermalConductivity: layer.bridgeMaterial.bridgeThermalConductivity,
            defaultBridgeWidth: layer.bridgeMaterial.bridgeWidthMillimetres,
            defaultCentresDistance: layer.bridgeMaterial.centresDistanceMillimetres,
            defaultNonBridgeHeight: layer.bridgeMaterial.nonBridgeHeightMillimetres,
          }
        : null,
    })
  );

  const floorMasterLayers = masterLayers.filter(layer => layer.isFloorInsulation);

  return {
    businessUnitId: data.businessUnitId,
    layers: masterLayers,
    floorLayers: floorMasterLayers,
    bridgeMaterials: data.bridgeMaterials.map(
      (material: ApiMasterDataBridgeMaterial): MasterDataBridgeMaterial => ({
        id: material.id,
        name: material.name,
        bridgeDescription: material.bridgeDescription,
        bridgeThermalConductivity: material.bridgeThermalConductivity,
        defaultBridgeWidth: material.bridgeWidthMillimetres,
        defaultCentresDistance: material.centresDistanceMillimetres,
        defaultNonBridgeHeight: material.nonBridgeHeightMillimetres,
      })
    ),
    mechanicalFasteners: data.mechanicalFasteners.map(
      (fastener: ApiMasterDataMechanicalFasteners): MasterDataMechanicalFasteners => ({
        id: fastener.id,
        name: fastener.name,
        defaultCrossArea: fastener.crossSectionalAreaMillimetresSquared,
        defaultNumber: fastener.fastenersPerMetreSquared,
      })
    ),
    airGapsCorrectionLevels: {
      level0: {
        level: data.airGapsCorrectionRecords.level0.level,
        value: data.airGapsCorrectionRecords.level0.value,
      },
      level1: {
        level: data.airGapsCorrectionRecords.level1.level,
        value: data.airGapsCorrectionRecords.level1.value,
      },
      level2: {
        level: data.airGapsCorrectionRecords.level2.level,
        value: data.airGapsCorrectionRecords.level2.value,
      },
    },
    applications: data.applications.map(
      (applicationType: ApiMasterDataApplicationType): MasterDataApplicationType => ({
        id: applicationType.id,
        name: applicationType.name,
        type: applicationType.type,
      })
    ),
    buildingRegions: data.buildingRegions.map(
      (buildingRegion: ApiMasterDataBuildingRegion): MasterDataBuildingRegion => ({
        id: buildingRegion.id,
        name: buildingRegion.name,
      })
    ),
    buildingTypes: data.buildingTypes.map(
      (buildingType: ApiMasterDataBuildingType): MasterDataBuildingType => ({
        id: buildingType.id,
        name: buildingType.name,
      })
    ),
    riskLevels: data.riskLevels.map(
      (riskLevel: ApiMasterDataRiskLevel): MasterDataRiskLevel => ({
        id: riskLevel.id,
        name: riskLevel.name,
      })
    ),
    soilTypes: data.soilTypes.map(
      (soilType: ApiMasterDataSoilType): MasterDataSoilType => ({
        id: soilType.id,
        description: soilType.description,
        lambda: soilType.lambda,
        vapourResistivity: soilType.vapourResistivity,
      })
    ),
    drainageFx: data.drainageFx.map(
      (drainageFxInput: ApiMasterDataDrainageFx): MasterDataDrainageFx => ({
        id: drainageFxInput.id,
        covering: drainageFxInput.covering,
        fxFactor: drainageFxInput.fxFactor,
      })
    ),
    precipitationByPostCodeArea: data.precipitationByPostCodeArea.map(
      (precipitation: ApiMasterDataPrecipitationByPostCodeArea): MasterDataPrecipitationByPostCodeArea => ({
        id: precipitation.id,
        postCodeArea: precipitation.postCodeArea,
        rainfallMillimetres: precipitation.rainfallMillimetres,
      })
    ),
    rainscreenCladdingDefaults: {
      chiValue: data.rainscreenCladdingDefaults.chiValue,
      bracketsPerMetreSquared: data.rainscreenCladdingDefaults.bracketsPerMetreSquared,
    },
    buildingUsage: data.buildingUsage,
    projectRegion: data.projectRegion,
    branding: data.brands,
    roofCharacteristicsOptions: data.roofCharacteristicsOptions.map(
      (option: ApiMasterDataRoofCharacteristics): MasterDataRoofCharacteristics => ({
        id: option.id,
        description: option.description,
        roofSpaceThermalResistance: option.roofSpaceThermalResistance,
      })
    ),
    salesContactTeams: data.salesContactTeams,
    projectCountyStates: data.projectCountyStates,
    projectStages: data.projectStages,
    productSectors: data.productSectors,
    calculationTitles: data.calculationTitles,
  };
}
