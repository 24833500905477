import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createNewCalculation } from '../../actions/calculationActions';
import { userProfileSelector } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { BusinessUnitDropdown } from '../FormComponents/BusinessUnitDropdown/BusinessUnitDropdown';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './NewCalculationModal.module.scss';

type Props = {
  onClose: (forceWelcome?: boolean) => void;
  canClose: boolean;
};

const none = -1;

export function NewCalculationModal({ onClose, canClose }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const userProfile = useSelector(userProfileSelector);
  const [businessUnitId, setBusinessUnitId] = useState(userProfile?.primaryBusinessUnitId ?? none);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = async () => {
    setIsCreating(true);
    try {
      await dispatch(createNewCalculation(businessUnitId));
      onClose();
    } catch (e) {
      console.error('createNewCalculation client/server occured', e);
      setIsCreating(false);
    }
  };

  return (
    <Dialog
      open
      onClose={() => onClose}
      aria-labelledby="modal-new-calculation-title"
      disableBackdropClick={!canClose}
      disableEscapeKeyDown={!canClose}
    >

      <div className={modalStyles.modalHeader}>
        <DialogTitle id="modal-new-calculation-title">
          <Typography component="span" variant="h5">Create Calculation</Typography>
        </DialogTitle>
      </div>

      <div className={`${[modalStyles.modalContent, styles.modalContent].join(' ')}`}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <BusinessUnitDropdown
                businessUnits={userProfile?.useableBusinessUnits}
                businessUnitId={businessUnitId}
                setBusinessUnitId={setBusinessUnitId}
                disabled={isCreating}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>

      <div className={modalStyles.modalActions}>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            {canClose && (
              <Grid container item xs={2} justify="flex-end">
                <Button
                  data-qa-id="newCalculationCloseButton"
                  disabled={isCreating}
                  onClick={() => onClose(true)}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
            )}

            <Grid item xs={3}>
              <Button
                type="button"
                data-qa-id="newCalculationSubmitButton"
                fullWidth
                disabled={isCreating || ((businessUnitId ?? none) === none)}
                onClick={handleCreate}
                color="primary"
                variant="contained"
                startIcon={isCreating && <CircularProgress size={20} />}
                autoFocus
              >
                Create New
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>

    </Dialog>
  );
}
