import React from 'react';
import { Tabs, Tab, Fade } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClipboardIcon from '@material-ui/icons/Assignment';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import styles from './CalculationNavigation.module.scss';

type Props = {
  items: string[];
  currentItem?: string;
  handleChange: (_: any, selectedItem: string) => void;
  handleTabClose: () => void;
  handleTabCopy: () => void;
};

export function CalculationNavigation({ items, currentItem, handleChange, handleTabClose, handleTabCopy }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleTabClick = (item: string) => {
    copy(item);

    enqueueSnackbar(
      <span>
        <ClipboardIcon className={styles.clipboardIcon} />
        Copied to clipboard
      </span>,
      { variant: 'default', autoHideDuration: 750, TransitionComponent: Fade }
    );
  };

  const getTabContent = (item: string) => {
    const isSelected = item === currentItem;

    return (
      <div className={`${styles.tabContent} ${isSelected ? styles.tabContentSelected : ''}`}>
        <div className={styles.tabLabel} data-qa-id="calculationTabLabel" onDoubleClick={() => handleTabClick(item)}>
          {item}
        </div>

        {isSelected && (
          <React.Fragment>
            <div className={styles.tabCopyButtonContainer}>
              <FileCopyIcon className={styles.tabCopyButton} data-qa-id="calculationCopyButton" onClick={() => handleTabCopy()} />
            </div>

            <div className={styles.tabCloseButtonContainer}>
              <CancelIcon className={styles.tabCloseButton} data-qa-id="calculationCloseButton" onClick={() => handleTabClose()} />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Tabs value={currentItem || false} onChange={handleChange as any} scrollButtons="auto" textColor="primary" variant="scrollable">
        {items.map((item, index) => (
          <Tab key={item} value={item} label={getTabContent(item)} disableRipple data-qa-id={`calculation-tab-${index}`} />
        ))}
      </Tabs>
    </div>
  );
}
