import { useDispatch, useSelector } from "react-redux";
import { Contact } from "../../types/store/contactTypes";
import { currentCalculationBusinessUnitIdSelector, interimOrCurrentCalculationSelector, salesContactTeamSelector } from "../../store/selectors";
import React, { ChangeEvent, FormEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import modalStyles from '../LayerModal/LayerModal.module.scss'
import styles from './ContactInfoModal.module.scss';
import { Alert, Autocomplete, CreateFilterOptionsConfig, createFilterOptions } from "@material-ui/lab";
import { useDebounce } from "../Utilities/hooks/useDebounce";
import { callApi } from "../../common/api";
import { associateContact } from "../../actions/contactActions";

type Props = {
  onClose: () => void;
}

type State = {
  newContact: Contact | null;
  existingContact: Contact | null;
}

export type ContactType = {
  id: number;
  typeName: string;
}

const config: CreateFilterOptionsConfig<Contact> = {
  stringify: (option: Contact) => option.email,
};

const filter = createFilterOptions<Contact>(config);

export function ContactInfoModal({ onClose }: Props) {
  const dispatch = useDispatch();

  const calculation = useSelector(interimOrCurrentCalculationSelector);
  const currentCalculationBusinessUnitId: number | null = useSelector(currentCalculationBusinessUnitIdSelector);
  const salesContactTeams = useSelector(salesContactTeamSelector);

  const contactTypes: ContactType[] = [
    {"id": 1, "typeName": "Account Manager"},
    {"id": 2, "typeName": "Architect"},
    {"id": 3, "typeName": "Architectural Technologist"},
    {"id": 4, "typeName": "BER Assessor"},
    {"id": 5, "typeName": "Builder"},
    {"id": 6, "typeName": "Building Control"},
    {"id": 7, "typeName": "BUILDING STANDARDS"},
    {"id": 8, "typeName": "Civil Engineering (Infrastructure)"},
    {"id": 9, "typeName": "Civil Engineering (Utilities)"},
    {"id": 10, "typeName": "Community and Amenity"},
    {"id": 11, "typeName": "Construction Engineer"},
    {"id": 12, "typeName": "Consultant"},
    {"id": 13, "typeName": "Contractor"},
    {"id": 14, "typeName": "CPD Coordinator/Contact"},
    {"id": 15, "typeName": "Customer"},
    {"id": 16, "typeName": "Design Manager"},
    {"id": 17, "typeName": "Development Manager"},
    {"id": 18, "typeName": "Director"},
    {"id": 19, "typeName": "Distributor"},
    {"id": 20, "typeName": "Ductwork Installer"},
    {"id": 21, "typeName": "Education"},
    {"id": 22, "typeName": "End user / Owner"},
    {"id": 23, "typeName": "Energy Expert"},
    {"id": 24, "typeName": "Engineer"},
    {"id": 25, "typeName": "Fire consultant"},
    {"id": 26, "typeName": "Fire Expert"},
    {"id": 27, "typeName": "Group Purchasing Manager"},
    {"id": 28, "typeName": "Heritage Conservationist"},
    {"id": 29, "typeName": "Hotel and Leisure"},
    {"id": 30, "typeName": "Industrial"},
    {"id": 31, "typeName": "Koolduct Installer"},
    {"id": 32, "typeName": "Main Contractor"},
    {"id": 33, "typeName": "Mechanical Engineer"},
    {"id": 34, "typeName": "Medical and Scientific"},
    {"id": 35, "typeName": "Merchant"},
    {"id": 36, "typeName": "Moisture consultant"},
    {"id": 37, "typeName": "Offices/Commercial"},
    {"id": 38, "typeName": "Other"},
    {"id": 39, "typeName": "Price List Updates"},
    {"id": 40, "typeName": "Private Housing"},
    {"id": 41, "typeName": "Production"},
    {"id": 42, "typeName": "Production Director"},
    {"id": 43, "typeName": "Project Manager"},
    {"id": 44, "typeName": "Promoter"},
    {"id": 45, "typeName": "Property Services"},
    {"id": 46, "typeName": "Prospect"},
    {"id": 47, "typeName": "Purchasing Manager"},
    {"id": 48, "typeName": "Quantity Surveyor"},
    {"id": 49, "typeName": "Remodeler"},
    {"id": 50, "typeName": "Retail"},
    {"id": 51, "typeName": "Roofing Contractor"},
    {"id": 52, "typeName": "Social Housing"},
    {"id": 53, "typeName": "Specifier"},
    {"id": 54, "typeName": "Sub-Contractor"},
    {"id": 55, "typeName": "Supplier"},
    {"id": 56, "typeName": "Surveyor"},
    {"id": 57, "typeName": "System Suppliers"},
    {"id": 58, "typeName": "TEK Designer"},
    {"id": 59, "typeName": "Thermal Engineer"}
  ];

  const defaultState: State = {
    newContact: {
      id: null,
      businessUnitId: currentCalculationBusinessUnitId,
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      contactType: '',
      businessPhone: '',
      mobilePhone: '',
      email: '',
      salesContactTeam: '',
      salesContactTeamValue: '',
      companyPostCode: '',
    },
    existingContact: null,
  };

  const [state, setState] = React.useState<State>(defaultState);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [existingContact, setExistingContact] = React.useState<Contact[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  const disableButtons = calculation?.locked || (state.newContact ? !state.newContact.email : !state.existingContact?.email);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const lookupContactType = (contactType: string) => {
    return contactTypes.find(ct => ct.typeName === contactType)?.id ?? contactTypes[0].id;
  }

  const updateField = (fieldName: string, value: string) => {
    setState(currentState => ({
      ...currentState,
      ...(state.newContact
        ? {
          newContact: {
            ...currentState.newContact!,
            [fieldName]: value,
          },
        }
        : {
          existingContact: {
            ...currentState.existingContact!,
            [fieldName]: value,
          },
        }),
    }));
  };

  const handleContactEmailChange = (contact: Contact | null) => {
    if (!contact) {
      return setState(defaultState);
    }

    if (contact.id != null) {
      setState(currentState => ({
        ...currentState,
        newContact: null,
        existingContact: contact,
      }));
    } else {
      if (state.newContact) {
        setState(currentState => ({
          ...currentState,
          newContact: {
            ...currentState.newContact!,
            email: contact.email,
            businessUnitId: currentCalculationBusinessUnitId,
          },
          existingContact: null,
        }));
      } else {
        setState(currentState => ({
          ...currentState,
          newContact: contact,
          existingContact: null,
        }));
      }
    }
  }

  const handleSubmit = React.useCallback(
    async (event: FormEvent, shouldAssociateToAll: boolean) => {
      event.preventDefault();
      try {
        if (state.newContact) {
          await dispatch(associateContact(state.newContact, true, shouldAssociateToAll));
        } else if (state.existingContact) {
          await dispatch(associateContact(state.existingContact, false, shouldAssociateToAll));
        }
        onClose();
      } catch (e) {
        setError('Your contact information could not be saved and associated, please try again.');
      }
    },
    [dispatch, state, onClose]
  );

  React.useEffect(() => {
    if (calculation?.contact) {
      (async () => {
        const existingContact = await callApi<Contact>(dispatch, 'GET', `/Contact/${currentCalculationBusinessUnitId}/${calculation.contact?.id}`);

        if (existingContact) {
          setState({
            newContact: null,
            existingContact,
          });
        }
      })();
    }
  }, [dispatch, calculation, currentCalculationBusinessUnitId]);

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        if (searchTerm.length < 3) {
          return setExistingContact([]);
        }

        const existingCoontacts = await callApi<Contact[]>(dispatch, 'GET', `/Contact/search/${currentCalculationBusinessUnitId}/${debouncedSearchTerm}`);

        if (existingCoontacts) {
          setExistingContact(existingCoontacts);
        }
      })();
    }
  }, [dispatch, searchTerm, debouncedSearchTerm, setExistingContact, currentCalculationBusinessUnitId]);

  return (
    <Dialog open onClose={onClose} aria-labelledby="modal-contact-info-title">
      <form data-qa-id="contactInfoModalForm" onSubmit={(event: FormEvent) => handleSubmit(event, false)} noValidate>
      <div className={modalStyles.modalHeader}>
        <DialogTitle id="modal-contact-info-title">
          <Typography component="span" variant="h5">Contact Information</Typography>
        </DialogTitle>
      </div>

      <div className={`${[modalStyles.modalContent].join('')}`}>
        <DialogContent>
          {error && (
            <Alert severity="error" className={styles.alert} data-qa-id="contactInfoError">
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoEmailInput" data-qa-id="contactInfoEmailLabel">
                  Email
                </InputLabel>

                <Autocomplete
                      id="contactInfoEmailInput"
                      data-qa-id="contactInfoEmailInput"
                      options={existingContact}
                      autoHighlight
                      disabled={calculation?.locked}
                      getOptionLabel={(option: Contact) => option.email}
                      onInputChange={(event: ChangeEvent<any>, searchTerm: string) => setSearchTerm(searchTerm)}
                      onChange={(event: ChangeEvent<{}>, contact: Contact | null) => handleContactEmailChange(contact)}
                      value={state.newContact ?? state.existingContact}
                      forcePopupIcon
                      freeSolo
                      renderOption={(contact: Contact) =>
                        contact.id != null ? (
                          contact.email
                        ) : (
                          <Grid container justify="space-between" alignItems="center">
                            <span data-qa-id="newContactOptionEmail">{contact.email}</span>

                            <Grid item>
                              <Button variant="outlined" data-qa-id="newContactOptionButton">
                                {state.newContact?.email ? 'Update' : '+ New Contact'}
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params) as Contact[];

                        if (params.inputValue !== '') {
                          const option: Contact = state.newContact
                            ? {
                              ...state.newContact,
                              email: params.inputValue,
                            }
                            : {
                              ...defaultState.newContact!,
                              email: params.inputValue,
                            };

                          filtered.push(option);
                        }

                        return filtered.reduce<Contact[]>((acc, contact) => {
                          const isNewOption = contact.id === null;
                          const isAlreadyASuggestion = existingContact.find(p => p.email.toLowerCase() === contact.email.toLowerCase());

                          return isNewOption && isAlreadyASuggestion ? acc : [...acc, contact];
                        }, []);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Enter 3 or more characters to search..."
                          variant="outlined"
                          autoComplete="off" // Disable browser autocomplete and autofill
                        />
                      )}
                    />
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoFirstNameInput" data-qa-id="contactInfoFirstNameLabel">
                  First Name
                </InputLabel>

                <TextField
                  id="contactInfoFirstNameInput"
                  data-qa-id="contactInfoFirstNameInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.firstName : state.existingContact?.firstName}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('firstName', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoLastNameInput" data-qa-id="contactInfoLastNameLabel">
                  Last Name
                </InputLabel>

                <TextField
                  id="contactInfoLastNameInput"
                  data-qa-id="contactInfoLastNameInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.lastName : state.existingContact?.lastName}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('lastName', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoJobTitleInput" data-qa-id="contactInfoJobTitleLabel">
                  Job Title
                </InputLabel>

                <TextField
                  id="contactInfoJobTitleInput"
                  data-qa-id="contactInfoJobTitleInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.jobTitle : state.existingContact?.jobTitle}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('jobTitle', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoCompanyInput" data-qa-id="contactInfoCompanyLabel">
                  Company
                </InputLabel>

                <TextField
                  id="contactInfoCompanyInput"
                  data-qa-id="contactInfoCompanyInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.company : state.existingContact?.company}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('company', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoCompanyPostCode" data-qa-id="contactInfoCompanyPostCodeLabel">
                  Company Postcode
                </InputLabel>

                <TextField
                  id="contactInfoCompanyPostCodeInput"
                  data-qa-id="contactInfoCompanyPostCodeInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.companyPostCode : state.existingContact?.companyPostCode}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('companyPostCode', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoContactTypeInput" data-qa-id="contactInfoContactTypeLabel">
                  Contact Type
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="contactInfoContactTypeInput"
                  id="contactInfoContactTypeInput"
                  value={state.newContact ? lookupContactType(state.newContact!.contactType) : lookupContactType(state.existingContact!.contactType)}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<any>) => updateField('contactType', contactTypes.find(ct => ct.id === value)?.typeName ?? contactTypes[0].typeName)}
                  variant="outlined"
                >
                  <MenuItem key="null" value="">
                    &nbsp;
                  </MenuItem>

                  {contactTypes.map(({ id, typeName }) => (
                    <MenuItem key={id} value={id}>
                      {typeName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoBusinessPhoneInput" data-qa-id="contactInfoBusinessPhoneLabel">
                  Business Phone
                </InputLabel>

                <TextField
                  id="contactInfoBusinessPhoneInput"
                  data-qa-id="contactInfoBusinessPhoneInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.businessPhone : state.existingContact?.businessPhone}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('businessPhone', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoMobilePhoneInput" data-qa-id="contactInfoMobilePhoneLabel">
                  Mobile Phone
                </InputLabel>

                <TextField
                  id="contactInfoMobilePhoneInput"
                  data-qa-id="contactInfoMobilePhoneInput"
                  fullWidth
                  variant="outlined"
                  value={state.newContact ? state.newContact.mobilePhone : state.existingContact?.mobilePhone}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('mobilePhone', value)}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel htmlFor="contactInfoSalesContactTeamInput" data-qa-id="contactInfoSalesContactTeamLabel">
                  Sales Contact Team
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="contactInfoSalesContactTeamInput"
                  id="contactInfoSalesContactTeamInput"
                  value={state.newContact ? state.newContact.salesContactTeam : state.existingContact?.salesContactTeam}
                  disabled={calculation?.locked}
                  onChange={({ target: { value } }: ChangeEvent<any>) => {
                    updateField('salesContactTeam', value)
                    updateField('salesContactTeamValue', salesContactTeams.find(sct => sct.id === value)?.areaCode ?? '')
                  }}
                  variant="outlined"
                >
                  <MenuItem key="null" value="">
                    &nbsp;
                  </MenuItem>

                  {salesContactTeams.map(({ id, areaCode }) => (
                    <MenuItem key={id} value={id}>
                      {areaCode}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </div>

      <div className={modalStyles.modalActions}>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            <Grid container item xs={2} justify="flex-end">
              <Button data-qa-id="contactInfoCloseButton" onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </Grid>

            <Grid container item xs={3} justify="flex-end">
              <Button
                type="button"
                data-qa-id="contactInfoAssociateAllButton"
                fullWidth
                onClick={(event: FormEvent) => handleSubmit(event, true)}
                color="primary"
                variant="outlined"
                disabled={disableButtons}
              >
                Associate to all
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button
                type="submit"
                data-qa-id="contactInfoSubmitButton"
                fullWidth
                color="primary"
                variant="contained"
                disabled={disableButtons}
              >
                Associate to current
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
      </form>
    </Dialog>
  );
}

