export const isStringNotNullishOrWhitespace = (input?: string | null) => input != null && input.trim().length > 0;

export const isStringValidPostiveNumber = (input?: string | null) => isStringNotNullishOrWhitespace(input) && Number(input) > 0;

export const isStringValidNumberInRangeInclusive = (min: number, max: number, input?: string | null) => {
  if (!isStringNotNullishOrWhitespace(input)) {
    return false;
  }
  const numberInput = Number(input);
  if (numberInput < min || numberInput > max) {
    return false;
  }
  return true;
};
