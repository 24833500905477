import React, { ReactNode } from 'react';

export type TabPanelProps = {
  children?: ReactNode;
  id: string;
  'data-qa-id': string;
  targetIndex: number;
  currentTabIndex: number | false;
  'aria-labelledby': string;
};

export function TabPanel(props: TabPanelProps) {
  const { children, currentTabIndex, targetIndex, id, 'aria-labelledby': ariaLabelledBy, 'data-qa-id': dataQaId } = props;
  return (
    <div hidden={currentTabIndex !== targetIndex} id={id} data-qa-id={dataQaId} aria-labelledby={ariaLabelledBy}>
      {currentTabIndex === targetIndex && children}
    </div>
  );
}
