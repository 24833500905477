import React, { ChangeEvent, FormEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { Chat } from '@material-ui/icons';

import { NotesTab } from './NotesTab';

import styles from '../LayerModal/LayerModal.module.scss';
import constructionStyles from './ConstructionModal.module.scss';
import {
  Calculation,
  ConstructionFloorInsulationDetails,
  FloorInsulationLayerReferenceType,
  ConstructionRainscreenCladdingDetails,
  ApiConstructionDetailsRequest,
  ApiConstructionDetailsSteelWallRequest,
  ApiConstructionDetailsInvertedRoofRequest,
  ConstructionPitchedRoofDetails,
  CalculationNotes,
  ConstructionPitchedRoofWithLoftDetails,
} from '../../types/store/calculationTypes';
import {
  applicationTypeSelector,
  interimOrCurrentCalculationSelector,
  soilTypeSelector,
  floorMasterLayerSelector,
  drainageFxSelector,
  precipitationByPostCodeAreaSelector,
  rainscreenCladdingDefaultsSelector,
  roofCharacteristicsOptionsSelector,
  productSectorSelector,
} from '../../store/selectors';
import {
  MasterDataApplicationType,
  MasterDataSoilType,
  MasterRainscreenCladdingDefaults,
  MasterDataDrainageFx,
  MasterDataPrecipitationByPostCodeArea,
  MasterDataRoofCharacteristics,
  MasterDataProductSector,
} from '../../types/store/masterDataTypes';
import { TabPanel } from '../LayerModal/TabPanel';
import { VerticalAlign } from '../Utilities/VerticalAlign/VerticalAlign';
import { addConstructionDetailsToCalculation } from '../../actions/calculationActions';
import { Autocomplete, createFilterOptions, CreateFilterOptionsConfig } from '@material-ui/lab';
import { isStringValidPostiveNumber, isStringNotNullishOrWhitespace } from '../../common/inputValidation';

import {
  ConstructionModalState,
  noFloorReferenceOption,
  clientSpecifiedFloorReferenceOption,
  FloorReferenceOption,
} from './ConstructionModalStateTypes';
import { uuid } from '../../common/uuid';

type Props = {
  onClose: () => void;
};

const config: CreateFilterOptionsConfig<FloorReferenceOption> = {
  stringify: (option: FloorReferenceOption) => option.name,
};
const filter = createFilterOptions<FloorReferenceOption>(config);

const wallType = 'Wall';
const floorOtherThanGroundType = 'Floor';
const solidGroundFloorType = 'SolidGroundFloor';
const suspendedFloorType = 'SuspendedFloor';
const basementWallType = 'BasementWall';
const basementFloorType = 'BasementFloor';
const steelFrameWallType = 'SteelFrameWall';
const invertedRoofType = 'InvertedRoof';
const pitchedRoofWithRafterInsulation = 'PitchedRoofWithRafterInsulation';
const pitchedRoofWithVentilatedLoftSpace = 'PitchedRoofWithVentilatedLoftSpace';
const floorWithPerimeterInsulation = 'FloorWithPerimeterInsulation';

const typesWithWindShielding = [suspendedFloorType];
const typesWithPaRatio = [solidGroundFloorType, suspendedFloorType, basementFloorType, floorWithPerimeterInsulation];
const typesWithWallThickness = [
  solidGroundFloorType,
  suspendedFloorType,
  basementFloorType,
  basementWallType,
  floorWithPerimeterInsulation,
];
const typesWithSoilType = [solidGroundFloorType, suspendedFloorType, basementFloorType, basementWallType, floorWithPerimeterInsulation];
const typesWithHeightBelowGround = [basementWallType, suspendedFloorType, basementFloorType];
const typesWithFloorInsulationDetails = [basementWallType, floorWithPerimeterInsulation];
const typesWithPitchAngle = [pitchedRoofWithRafterInsulation];
const typesWithInsulationWidth = [floorWithPerimeterInsulation];

const typesWithConstructionDetails = [
  steelFrameWallType,
  solidGroundFloorType,
  suspendedFloorType,
  basementWallType,
  basementFloorType,
  invertedRoofType,
  pitchedRoofWithRafterInsulation,
  pitchedRoofWithVentilatedLoftSpace,
  floorWithPerimeterInsulation,
];
const typesWithRainscreenCladding = [wallType, floorOtherThanGroundType, steelFrameWallType];

function applicationTypeHasHeightBelowGround(applicationType?: string) {
  return applicationType != null && typesWithHeightBelowGround.includes(applicationType);
}

function applicationTypeHasPaRatio(applicationType?: string) {
  return applicationType != null && typesWithPaRatio.includes(applicationType);
}

function applicationTypeHasWallThickness(applicationType?: string) {
  return applicationType != null && typesWithWallThickness.includes(applicationType);
}

function applicationTypeHasSoilType(applicationType?: string) {
  return applicationType != null && typesWithSoilType.includes(applicationType);
}

function applicationTypeHasWindShielding(applicationType?: string) {
  return applicationType != null && typesWithWindShielding.includes(applicationType);
}

function applicationTypeHasPitchAngle(applicationType?: string) {
  return applicationType != null && typesWithPitchAngle.includes(applicationType);
}

function applicationTypeIsSteelFrameWall(applicationType?: string) {
  return applicationType != null && applicationType === steelFrameWallType;
}

function applicationTypeHasFloorInsulationDetails(applicationType?: string) {
  return applicationType != null && typesWithFloorInsulationDetails.includes(applicationType);
}

function applicationTypeHasRainscreenCladding(applicationType?: string) {
  return applicationType != null && typesWithRainscreenCladding.includes(applicationType);
}

function applicationTypeHasConstructionDetails(applicationType?: string) {
  return applicationType != null && typesWithConstructionDetails.includes(applicationType);
}

function applicationTypeIsInvertedRoof(applicationType?: string) {
  return applicationType != null && applicationType === invertedRoofType;
}

function applicationTypeHasConstructionDetailsResults(applicationType?: string) {
  return applicationTypeHasSoilType(applicationType) || applicationTypeIsInvertedRoof(applicationType);
}

function applicationTypeIsPitchedRoofWithVentilatedLoftSpace(applicationType?: string) {
  return applicationType != null && applicationType === pitchedRoofWithVentilatedLoftSpace;
}

function applicationTypeIsFloorWithPerimeterInsulation(applicationType?: string) {
  return applicationType != null && applicationType === floorWithPerimeterInsulation;
}

function applicationTypeHasInsulationWidth(applicationType?: string) {
  return applicationType != null && typesWithInsulationWidth.includes(applicationType);
}

function getInitialFloorMasterLayer(
  floorInsulationDetails: ConstructionFloorInsulationDetails | undefined,
  masterFloorLayers: FloorReferenceOption[]
): FloorReferenceOption | null {
  if (!masterFloorLayers || floorInsulationDetails?.insulationReference.insulationReferenceType == null) {
    return null;
  }

  switch (floorInsulationDetails?.insulationReference.insulationReferenceType) {
    case FloorInsulationLayerReferenceType.None:
      return noFloorReferenceOption;
    case FloorInsulationLayerReferenceType.Custom:
      return clientSpecifiedFloorReferenceOption;
    case FloorInsulationLayerReferenceType.Master:
      const masterFloorLayer = masterFloorLayers.find(
        layer => layer.id != null && layer.id === (floorInsulationDetails?.insulationReference.id || '')
      );

      if (masterFloorLayer == null) {
        return null;
      }

      return masterFloorLayer;
    default:
      return null;
  }
}

export function ConstructionModal(props: Props) {
  const dispatch = useDispatch();

  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);
  const applicationTypes: MasterDataApplicationType[] = useSelector(applicationTypeSelector);
  const soilTypes: MasterDataSoilType[] = useSelector(soilTypeSelector);
  const productSectors: MasterDataProductSector[] = useSelector(productSectorSelector);

  const drainageFxTypes: MasterDataDrainageFx[] = useSelector(drainageFxSelector);
  const precipitationByPostCodeAreas: MasterDataPrecipitationByPostCodeArea[] = useSelector(precipitationByPostCodeAreaSelector);
  const floorLayers: FloorReferenceOption[] = useSelector(floorMasterLayerSelector);
  const rainscreenCladdingDefaults: MasterRainscreenCladdingDefaults | undefined = useSelector(rainscreenCladdingDefaultsSelector);
  const roofCharacteristicsOptions: MasterDataRoofCharacteristics[] = useSelector(roofCharacteristicsOptionsSelector);
  const floorReferenceOptions = useMemo(() => [...floorLayers, clientSpecifiedFloorReferenceOption, noFloorReferenceOption], [floorLayers]);

  const initialSelectedApplicationType = applicationTypes.find(({ id }) => id === calculation?.applicationDetails.id)?.type;
  const initialSelectedApplicationName = applicationTypes.find(({ id }) => id === calculation?.applicationDetails.id)?.name;

  const initialDrainageFxTypeId = calculation?.constructionDetails?.invertedRoofDetails?.drainageFxId
    ? calculation?.constructionDetails?.invertedRoofDetails?.drainageFxId
    : 4;

  const initialTabsForSelection = [
    applicationTypeHasConstructionDetails(initialSelectedApplicationType) ? 0 : -1,
    applicationTypeHasRainscreenCladding(initialSelectedApplicationType) ? 1 : -1,
    2, // Fallback to Notes tab
  ];

  const [localState, setLocalState] = React.useState<ConstructionModalState>({
    selectedTab: initialTabsForSelection.filter(x => x !== -1)[0],
    applicationTypeId: calculation?.applicationDetails.id || 1,
    perimeterMetres: calculation?.constructionDetails?.perimeterMetres ?? '0.000',
    areaMetresSquared: calculation?.constructionDetails?.areaMetresSquared ?? '0.00',
    perimeterAreaRatio: calculation?.constructionDetails?.perimeterAreaRatio ?? '0.000',
    wallThicknessMetres: calculation?.constructionDetails?.wallThicknessMetres ?? '0.30',
    widthOfEdgeInsulation: calculation?.constructionDetails?.widthOfEdgeInsulation ?? '1200',
    ventilationRateMillimetresSquaredPerMetre: calculation?.constructionDetails?.ventilationRateMillimetresSquaredPerMetre ?? '1500',
    thermalTransmittanceWallsInUnderFloorSpaceAboveGround:
      calculation?.constructionDetails?.thermalTransmittanceWallsInUnderFloorSpaceAboveGround ?? '1.7',
    windSpeedMetresPerSecond: calculation?.constructionDetails?.windSpeedMetresPerSecond ?? '5.0',
    averageWindShieldingFactor: calculation?.constructionDetails?.averageWindShieldingFactor ?? '0.05',
    heightBelowGroundMetres: calculation?.constructionDetails?.heightBelowGroundMetres ?? '0.225',
    soilTypeId: applicationTypeHasSoilType(initialSelectedApplicationType)
      ? calculation?.constructionDetails?.soilTypeId ?? soilTypes[0].id
      : null,
    floorInsulationLayer: getInitialFloorMasterLayer(calculation?.constructionDetails?.floorInsulationDetails, floorReferenceOptions),
    floorInsulationReferenceType:
      calculation?.constructionDetails?.floorInsulationDetails?.insulationReference.insulationReferenceType ?? null,
    floorInsulationLayerThickness: calculation?.constructionDetails?.floorInsulationDetails?.thicknessMillimetres ?? null,
    floorInsulationThermalConductivity: calculation?.constructionDetails?.floorInsulationDetails?.thermalConductivity ?? null,
    floorInsulationLayerInstanceId: calculation?.constructionDetails?.floorInsulationDetails?.insulationLayerInstanceId ?? null,
    isWarmSteelFramedWall: calculation?.constructionDetails?.steelWallDetails?.isWarmSteelFramedWall ?? false,
    is50To80MillimetreFlange: calculation?.constructionDetails?.steelWallDetails?.is50To80MillimetreFlange ?? true,
    studDepthMillimetres: calculation?.constructionDetails?.steelWallDetails?.studDepthMillimetres ?? '150.0',
    studSpacingMillimetres: calculation?.constructionDetails?.steelWallDetails?.studSpacingMillimetres ?? '400.0',
    rainscreenCladdingDetailsPartial: calculation?.rainscreenCladdingDetails ?? undefined,
    drainageFx: drainageFxTypes.filter(d => d.id === initialDrainageFxTypeId)[0],
    precipitationForArea:
      precipitationByPostCodeAreas.filter(d => d.id === calculation?.constructionDetails?.invertedRoofDetails?.precipitationForAreaId)[0] ??
      null,
    pitchAngle: applicationTypeHasPitchAngle(initialSelectedApplicationType)
      ? calculation?.constructionDetails?.pitchedRoofDetails?.pitchAngle ?? '30'
      : null,
    calculationNotes: calculation?.calculationNotes ?? { calculationTitle: initialSelectedApplicationName || null, notes: null },
    pitchedRoofWithLoftDetails: applicationTypeIsPitchedRoofWithVentilatedLoftSpace(initialSelectedApplicationType)
      ? calculation!.constructionDetails!.pitchedRoofWithLoftDetails
      : undefined,
    productSectorId: calculation?.productSectorId ? calculation.productSectorId : productSectors[0].id,
    projectInsulationVolumeM2: calculation?.projectInsulationVolumeM2 ? calculation.projectInsulationVolumeM2 : 0,
  });

  const {
    selectedTab,
    applicationTypeId,
    soilTypeId,
    perimeterMetres,
    areaMetresSquared,
    perimeterAreaRatio,
    wallThicknessMetres,
    widthOfEdgeInsulation,
    ventilationRateMillimetresSquaredPerMetre,
    thermalTransmittanceWallsInUnderFloorSpaceAboveGround,
    windSpeedMetresPerSecond,
    averageWindShieldingFactor,
    heightBelowGroundMetres,
    floorInsulationLayer,
    floorInsulationReferenceType,
    floorInsulationLayerThickness,
    floorInsulationThermalConductivity,
    floorInsulationLayerInstanceId,
    isWarmSteelFramedWall,
    is50To80MillimetreFlange,
    studDepthMillimetres,
    studSpacingMillimetres,
    rainscreenCladdingDetailsPartial,
    drainageFx,
    precipitationForArea,
    pitchAngle,
    calculationNotes,
    pitchedRoofWithLoftDetails,
    productSectorId,
    projectInsulationVolumeM2
  } = localState;

  const selectedApplicationType = applicationTypes.find(({ id }) => id === localState.applicationTypeId)?.type;
  const selectedApplicationName = applicationTypes.find(({ id }) => id === localState.applicationTypeId)?.name;
  const groundThermalConductivity = soilTypes.find(({ id }) => id === localState.soilTypeId)?.lambda;
  const groundVapourResistivity = soilTypes.find(({ id }) => id === localState.soilTypeId)?.vapourResistivity;
  const rainscreenCladdingCorrectionFactor = calculation?.rainscreenCladdingCorrectionFactor;

  const handleApplicationTypeChange = (event: React.ChangeEvent<any>) => {
    const newSelectedApplicationId = Number(event.target.value);
    const newSelectedApplicationType = applicationTypes.find(({ id }) => id === newSelectedApplicationId)?.type;
    const selectedApplicationName = applicationTypes.find(({ id }) => id === newSelectedApplicationId)?.name;

    setLocalState(prevState => {
      const tabsForSelection = [
        applicationTypeHasConstructionDetails(newSelectedApplicationType) ? 0 : -1,
        applicationTypeHasRainscreenCladding(newSelectedApplicationType) ? 1 : -1,
        2, // Fallback to Notes tab
      ];

      // Object to resetModalState. Only include values that should be changed on application type change
      const defaultsToResetTo: Partial<ConstructionModalState> =
        prevState.applicationTypeId !== newSelectedApplicationId
          ? {
              selectedTab: tabsForSelection.filter(x => x !== -1)[0],
              rainscreenCladdingDetailsPartial: undefined,
              soilTypeId: applicationTypeHasSoilType(newSelectedApplicationType) ? soilTypes[0].id : null,
              pitchAngle: applicationTypeHasPitchAngle(newSelectedApplicationType) ? '30' : null,
              calculationNotes: {
                ...prevState.calculationNotes,
                calculationTitle: selectedApplicationName || '',
              },
              pitchedRoofWithLoftDetails: applicationTypeIsPitchedRoofWithVentilatedLoftSpace(newSelectedApplicationType)
                ? { loftLayerInstanceId: uuid(), roofCharacteristicId: roofCharacteristicsOptions[0].id, thicknessMillimetres: '300.0' }
                : undefined,
              floorInsulationLayerInstanceId: applicationTypeIsFloorWithPerimeterInsulation(newSelectedApplicationType) ? uuid() : undefined
            }
          : {};

      return {
        ...prevState,
        applicationTypeId: newSelectedApplicationId,
        // Reset construction details
        ...defaultsToResetTo,
      };
    });
  };

  const handleProjectInsulationVolumeM2Changed = (event: React.ChangeEvent<any>) => {
    const newProjectInsulationVolumeM2 = Number(event.target.value)

    setLocalState(prevState => {
      return {
        ...prevState,
        projectInsulationVolumeM2: newProjectInsulationVolumeM2,
      };
    });
  };

  const handleProductSectorChange = (event: React.ChangeEvent<any>) => {
    const newSelectedProductSectorId = Number(event.target.value);

    setLocalState(prevState => {

      const defaultsToResetTo: Partial<ConstructionModalState> =
        prevState.productSectorId !== newSelectedProductSectorId
          ? {
              productSectorId: newSelectedProductSectorId
            }
          : {};

          return {
            ...prevState,
            productSectorId: newSelectedProductSectorId,
            // Reset construction details
            ...defaultsToResetTo,
          };
    });
  };

  const handleSubmit = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const hasConstructionDetails = applicationTypeHasConstructionDetails(selectedApplicationType);
      const hasPaRatio = applicationTypeHasPaRatio(selectedApplicationType);
      const hasWindshielding = applicationTypeHasWindShielding(selectedApplicationType);
      const isSteelFramedWall = applicationTypeIsSteelFrameWall(selectedApplicationType);
      const hasWallThickness = applicationTypeHasWallThickness(selectedApplicationType);
      const hasInsulationWidth = applicationTypeHasInsulationWidth(selectedApplicationType);
      const hasHeightBelowGround = applicationTypeHasHeightBelowGround(selectedApplicationType);
      const hasSoilType = applicationTypeHasSoilType(selectedApplicationType);
      const hasPitchAngle = applicationTypeHasPitchAngle(selectedApplicationType);

      const isInvertedRoof = applicationTypeIsInvertedRoof(selectedApplicationType);
      const hasFloorInsulationDetails = applicationTypeHasFloorInsulationDetails(selectedApplicationType);
      const hasRainscreenCladdingDetails = applicationTypeHasRainscreenCladding(selectedApplicationType);
      const isPitchedRoofWithVentilatedLoftSpace = applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType);

      const floorInsulationDetails: ConstructionFloorInsulationDetails | undefined =
        hasFloorInsulationDetails && floorInsulationReferenceType != null
          ? {
              insulationReference: {
                insulationReferenceType: floorInsulationReferenceType,
                id: floorInsulationLayer?.id || null,
              },
              thicknessMillimetres: floorInsulationLayerThickness,
              thermalConductivity: floorInsulationThermalConductivity,
              insulationLayerInstanceId: floorInsulationLayerInstanceId
            }
          : undefined;

      const isRainscreenCladdingComplete =
        hasRainscreenCladdingDetails &&
        rainscreenCladdingDetailsPartial != null &&
        Number(rainscreenCladdingDetailsPartial?.chiValue) > 0 &&
        Number(rainscreenCladdingDetailsPartial?.bracketsPerMetreSquared) > 0;

      const rainscreenCladdingDetails: ConstructionRainscreenCladdingDetails | undefined = isRainscreenCladdingComplete
        ? {
            chiValue: rainscreenCladdingDetailsPartial!.chiValue!,
            bracketsPerMetreSquared: rainscreenCladdingDetailsPartial!.bracketsPerMetreSquared!,
          }
        : undefined;

      let steelWallDetails: ApiConstructionDetailsSteelWallRequest | null = null;
      if (isSteelFramedWall && isWarmSteelFramedWall != null) {
        steelWallDetails = {
          isWarmSteelFramedWall,
          is50To80MillimetreFlange: isSteelFramedWall ? is50To80MillimetreFlange : null,
          studDepthMillimetres: isSteelFramedWall ? studDepthMillimetres : null,
          studSpacingMillimetres: isSteelFramedWall ? studSpacingMillimetres : null,
        };
      }

      const invertedRoofDetails: ApiConstructionDetailsInvertedRoofRequest | null = isInvertedRoof
        ? {
            drainageFxId: drainageFx ? drainageFx.id : null,
            drainageFx: drainageFx ? drainageFx.fxFactor : null,
            precipitationForAreaId: precipitationForArea ? precipitationForArea?.id : null,
            precipitationForAreaMillimetres: precipitationForArea ? precipitationForArea?.rainfallMillimetres : null,
          }
        : null;

      const pitchedRoofDetails: ConstructionPitchedRoofDetails | undefined =
        hasPitchAngle && pitchAngle != null && Number(pitchAngle) >= 0 && Number(pitchAngle) <= 90
          ? {
              pitchAngle,
            }
          : undefined;

      const pitchedRoofWithLoftDetailsFinal: ConstructionPitchedRoofWithLoftDetails | undefined = isPitchedRoofWithVentilatedLoftSpace
        ? pitchedRoofWithLoftDetails
        : undefined;

      const constructionDetails: ApiConstructionDetailsRequest | undefined = hasConstructionDetails
        ? {
            soilTypeId: hasSoilType ? soilTypeId : null,
            perimeterMetres: hasPaRatio ? perimeterMetres : null,
            areaMetresSquared: hasPaRatio ? areaMetresSquared : null,
            perimeterAreaRatio: hasPaRatio ? perimeterAreaRatio : null,
            wallThicknessMetres: hasWallThickness ? wallThicknessMetres : null,
            widthOfEdgeInsulation: hasInsulationWidth ? widthOfEdgeInsulation : null,
            ventilationRateMillimetresSquaredPerMetre: hasWindshielding ? ventilationRateMillimetresSquaredPerMetre : null,
            thermalTransmittanceWallsInUnderFloorSpaceAboveGround: hasWindshielding
              ? thermalTransmittanceWallsInUnderFloorSpaceAboveGround
              : null,
            windSpeedMetresPerSecond: hasWindshielding ? windSpeedMetresPerSecond : null,
            averageWindShieldingFactor: hasWindshielding ? averageWindShieldingFactor : null,
            heightBelowGroundMetres: hasHeightBelowGround ? heightBelowGroundMetres : null,
            floorInsulationDetails: floorInsulationDetails,
            steelWallDetails,
            invertedRoofDetails,
            pitchedRoofDetails,
            pitchedRoofWithLoftDetails: pitchedRoofWithLoftDetailsFinal,
          }
        : undefined;

      // If null is somehow sent because we don't have an applicationName, the server will replace the title with the applicationName
      const titleFallback = isStringNotNullishOrWhitespace(selectedApplicationName) ? (selectedApplicationName as string) : null;
      const finalCalculationNotes: CalculationNotes = {
        ...calculationNotes,
        calculationTitle: isStringNotNullishOrWhitespace(calculationNotes.calculationTitle)
          ? calculationNotes.calculationTitle
          : titleFallback,
      };

      // const projectInsulationVolumeM2: number = Number(localState.projectInsulationVolumeM2);

      dispatch(
        addConstructionDetailsToCalculation(applicationTypeId, constructionDetails, rainscreenCladdingDetails, finalCalculationNotes, productSectorId, projectInsulationVolumeM2)
      );
      props.onClose();
    },
    [
      dispatch,
      applicationTypeId,
      soilTypeId,
      perimeterMetres,
      areaMetresSquared,
      perimeterAreaRatio,
      wallThicknessMetres,
      widthOfEdgeInsulation,
      selectedApplicationType,
      averageWindShieldingFactor,
      heightBelowGroundMetres,
      thermalTransmittanceWallsInUnderFloorSpaceAboveGround,
      ventilationRateMillimetresSquaredPerMetre,
      windSpeedMetresPerSecond,
      floorInsulationLayer,
      floorInsulationReferenceType,
      floorInsulationLayerThickness,
      floorInsulationThermalConductivity,
      floorInsulationLayerInstanceId,
      isWarmSteelFramedWall,
      is50To80MillimetreFlange,
      studDepthMillimetres,
      studSpacingMillimetres,
      rainscreenCladdingDetailsPartial,
      drainageFx,
      precipitationForArea,
      pitchAngle,
      calculationNotes,
      selectedApplicationName,
      pitchedRoofWithLoftDetails,
      productSectorId,
      projectInsulationVolumeM2,
      props,
    ]
  );

  const handlePerimeterAreaRatioChanged = (event: React.ChangeEvent<any>) => {
    const targetValue = event.target.value;
    if (event.target.value !== '') {
      setLocalState(prevState => {
        return {
          ...prevState,
          perimeterMetres: '',
          areaMetresSquared: '',
          perimeterAreaRatio: targetValue,
        };
      });
    } else {
      setLocalState(prevState => ({ ...prevState, perimeterAreaRatio: targetValue }));
    }
  };

  const handlePerimeterMetresChanged = (event: React.ChangeEvent<any>) => {
    const targetValue = event.target.value;
    setLocalState(prevState => {
      return setPeremiterAndArea(prevState, targetValue, prevState.areaMetresSquared);
    });
  };

  const handleAreaMetresSquaredChanged = (event: React.ChangeEvent<any>) => {
    const targetValue = event.target.value;
    setLocalState(prevState => {
      return setPeremiterAndArea(prevState, prevState.perimeterMetres, targetValue);
    });
  };

  const setPeremiterAndArea = (prevState: ConstructionModalState, perimeterMetres: string | null, areaMetresSquared: string | null) => {
    const perimeterAreaRatio = Number(perimeterMetres) / Number(areaMetresSquared);

    if (isPerimeterAndAreaInvalid(perimeterMetres, areaMetresSquared, perimeterAreaRatio)) {
      return { ...prevState, perimeterMetres, areaMetresSquared, perimeterAreaRatio: '' };
    } else {
      return { ...prevState, perimeterMetres, areaMetresSquared, perimeterAreaRatio: perimeterAreaRatio.toFixed(3) };
    }
  };

  const isPerimeterAndAreaInvalid = (perimeterMetres: any, areaMetresSquared: any, perimeterAreaRatio: number) => {
    return perimeterMetres === '' || areaMetresSquared === '' || isNaN(perimeterAreaRatio) || !isFinite(perimeterAreaRatio);
  };

  const canSubmit = () => {
    if (calculation?.locked) return false;

    const isSoilTypeValid =
      (applicationTypeHasSoilType(selectedApplicationType) && Number(localState.soilTypeId) > 0) ||
      !applicationTypeHasSoilType(selectedApplicationType);

    const isHeightBelowGroundMetresValid =
      (applicationTypeHasHeightBelowGround(selectedApplicationType) && Number(localState.heightBelowGroundMetres) > 0) ||
      !applicationTypeHasHeightBelowGround(selectedApplicationType);

    const isWindshieldingValid =
      (applicationTypeHasWindShielding(selectedApplicationType) &&
        Number(localState.ventilationRateMillimetresSquaredPerMetre) > 0 &&
        Number(localState.windSpeedMetresPerSecond) > 0 &&
        Number(localState.averageWindShieldingFactor) > 0 &&
        Number(localState.thermalTransmittanceWallsInUnderFloorSpaceAboveGround) > 0) ||
      !applicationTypeHasWindShielding(selectedApplicationType);

    const isFloorInsulationDetailsValid =
      (applicationTypeHasFloorInsulationDetails(selectedApplicationType) &&
        ((localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.None &&
          localState.floorInsulationLayer?.id == null &&
          localState.floorInsulationLayerThickness == null &&
          localState.floorInsulationThermalConductivity == null) ||
          // If master layer is selected, the thermal conductivity is not validated as the user cannot set it, and there are some cases where it cannot be set in the frontend.
          // Therefore, setting it has moved completely to the backend for consistency
          (localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.Master &&
            localState.floorInsulationLayer?.id != null &&
            isStringValidPostiveNumber(localState.floorInsulationLayerThickness)) ||
          (localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.Custom &&
            localState.floorInsulationLayer?.id == null &&
            isStringValidPostiveNumber(localState.floorInsulationLayerThickness) &&
            isStringValidPostiveNumber(localState.floorInsulationThermalConductivity)))) ||
      !applicationTypeHasFloorInsulationDetails(selectedApplicationType);

    const isPaRatioValid =
      (applicationTypeHasPaRatio(selectedApplicationType) &&
        isStringValidPostiveNumber(localState.perimeterAreaRatio) &&
        !isNaN(Number(localState.perimeterAreaRatio)) &&
        isFinite(Number(localState.perimeterAreaRatio))) ||
      !applicationTypeHasPaRatio(selectedApplicationType);

    const isSteelFramedWallValid =
      (applicationTypeIsSteelFrameWall(selectedApplicationType) &&
        Number(localState.studSpacingMillimetres) > 0 &&
        Number(localState.studSpacingMillimetres) !== Infinity &&
        Number(localState.studDepthMillimetres) > 0 &&
        Number(localState.studDepthMillimetres) !== Infinity) ||
      !applicationTypeIsSteelFrameWall(selectedApplicationType);

    const isWallThicknessValid =
      (applicationTypeHasWallThickness(selectedApplicationType) && isStringValidPostiveNumber(localState.wallThicknessMetres)) ||
      !applicationTypeHasWallThickness(selectedApplicationType);

    const isInsulationWidthValid =
      (applicationTypeHasInsulationWidth(selectedApplicationType) && isStringValidPostiveNumber(localState.widthOfEdgeInsulation)) ||
      !applicationTypeHasInsulationWidth(selectedApplicationType);

    const isRainscreenCladdingValid =
      (applicationTypeHasRainscreenCladding(selectedApplicationType) &&
        ((Number(localState.rainscreenCladdingDetailsPartial?.chiValue) > 0 &&
          Number(localState.rainscreenCladdingDetailsPartial?.bracketsPerMetreSquared) > 0) ||
          localState.rainscreenCladdingDetailsPartial == null)) ||
      !applicationTypeHasRainscreenCladding(selectedApplicationType);

    const isInvertedRoofValid =
      (applicationTypeIsInvertedRoof(selectedApplicationType) &&
        Number(localState.drainageFx?.id) > 0 &&
        isStringValidPostiveNumber(localState.drainageFx?.fxFactor) &&
        Number(localState.precipitationForArea?.id) > 0) ||
      !applicationTypeIsInvertedRoof(selectedApplicationType);

    const isPitchAngleValid =
      (applicationTypeHasPitchAngle(selectedApplicationType) &&
        isStringNotNullishOrWhitespace(localState.pitchAngle) &&
        Number(localState.pitchAngle) >= 0 &&
        Number(localState.pitchAngle) <= 90) ||
      !applicationTypeHasPitchAngle(selectedApplicationType);

    const isPitchedRoofWithVentilatedLoftSpaceValid =
      (applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) &&
        isStringNotNullishOrWhitespace(pitchedRoofWithLoftDetails?.loftLayerInstanceId) &&
        Number(pitchedRoofWithLoftDetails?.roofCharacteristicId) > 0 &&
        Number(pitchedRoofWithLoftDetails?.thicknessMillimetres) > 0) ||
      (!applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) && pitchedRoofWithLoftDetails === undefined);

    return (
      isSoilTypeValid &&
      isWallThicknessValid &&
      isInsulationWidthValid &&
      isPaRatioValid &&
      isHeightBelowGroundMetresValid &&
      isWindshieldingValid &&
      isFloorInsulationDetailsValid &&
      isPaRatioValid &&
      isHeightBelowGroundMetresValid &&
      isWindshieldingValid &&
      isSteelFramedWallValid &&
      isRainscreenCladdingValid &&
      isInvertedRoofValid &&
      isPitchAngleValid &&
      isPitchedRoofWithVentilatedLoftSpaceValid
    );
  };

  function renderNoteTabLabel() {
    return (
      <span data-qa-id="notesTabLabel">
        Notes
        <span className={constructionStyles.notesTabLabelIcon}>
          <Chat
            classes={{
              root: constructionStyles.notesTabLabelIcon,
            }}
            data-qa-id="notesTabLabelIcon"
            fontSize={'small'}
          />
        </span>
      </span>
    );
  }

  return (
    <Dialog open onClose={props.onClose} aria-labelledby="modal-construction-title">
      <form data-qa-id="constructionModalForm" onSubmit={handleSubmit} noValidate>
        <div className={styles.modalHeader}>
          <DialogTitle id="modal-construction-title">
          <Typography component="span" variant="h5">Edit Construction</Typography>
          </DialogTitle>
        </div>

        <div className={styles.modalContent}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel data-qa-id="applicationTypeLabel" id="calculation-application-type">
                  Application Type
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="applicationTypeInput"
                  id="calculation-application-type"
                  value={applicationTypeId}
                  onChange={handleApplicationTypeChange}
                  variant="outlined"
                  disabled={!!calculation?.locked}
                >
                  {applicationTypes.map(applicationType => {
                    return (
                      <MenuItem
                        key={applicationType.id}
                        value={applicationType.id}
                        data-qa-id={`applicationTypeInput-option-${applicationType.name}`}
                      >
                        {applicationType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel data-qa-id="productSectorLabel" id="calculation-product-sector">
                  Product Sector
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="productSectorInput"
                  id="calculation-product-sector"
                  value={productSectorId}
                  onChange={handleProductSectorChange}
                  variant="outlined"
                  disabled={!!calculation?.locked}
                >
                  {productSectors.map(productSelector => {
                    return (
                      <MenuItem
                        key={productSelector.id}
                        value={productSelector.id}
                        data-qa-id={`productSectorInput-option-${productSelector.sector}`}
                      >
                        {productSelector.sector}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                    <InputLabel data-qa-id="projectInsulationInputVolumeM2Label">
                      Project Insulation Volume M2
                    </InputLabel>

                    <TextField
                      id="contactInfoProjectInsulationVolumeM2Input"
                      data-qa-id="contactInfoProjectInsulationVolumeM2Input"
                      fullWidth
                      variant="outlined"
                      value={localState.projectInsulationVolumeM2}
                      disabled={calculation?.locked}
                      onChange={handleProjectInsulationVolumeM2Changed}
                    />
                </Grid>
            </Grid>

            <React.Fragment>
              <Tabs
                className={styles.tabs}
                value={selectedTab}
                onChange={(event: React.ChangeEvent<any>, selectedTab: number) =>
                  setLocalState(prevState => ({ ...prevState, selectedTab }))
                }
                indicatorColor="primary"
                textColor="primary"
                aria-label="further-details-tabs"
                data-qa-id="further-details-tabs"
              >
                <Tab
                  disableRipple
                  disabled={!applicationTypeHasConstructionDetails(selectedApplicationType)}
                  className={styles.tab}
                  label="Construction Details"
                  id="construction-details-tab"
                  aria-controls="construction-details-panel"
                  data-qa-id="construction-details-tab"
                />

                <Tab
                  disableRipple
                  disabled={!applicationTypeHasRainscreenCladding(selectedApplicationType)}
                  className={styles.tab}
                  label="Rainscreen Cladding"
                  id="rainscreen-cladding-tab"
                  aria-controls="rainscreen-cladding-panel"
                  data-qa-id="rainscreen-cladding-tab"
                />

                <Tab
                  disableRipple
                  className={styles.tab}
                  label={renderNoteTabLabel()}
                  id="notes-tab"
                  aria-controls="notes-tab-panel"
                  data-qa-id="notes-tab"
                />
              </Tabs>

              {localState.selectedTab !== false && (
                <div className={styles.tabbedContentContainer} data-qa-id="tab-panels-container">
                  {applicationTypeHasConstructionDetails(selectedApplicationType) && (
                    <TabPanel
                      id="construction-details-panel"
                      data-qa-id="construction-details-panel"
                      targetIndex={0}
                      currentTabIndex={localState.selectedTab}
                      aria-labelledby="construction-details-tab"
                    >
                      <div className={styles.tabContent}>
                        <Grid container item xs={12}>
                          {applicationTypeHasPaRatio(selectedApplicationType) && (
                            <Grid container item xs={6} spacing={3}>
                              <Grid item xs={3}>
                                <InputLabel htmlFor="modal-construction-details-perimeter" data-qa-id="constructionDetailsPerimeterLabel">
                                  Perimeter
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-perimeter"
                                      data-qa-id="constructionDetailsPerimeterInput"
                                      variant="outlined"
                                      value={localState.perimeterMetres || ''}
                                      onChange={handlePerimeterMetresChanged}
                                      disabled={!!calculation?.locked}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsPerimeterUnits">
                                      m
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={3}>
                                <InputLabel htmlFor="modal-construction-details-area" data-qa-id="constructionDetailsAreaLabel">
                                  Area
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-area"
                                      data-qa-id="constructionDetailsAreaInput"
                                      variant="outlined"
                                      value={localState.areaMetresSquared || ''}
                                      onChange={handleAreaMetresSquaredChanged}
                                      disabled={!!calculation?.locked}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsAreaUnits">
                                      m²
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={1}>
                                {/* Empty label to ensure correct vertical alignment */}
                                <InputLabel />

                                <VerticalAlign align="center">
                                  <div className={styles.standaloneUnit}>=</div>
                                </VerticalAlign>
                              </Grid>

                              <Grid item xs={3}>
                                <InputLabel htmlFor="modal-construction-details-ratio" data-qa-id="constructionDetailsRatioLabel">
                                  P/A Ratio
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-ratio"
                                      data-qa-id="constructionDetailsRatioInput"
                                      variant="outlined"
                                      value={localState.perimeterAreaRatio || ''}
                                      onChange={handlePerimeterAreaRatioChanged}
                                      disabled={!!calculation?.locked}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid container item xs={6} spacing={3}>
                            {applicationTypeHasWallThickness(selectedApplicationType) && (
                              <Grid item xs={4}>
                                <InputLabel
                                  htmlFor="modal-construction-details-wall-thickness"
                                  data-qa-id="constructionDetailsWallThicknessLabel"
                                >
                                  Wall Thickness
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-wall-thickness"
                                      data-qa-id="constructionDetailsWallThicknessInput"
                                      variant="outlined"
                                      value={wallThicknessMetres || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({ ...prevState, wallThicknessMetres: value }));
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsWallThicknessUnits">
                                      m
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {applicationTypeHasHeightBelowGround(selectedApplicationType) && (
                              <Grid item xs={4}>
                                <InputLabel
                                  htmlFor="modal-construction-details-height-below-ground"
                                  data-qa-id="constructionDetailsHeightBelowGroundLabel"
                                >
                                  Height B. Ground
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-height-below-ground"
                                      data-qa-id="constructionDetailsHeightBelowGroundInput"
                                      variant="outlined"
                                      value={heightBelowGroundMetres || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({ ...prevState, heightBelowGroundMetres: value }));
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsHeightBelowGroundUnits">
                                      m
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {applicationTypeHasInsulationWidth(selectedApplicationType) && (
                              <Grid item xs={4}>
                                <InputLabel
                                  htmlFor="modal-construction-details-insulation-width"
                                  data-qa-id="constructionDetailsInsulationWidthLabel"
                                >
                                  Insulation Width
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-insulation-width"
                                      data-qa-id="constructionDetailsInsulationWidthInput"
                                      variant="outlined"
                                      value={widthOfEdgeInsulation || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({ ...prevState, widthOfEdgeInsulation: value }));
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsInsulationWidthUnits">
                                      mm
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {applicationTypeHasWindShielding(selectedApplicationType) && (
                              <Grid item xs={4}>
                                <InputLabel
                                  htmlFor="modal-construction-details-ventilation-rate"
                                  data-qa-id="constructionDetailsVentilationRateLabel"
                                >
                                  Ventilation rate
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-ventilation-rate"
                                      data-qa-id="constructionDetailsVentilationRateInput"
                                      variant="outlined"
                                      value={localState.ventilationRateMillimetresSquaredPerMetre || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({
                                          ...prevState,
                                          ventilationRateMillimetresSquaredPerMetre: value,
                                        }));
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <div className={styles.inputUnit} data-qa-id="constructionDetailsVentilationRateUnits">
                                      mm2
                                      <br />
                                      /m
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>

                        {(applicationTypeHasSoilType(selectedApplicationType) ||
                          applicationTypeHasWindShielding(selectedApplicationType)) && (
                          <React.Fragment>
                            <div className={styles.verticalDivider} />

                            <Grid container item xs={12}>
                              {applicationTypeHasSoilType(selectedApplicationType) && (
                                <Grid container item xs={6} spacing={3}>
                                  <Grid item xs={10}>
                                    <InputLabel data-qa-id="constructionDetailsSoilTypeLabel" id="modal-construction-details-soil-type">
                                      Soil Type
                                    </InputLabel>

                                    <Select
                                      fullWidth
                                      data-qa-id="constructionDetailsSoilTypeInput"
                                      id="modal-construction-details-soil-type"
                                      value={localState.soilTypeId}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: React.ChangeEvent<any>) =>
                                        setLocalState(prevState => ({ ...prevState, soilTypeId: Number(event.target.value) }))
                                      }
                                      variant="outlined"
                                    >
                                      {soilTypes.map(soilType => {
                                        return (
                                          <MenuItem key={soilType.id} value={soilType.id}>
                                            {soilType.description}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              )}
                              {applicationTypeHasWindShielding(selectedApplicationType) && (
                                <Grid container item xs={6} spacing={3}>
                                  <Grid item xs={4}>
                                    <InputLabel
                                      htmlFor="modal-construction-details-thermal-trans"
                                      data-qa-id="constructionDetailsThermalTransLabel"
                                    >
                                      Thermal trans
                                    </InputLabel>

                                    <Grid container alignItems="center">
                                      <Grid item xs={10}>
                                        <TextField
                                          id="modal-construction-details-thermal-trans"
                                          data-qa-id="constructionDetailsThermalTransInput"
                                          variant="outlined"
                                          disabled={!!calculation?.locked}
                                          value={localState.thermalTransmittanceWallsInUnderFloorSpaceAboveGround || ''}
                                          onChange={(event: ChangeEvent<any>) => {
                                            const value = event.target.value;
                                            setLocalState(prevState => ({
                                              ...prevState,
                                              thermalTransmittanceWallsInUnderFloorSpaceAboveGround: value,
                                            }));
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={2}>
                                        <div className={styles.inputUnit} data-qa-id="constructionDetailsThermalTransUnits">
                                          m
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <InputLabel
                                      htmlFor="modal-construction-details-windspeed"
                                      data-qa-id="constructionDetailsWindspeedLabel"
                                    >
                                      Windspeed
                                    </InputLabel>

                                    <Grid container alignItems="center">
                                      <Grid item xs={10}>
                                        <TextField
                                          id="modal-construction-details-windspeed"
                                          data-qa-id="constructionDetailsWindspeedInput"
                                          variant="outlined"
                                          disabled={!!calculation?.locked}
                                          value={localState.windSpeedMetresPerSecond || ''}
                                          onChange={(event: ChangeEvent<any>) => {
                                            const value = event.target.value;
                                            setLocalState(prevState => ({ ...prevState, windSpeedMetresPerSecond: value }));
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={2}>
                                        <div className={styles.inputUnit} data-qa-id="constructionDetailsWindspeedUnits">
                                          m/s
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <InputLabel
                                      htmlFor="modal-construction-details-average-wind-shielding"
                                      data-qa-id="constructionDetailsAverageWindShieldingLabel"
                                    >
                                      Avg wind shielding
                                    </InputLabel>

                                    <Grid container alignItems="center">
                                      <Grid item xs={10}>
                                        <TextField
                                          id="modal-construction-details-average-wind-shielding"
                                          data-qa-id="constructionDetailsAverageWindShieldingInput"
                                          variant="outlined"
                                          value={localState.averageWindShieldingFactor || ''}
                                          disabled={!!calculation?.locked}
                                          onChange={(event: ChangeEvent<any>) => {
                                            const value = event.target.value;
                                            setLocalState(prevState => ({ ...prevState, averageWindShieldingFactor: value }));
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </React.Fragment>
                        )}

                        {applicationTypeHasFloorInsulationDetails(selectedApplicationType) && (
                          <React.Fragment>
                            <div className={styles.verticalDivider} />
                            <Grid container item xs={12}>
                              <Grid container item xs={6} spacing={3}>
                                <Grid item xs={10}>
                                  <InputLabel
                                    htmlFor="modal-construction-details-floor-insulation-layer"
                                    data-qa-id="constructionDetailsFloorInsulationLayerLabel"
                                  >
                                    Floor Insulation
                                  </InputLabel>

                                  <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        id="modal-construction-details-floor-insulation-layer"
                                        data-qa-id="constructionDetailsFloorInsulationLayerInput"
                                        options={floorReferenceOptions}
                                        autoHighlight
                                        getOptionLabel={(option: FloorReferenceOption) => option.name}
                                        value={localState.floorInsulationLayer}
                                        disabled={!!calculation?.locked}
                                        onChange={(event: React.ChangeEvent<{}>, layer: FloorReferenceOption | null) => {
                                          if (!layer) {
                                            setLocalState(prevState => ({
                                              ...prevState,
                                              floorInsulationLayerThickness: null,
                                              floorInsulationReferenceType: null,
                                              floorInsulationThermalConductivity: null,
                                              floorInsulationLayer: null,
                                            }));
                                            return;
                                          }

                                          if (layer.id != null) {
                                            setLocalState(prevState => ({
                                              ...prevState,
                                              floorInsulationLayerThickness: layer.defaultThickness,
                                              floorInsulationReferenceType: FloorInsulationLayerReferenceType.Master,
                                              floorInsulationThermalConductivity: null,
                                              floorInsulationLayer: layer,
                                            }));
                                            return;
                                          } else {
                                            if (layer.type === FloorInsulationLayerReferenceType.None) {
                                              setLocalState(prevState => ({
                                                ...prevState,
                                                floorInsulationLayerThickness: null,
                                                floorInsulationReferenceType: FloorInsulationLayerReferenceType.None,
                                                floorInsulationThermalConductivity: null,
                                                floorInsulationLayer: layer,
                                              }));
                                              return;
                                            } else {
                                              setLocalState(prevState => ({
                                                ...prevState,
                                                floorInsulationLayerThickness: null,
                                                floorInsulationReferenceType: FloorInsulationLayerReferenceType.Custom,
                                                floorInsulationThermalConductivity: null,
                                                floorInsulationLayer: layer,
                                              }));
                                              return;
                                            }
                                          }
                                        }}
                                        forcePopupIcon
                                        freeSolo
                                        filterOptions={(options, params) => {
                                          const filtered = filter(options, params) as FloorReferenceOption[];
                                          return filtered;
                                        }}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            placeholder="Browse catalogue..."
                                            variant="outlined"
                                            autoComplete="off" // Disable browser autocomplete and autofill
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container item xs={6} spacing={3}>
                                {floorInsulationReferenceType === FloorInsulationLayerReferenceType.Custom && (
                                  <Grid item xs={4}>
                                    <InputLabel
                                      htmlFor="modal-construction-details-floor-insulation-thermal-conductivity"
                                      data-qa-id="constructionDetailsFloorInsulationThermalConductivityLabel"
                                    >
                                      Therm. Cond.
                                    </InputLabel>

                                    <Grid container alignItems="center">
                                      <Grid item xs={9}>
                                        <TextField
                                          id="modal-construction-details-floor-insulation-thermal-conductivity"
                                          data-qa-id="constructionDetailsFloorInsulationThermalConductivityInput"
                                          variant="outlined"
                                          value={localState.floorInsulationThermalConductivity || ''}
                                          disabled={!!calculation?.locked}
                                          onChange={(event: ChangeEvent<any>) => {
                                            const value = event.target.value;
                                            setLocalState(prevState => ({ ...prevState, floorInsulationThermalConductivity: value }));
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <div
                                          className={styles.inputUnit}
                                          data-qa-id="constructionDetailsFloorInsulationThermalConductivityUnits"
                                        >
                                          W/mK
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}

                                {floorInsulationReferenceType != null &&
                                  floorInsulationReferenceType !== FloorInsulationLayerReferenceType.None && (
                                    <Grid item xs={4}>
                                      <InputLabel
                                        htmlFor="modal-construction-details-floor-insulation-thickness"
                                        data-qa-id="constructionDetailsFloorInsulationThicknessLabel"
                                      >
                                        Thickness
                                      </InputLabel>

                                      <Grid container alignItems="center">
                                        <Grid item xs={10}>
                                          <TextField
                                            id="modal-construction-details-floor-insulation-thickness"
                                            data-qa-id="constructionDetailsFloorInsulationThicknessInput"
                                            variant="outlined"
                                            value={localState.floorInsulationLayerThickness || ''}
                                            disabled={!!calculation?.locked}
                                            onChange={(event: ChangeEvent<any>) => {
                                              const value = event.target.value;
                                              setLocalState(prevState => ({ ...prevState, floorInsulationLayerThickness: value }));
                                            }}
                                          />
                                        </Grid>

                                        <Grid item xs={2}>
                                          <div className={styles.inputUnit} data-qa-id="constructionDetailsfloorInsulationThicknessUnits">
                                            mm
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}

                        {applicationTypeIsSteelFrameWall(selectedApplicationType) && (
                          <React.Fragment>
                            <Grid container item xs={12}>
                              <Grid item xs={4}>
                                <FormLabel
                                  classes={{
                                    root: constructionStyles.formLabelUpsize,
                                  }}
                                >
                                  Cold or Hybrid Steel Frame
                                </FormLabel>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControlLabel
                                  data-qa-id="constructionDetailsWarmSteelFrameLabel"
                                  control={
                                    <Switch
                                      color="primary"
                                      checked={isWarmSteelFramedWall == null ? false : isWarmSteelFramedWall}
                                      data-qa-id="constructionDetailsWarmSteelFrameSwitch"
                                      name="isWarmSteelFramedWall"
                                      disabled={!!calculation?.locked}
                                      onChange={() => {
                                        setLocalState(prevState => ({
                                          ...prevState,
                                          isWarmSteelFramedWall: !prevState.isWarmSteelFramedWall,
                                        }));
                                      }}
                                    />
                                  }
                                  label="Warm Steel Frame"
                                  labelPlacement="end"
                                />
                              </Grid>
                              <div className={styles.verticalDivider} />
                              <Grid container>
                                <Grid item xs={4}>
                                  <InputLabel
                                    disabled={isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined}
                                    htmlFor="modal-construction-details-stud-depth"
                                    data-qa-id="constructionDetailsStudDepthLabel"
                                  >
                                    Stud depth
                                  </InputLabel>

                                  <Grid container alignItems="center">
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={!!calculation?.locked || !!isWarmSteelFramedWall}
                                        id="modal-construction-details-stud-depth"
                                        data-qa-id="constructionDetailsStudDepthInput"
                                        variant="outlined"
                                        value={localState.studDepthMillimetres || ''}
                                        onChange={(event: ChangeEvent<any>) => {
                                          const value = event.target.value;
                                          setLocalState(prevState => ({ ...prevState, studDepthMillimetres: value }));
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div className={styles.inputUnit} data-qa-id="constructionDetailsStudDepthUnits">
                                        mm
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                  <InputLabel
                                    disabled={isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined}
                                    htmlFor="modal-construction-details-stud-spacing"
                                    data-qa-id="constructionDetailsStudSpacingLabel"
                                  >
                                    Stud spacing
                                  </InputLabel>

                                  <Grid container alignItems="center">
                                    <Grid item xs={5}>
                                      <TextField
                                        id="modal-construction-details-stud-spacing"
                                        data-qa-id="constructionDetailsStudSpacingInput"
                                        variant="outlined"
                                        value={localState.studSpacingMillimetres || ''}
                                        disabled={!!calculation?.locked || !!isWarmSteelFramedWall}
                                        onChange={(event: ChangeEvent<any>) => {
                                          const value = event.target.value;
                                          setLocalState(prevState => ({ ...prevState, studSpacingMillimetres: value }));
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <div className={styles.inputUnit} data-qa-id="constructionDetailsStudSpacingUnits">
                                        mm
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <div className={styles.verticalDivider} />
                                <Grid container>
                                  <Grid item xs={12}>
                                    <FormControl disabled={isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined}>
                                      <RadioGroup
                                        name="is50To80MillimetreFlange"
                                        value={is50To80MillimetreFlange === null ? 'false' : is50To80MillimetreFlange.toString()}
                                        data-qa-id="constructionDetailsLargeFlangeSwitch"
                                        onChange={(event: ChangeEvent<any>) => {
                                          const value = event.target.value === 'true';
                                          setLocalState(prevState => ({ ...prevState, is50To80MillimetreFlange: value }));
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          disabled={!!calculation?.locked}
                                          value="false"
                                          control={<Radio />}
                                          label="Not Exceeding 50mm"
                                        />
                                        <FormControlLabel
                                          disabled={!!calculation?.locked}
                                          value="true"
                                          control={<Radio />}
                                          label="Not Exceeding 80mm"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}

                        {applicationTypeIsInvertedRoof(selectedApplicationType) && (
                          <React.Fragment>
                            <Grid container spacing={3}>
                              <Grid item xs={5}>
                                <InputLabel data-qa-id="ballastTypeLabel" id="calculation-details-ballast-type">
                                  Ballast Type
                                </InputLabel>

                                <Select
                                  fullWidth
                                  data-qa-id="ballastTypeInput"
                                  id="calculation-details-ballast-type"
                                  value={drainageFx?.id ?? ''}
                                  disabled={!!calculation?.locked}
                                  onChange={(event: ChangeEvent<any>) => {
                                    const value = event.target.value;
                                    setLocalState(prevState => ({
                                      ...prevState,
                                      drainageFx: drainageFxTypes.filter(d => d.id === value)[0],
                                    }));
                                  }}
                                  variant="outlined"
                                >
                                  {drainageFxTypes.map(drainageFxType => {
                                    return (
                                      <MenuItem key={drainageFxType.id} value={drainageFxType.id}>
                                        {drainageFxType.covering}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                              <Grid item xs={2}>
                                <InputLabel
                                  htmlFor="modal-construction-details-drainage-fx"
                                  data-qa-id="constructionDetailsDrainageFxLabel"
                                >
                                  Drainage (f.x)
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-details-drainage-fx"
                                      data-qa-id="constructionDetailsDrainageFxInput"
                                      variant="outlined"
                                      value={localState.drainageFx?.fxFactor}
                                      disabled
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <InputLabel
                                  htmlFor="modal-construction-details-details-postcode-area"
                                  data-qa-id="constructionDetailsPostcodeAreaLabel"
                                >
                                  Postcode Area
                                </InputLabel>
                                <Autocomplete
                                  id="modal-construction-details-details-postcode-are"
                                  data-qa-id="constructionDetailsPostcodeAreaInput"
                                  options={precipitationByPostCodeAreas}
                                  autoHighlight
                                  getOptionLabel={(option: MasterDataPrecipitationByPostCodeArea) => option.postCodeArea}
                                  value={precipitationForArea}
                                  disabled={!!calculation?.locked}
                                  onChange={(event: React.ChangeEvent<{}>, value: MasterDataPrecipitationByPostCodeArea | null) => {
                                    setLocalState(prevState => ({
                                      ...prevState,
                                      precipitationForArea: value,
                                    }));
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // Disable browser autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <InputLabel htmlFor="modal-construction-details-fainfall" data-qa-id="constructionDetailsRainfallLabel">
                                  Rainfall
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-construction-details-rainfall"
                                      data-qa-id="constructionDetailsRainfallInput"
                                      variant="outlined"
                                      value={localState.precipitationForArea?.rainfallMillimetres ?? ''}
                                      disabled
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}

                        {applicationTypeHasPitchAngle(selectedApplicationType) && (
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <InputLabel htmlFor="modal-construction-details-pitch-angle" data-qa-id="constructionDetailsPitchAngleLabel">
                                Pitch Angle
                              </InputLabel>

                              <Grid container alignItems="center">
                                <Grid item xs={3}>
                                  <TextField
                                    id="modal-construction-details-pitch-angle"
                                    data-qa-id="constructionDetailsPitchAngleInput"
                                    variant="outlined"
                                    value={localState.pitchAngle || ''}
                                    disabled={!!calculation?.locked}
                                    onChange={(event: ChangeEvent<any>) => {
                                      const value = event.target.value;
                                      setLocalState(prevState => ({ ...prevState, pitchAngle: value }));
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) && (
                          <Grid container spacing={3}>
                            <Grid item xs={11}>
                              <InputLabel
                                htmlFor="modal-construction-details-roof-characteristics"
                                data-qa-id="constructionDetailsRoofCharacteristicsLabel"
                              >
                                Roof Characteristics
                              </InputLabel>

                              <Select
                                fullWidth
                                data-qa-id="constructionDetailsRoofCharacteristicsInput"
                                id="modal-construction-details-roof-characteristics"
                                value={localState.pitchedRoofWithLoftDetails?.roofCharacteristicId || roofCharacteristicsOptions[0].id}
                                disabled={!!calculation?.locked}
                                onChange={(event: React.ChangeEvent<any>) =>
                                  setLocalState(prevState => ({
                                    ...prevState,
                                    pitchedRoofWithLoftDetails: {
                                      ...prevState.pitchedRoofWithLoftDetails!,
                                      roofCharacteristicId: Number(event.target.value),
                                    },
                                  }))
                                }
                                variant="outlined"
                              >
                                {roofCharacteristicsOptions.map(option => {
                                  return (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.description}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </Grid>

                            <Grid item xs={4}>
                              <InputLabel
                                htmlFor="modal-construction-details-loft-space-thickness"
                                data-qa-id="constructionDetailsLoftSpaceThicknessLabel"
                              >
                                Loft Space Thickness
                              </InputLabel>

                              <Grid container alignItems="center">
                                <Grid item xs={3}>
                                  <TextField
                                    id="modal-construction-details-loft-space-thickness"
                                    data-qa-id="constructionDetailsLoftSpaceThicknessInput"
                                    variant="outlined"
                                    value={localState.pitchedRoofWithLoftDetails?.thicknessMillimetres || ''}
                                    disabled={!!calculation?.locked}
                                    onChange={(event: ChangeEvent<any>) => {
                                      const value = event.target.value;
                                      setLocalState(prevState => ({
                                        ...prevState,
                                        pitchedRoofWithLoftDetails: {
                                          ...prevState.pitchedRoofWithLoftDetails!,
                                          thicknessMillimetres: value,
                                        },
                                      }));
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </div>

                      {applicationTypeHasConstructionDetailsResults(selectedApplicationType) && (
                        <div className={styles.tabContentResults}>
                          {applicationTypeHasSoilType(selectedApplicationType) && (
                            <Grid container spacing={10}>
                              <Grid item xs={6}>
                                <div className={styles.tabContentResult}>
                                  <Grid container>
                                    <Grid item xs={8} data-qa-id="soilThermalConductivityLabel">
                                      Soil Thermal Conductivity
                                    </Grid>

                                    <Grid item xs={4} data-qa-id="soilThermalConductivity">
                                      <div className={styles.tabContentResultValueUnitContainer}>
                                        <div className={styles.tabContentResultValue} data-qa-id="soilThermalConductivityValue">
                                          {groundThermalConductivity ?? '0.000'}
                                        </div>

                                        <div className={styles.tabContentResultUnit} data-qa-id="soilThermalConductivityUnit">
                                          W/mK
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.tabContentResult}>
                                  <Grid container>
                                    <Grid item xs={8} data-qa-id="soilVapourResistivityLabel">
                                      Soil Vapour Resistivity
                                    </Grid>

                                    <Grid item xs={4} data-qa-id="soilVapourResistivity">
                                      <div className={styles.tabContentResultValueUnitContainer}>
                                        <div className={styles.tabContentResultValue} data-qa-id="soilVapourResistivityValue">
                                          {groundVapourResistivity ?? '0.000'}
                                        </div>

                                        <div className={styles.tabContentResultUnit} data-qa-id="soilVapourResistivityUnit">
                                          MNs/gm
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          )}

                          {applicationTypeIsInvertedRoof(selectedApplicationType) && (
                            <Grid container spacing={10}>
                              <Grid item xs={6}>
                                <div className={styles.tabContentResult}>
                                  <Grid container>
                                    <Grid item xs={8} data-qa-id="RainwaterCorrectionLabel">
                                      Rainwater Cooling Correction
                                    </Grid>

                                    <Grid item xs={4} data-qa-id="RainwaterCorrection">
                                      <div className={styles.tabContentResultValueUnitContainer}>
                                        <div className={styles.tabContentResultValue} data-qa-id="RainwaterCorrectionValue">
                                          {calculation?.rainwaterCoolingCorrectionFactor ?? '0.000'}
                                        </div>

                                        <div className={styles.tabContentResultUnit} data-qa-id="RainwaterCorrectionUnit">
                                          W/m²K
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      )}
                    </TabPanel>
                  )}

                  {applicationTypeHasRainscreenCladding(selectedApplicationType) && (
                    <TabPanel
                      id="rainscreen-cladding-panel"
                      data-qa-id="rainscreen-cladding-panel"
                      targetIndex={1}
                      currentTabIndex={localState.selectedTab}
                      aria-labelledby="rainscreen-cladding-tab"
                    >
                      <div className={styles.tabContent}>
                        <Grid container spacing={3}>
                          <Grid container>
                            <Grid item xs={4}>
                              <FormControlLabel
                                data-qa-id="applyRainscreenCladdingLabel"
                                control={
                                  <Switch
                                    color="primary"
                                    checked={rainscreenCladdingDetailsPartial != null}
                                    data-qa-id="applyRainscreenCladdingSwitch"
                                    name="isRainscreenCladdingApplied"
                                    disabled={!!calculation?.locked}
                                    onChange={() => {
                                      setLocalState(prevState => ({
                                        ...prevState,
                                        rainscreenCladdingDetailsPartial:
                                          prevState.rainscreenCladdingDetailsPartial == null
                                            ? {
                                                chiValue: rainscreenCladdingDefaults?.chiValue ?? undefined,
                                                bracketsPerMetreSquared: rainscreenCladdingDefaults?.bracketsPerMetreSquared ?? undefined,
                                              }
                                            : undefined,
                                      }));
                                    }}
                                  />
                                }
                                label="Apply Rainscreen Cladding"
                                labelPlacement="end"
                              />
                            </Grid>
                          </Grid>
                          <div className={styles.verticalDivider} />
                          {rainscreenCladdingDetailsPartial != null && (
                            <Grid container item xs={6} spacing={3}>
                              {/* Chi Value */}
                              <Grid item xs={4}>
                                <InputLabel htmlFor="modal-rainscreen-cladding-chi-value" data-qa-id="rainscreenCladdingChiValueLabel">
                                  Chi Value
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-rainscreen-cladding-chi-value"
                                      data-qa-id="rainscreenCladdingChiValueInput"
                                      variant="outlined"
                                      value={localState.rainscreenCladdingDetailsPartial?.chiValue || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({
                                          ...prevState,
                                          rainscreenCladdingDetailsPartial:
                                            prevState.rainscreenCladdingDetailsPartial == null
                                              ? {
                                                  bracketsPerMetreSquared: undefined,
                                                  chiValue: value,
                                                }
                                              : {
                                                  ...prevState.rainscreenCladdingDetailsPartial,
                                                  chiValue: value,
                                                },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* Brackets Per Meter Squared */}
                              <Grid item xs={4}>
                                <InputLabel
                                  htmlFor="modal-rainscreen-cladding-brackets-per-metre-squared"
                                  data-qa-id="rainscreenCladdingBracketsPerMetreSquaredLabel"
                                >
                                  Brackets per m²
                                </InputLabel>

                                <Grid container alignItems="center">
                                  <Grid item xs={10}>
                                    <TextField
                                      id="modal-rainscreen-cladding-brackets-per-metre-squared"
                                      data-qa-id="rainscreenCladdingBracketsPerMetreSquaredInput"
                                      variant="outlined"
                                      value={localState.rainscreenCladdingDetailsPartial?.bracketsPerMetreSquared || ''}
                                      disabled={!!calculation?.locked}
                                      onChange={(event: ChangeEvent<any>) => {
                                        const value = event.target.value;
                                        setLocalState(prevState => ({
                                          ...prevState,
                                          rainscreenCladdingDetailsPartial:
                                            prevState.rainscreenCladdingDetailsPartial == null
                                              ? {
                                                  bracketsPerMetreSquared: value,
                                                  chiValue: undefined,
                                                }
                                              : {
                                                  ...prevState.rainscreenCladdingDetailsPartial,
                                                  bracketsPerMetreSquared: value,
                                                },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </div>

                      {/* Rainscreen cladding correction factor results */}
                      <div className={styles.tabContentResults}>
                        <Grid container spacing={10}>
                          <Grid item xs={6}>
                            <div className={styles.tabContentResult}>
                              <Grid container>
                                <Grid item xs={8} data-qa-id="rainscreenCladdingCorrectionLabel">
                                  Rainscreen Cladding Correction Factor
                                </Grid>

                                <Grid item xs={4} data-qa-id="rainscreenCladdingCorrection">
                                  <div className={styles.tabContentResultValueUnitContainer}>
                                    <div className={styles.tabContentResultValue} data-qa-id="rainscreenCladdingCorrectionValue">
                                      {rainscreenCladdingCorrectionFactor ?? '0.000'}
                                    </div>

                                    <div className={styles.tabContentResultUnit} data-qa-id="rainscreenCladdingCorrectionUnit">
                                      W/m²K
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </TabPanel>
                  )}

                  <NotesTab
                    selectedTab={selectedTab}
                    calculationTitle={calculationNotes.calculationTitle}
                    notes={calculationNotes.notes}
                    setLocalState={setLocalState}
                    isLocked={calculation?.locked}
                  />
                </div>
              )}
            </React.Fragment>
          </DialogContent>
        </div>

        <div className={styles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="layerCloseButton" onClick={props.onClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  data-qa-id="constructionSubmitButton"
                  fullWidth
                  disabled={!canSubmit()}
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}
