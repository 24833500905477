import { callApi } from "../common/api";
import { AppDispatch, RootState } from "../store/store";
import { Contact } from "../types/store/contactTypes";
import { actionTypes } from "./actionTypes";

export const associateContact = (contact: Contact, isNewContact: boolean, shouldAssociateToAll: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      calculation: { currentCalculation },
      inactiveCalculations,
    } = getState();

    const calculationIds = shouldAssociateToAll
      ? [currentCalculation?.calculationId, ...inactiveCalculations.map(c => c.calculationId)]
      : [currentCalculation?.calculationId];

    const apiMethod = isNewContact ? 'POST' : 'PUT';
    const apiEndpoint = isNewContact ? '/Contact' : `/Contact/${contact.id}`;

    const data = await callApi<Contact>(dispatch, apiMethod, apiEndpoint, {
      calculationIds,
      ...contact,
      contactType: contact.contactType || undefined,
      salesContactTeam: contact.salesContactTeam || undefined,
    });

    if (!data?.id) return;

    /**
     * Use "data" rather than the contact param as the data from the API will
     * contain the generated unique ID.
     */

    dispatch({
      type: actionTypes.UPDATE_CALCULATION_CONTACT,
      payload: { contact: data },
    });

    if (!shouldAssociateToAll) return;

    dispatch({
      type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_CONTACT,
      payload: { contact: data },
    });
  };
};
