import { createStore, applyMiddleware, AnyAction } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import { reducers } from '../reducers/reducers';

const middleware: any[] = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant());
}

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));

// Infer the `RootState` and `AppDispatch` types from the store itself
// Note that we need to support ThunkDispatch, this enables the "async function middleware": https://redux.js.org/tutorials/fundamentals/part-6-async-logic#using-the-redux-thunk-middleware
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = (typeof store.dispatch) & ThunkDispatch<RootState, unknown, AnyAction>;
