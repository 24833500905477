import { actionTypes } from '../../actions/actionTypes';
import { AirGapsCorrectionLevel } from './masterDataTypes';
import { InactiveCalculationsState } from './inactiveCalculationsTypes';
import { Project } from './projectTypes';
import { Contact } from './contactTypes';

export type ApiPostCalculationRequest = {
  businessUnitId: number;
  applicationDetails: {
    id: number;
  };
  calculationNotes?: CalculationNotes;
  layers: Array<ApiCalculationRequestLayer>;
  constructionDetails?: ApiConstructionDetailsRequest;
  rainscreenCladdingDetails?: ConstructionRainscreenCladdingDetails;
  envSettings?: ApiEnvSettingsRequest;
  saveCalculation: boolean;
  locked?: boolean;
  productSectorId?: number;
  projectInsulationVolumeM2?: number;
};

export type ApiCustomReferenceLayerRequest = {
  name: string;
  thermalResistance: string | null;
  thermalConductivity: string | null;
  insideEmissivity: string | null;
  outsideEmissivity: string | null;
  layerMaterialType: string | null;
  isCustom: true;
  isBlank: boolean;
};

export type ApiLayerBridgingRequest = {
  id: number;
  bridgeWidthMillimetres: string;
  centresDistanceMillimetres: string;
  nonBridgeHeightMillimetres: string | null;
};

export type ApiLayerMechanicalFastenerRequest = {
  id: number;
  crossSectionalAreaMillimetresSquared: string;
  fasteningsPerMetreSquared: string;
};

export type ApiLayerAirGapsCorrectionOverrideRequest = {
  overriddenCalculatedCorrectionFactor: AirGapsCorrectionLevel;
  overrideCorrectionFactor: AirGapsCorrectionLevel;
};

export type ApiLayerNotesRequest = {
  notes: string;
};

export type ApiCalculationRequestLayer = {
  id: number | null;
  isReadOnly?: boolean;
  instanceId: string;
  thicknessMillimetres: string | null;
  layerNotes?: ApiLayerNotesRequest;
  customReferenceLayer?: ApiCustomReferenceLayerRequest;
  layerBridging?: ApiLayerBridgingRequest;
  mechanicalFastener?: ApiLayerMechanicalFastenerRequest;
  airGapsCorrectionOverride?: ApiLayerAirGapsCorrectionOverrideRequest;
  vapourResistance?: string | null;
  vapourResistivity?: string | null;
};

export enum FloorInsulationLayerReferenceType {
  Master = 'Master',
  None = 'None',
  Custom = 'Custom',
}

export type ConstructionFloorInsulationReference = {
  insulationReferenceType: FloorInsulationLayerReferenceType;
  id: number | null;
};

export type ConstructionFloorInsulationDetails = {
  insulationReference: ConstructionFloorInsulationReference;
  thicknessMillimetres: string | null;
  thermalConductivity: string | null;
  insulationLayerInstanceId: string | null;
};

export type ConstructionRainscreenCladdingDetails = {
  chiValue: string;
  bracketsPerMetreSquared: string;
};

export type ConstructionPitchedRoofDetails = {
  pitchAngle: string;
};

export type ConstructionPitchedRoofWithLoftDetails = {
  roofCharacteristicId: number;
  thicknessMillimetres: string;
  loftLayerInstanceId: string;
};

export type CalculationNotes = {
  calculationTitle: string | null;
  notes: string | null;
};

export type ApiConstructionDetailsRequest = {
  soilTypeId: number | null;
  perimeterMetres: string | null;
  areaMetresSquared: string | null;
  perimeterAreaRatio: string | null;
  wallThicknessMetres: string | null;
  ventilationRateMillimetresSquaredPerMetre: string | null;
  thermalTransmittanceWallsInUnderFloorSpaceAboveGround: string | null;
  windSpeedMetresPerSecond: string | null;
  averageWindShieldingFactor: string | null;
  heightBelowGroundMetres: string | null;
  floorInsulationDetails?: ConstructionFloorInsulationDetails;
  steelWallDetails: ApiConstructionDetailsSteelWallRequest | null;
  invertedRoofDetails: ApiConstructionDetailsInvertedRoofRequest | null;
  pitchedRoofDetails?: ConstructionPitchedRoofDetails;
  pitchedRoofWithLoftDetails?: ConstructionPitchedRoofWithLoftDetails;
  widthOfEdgeInsulation: string | null;
};

export type ApiConstructionDetailsInvertedRoofRequest = {
  drainageFxId: number | null;
  drainageFx: string | null;
  precipitationForAreaId: number | null;
  precipitationForAreaMillimetres: string | null;
};

export type ApiConstructionDetailsSteelWallRequest = {
  isWarmSteelFramedWall: boolean | null;
  is50To80MillimetreFlange: boolean | null;
  studDepthMillimetres: string | null;
  studSpacingMillimetres: string | null;
};

export type ApiPutCalculationRequest = ApiPostCalculationRequest & {
  project: Project | null;
  contact: Contact | null;
};

export type ApiCalculationResponse = {
  calculationId: string;
  businessUnitId: number;
  countryCode: string;
  applicationDetails: ApiApplicationDetailsResponse;
  calculationNotes: CalculationNotes;
  constructionDetails?: ApiConstructionDetailsResponse;
  rainscreenCladdingDetails?: ConstructionRainscreenCladdingDetails;
  layers: Array<ApiCalculationResponseLayer>;
  mechanicalFastenersCorrectionFactor: string;
  airGapsCorrectionFactor: string;
  rainscreenCladdingCorrectionFactor: string;
  rainwaterCoolingCorrectionFactor: string;
  correctionFactor: string;
  totalThermalResistance: string;
  baseUValue: string;
  uValue: string;
  uValue2DP: string;
  isTotalCorrectionFactorBelowThreshold: boolean;
  envSettings?: EnvSettings;
  envConditions?: ApiEnvConditionResponse[];
  interstitialInterfaces?: ApiInterstitialInterfaceResponse[];
  project: Project | null;
  hasCondensation: boolean;
  locked?: boolean;
  productSectorId?: number;
  contact?: Contact | null;
  projectInsulationVolumeM2: number;
};

export type ApiCustomReferenceLayerResponse = ApiCustomReferenceLayerRequest;

export type ApiLayerNotesResponse = ApiLayerNotesRequest;

export type CustomReferenceLayer = ApiCustomReferenceLayerResponse;

export const customLayerDefaults: CustomReferenceLayer = {
  name: '',
  thermalResistance: null,
  thermalConductivity: null,
  insideEmissivity: null,
  outsideEmissivity: null,
  layerMaterialType: null,
  isCustom: true,
  isBlank: true,
};

export enum CustomLayerTypeOptions {
  Blank = 'Blank',
  Insulation = 'Insulation',
  NonInsulation = 'Non-Insulation',
  InvertedInsulation = 'Inverted Insulation',
}

export const customLayerTypeOptions = [
  CustomLayerTypeOptions.Blank,
  CustomLayerTypeOptions.Insulation,
  CustomLayerTypeOptions.NonInsulation,
  CustomLayerTypeOptions.InvertedInsulation,
];

export type ApiApplicationDetailsResponse = {
  id: number;
  type: string;
  insideSurfaceThermalResistance: string;
  outsideSurfaceThermalResistance: string;
};

export type ApiLayerBridgingResponse = {
  id: number;
  name: string;
  bridgeDescription: string;
  bridgeThermalConductivity: string;
  bridgePercentage: string;
  bridgeWidthMillimetres: string;
  centresDistanceMillimetres: string;
  nonBridgeHeightMillimetres: string | null;
};

export type ApiLayerMechanicalFastenerResponse = {
  id: number;
  name: string;
  alphaCoefficient: string;
  crossSectionalAreaMillimetresSquared: string;
  thermalConductivity: string;
  fasteningsPerMetreSquared: string;
  correctionFactor: string;
};

export type ApiLayerAirGapsCorrectionResponse = {
  calculatedCorrectionFactor: AirGapsCorrectionLevel;
  overrideCorrectionFactor: AirGapsCorrectionLevel | null;
  correctionFactorResult: AirGapsCorrectionLevel;
  airGapsCorrectionResultValue: string;
};

export type ApiCalculationResponseLayer = {
  id: number | null;
  isReadOnly?: boolean;
  instanceId: string;
  name: string;
  thicknessMillimetres: string;
  thermalConductivity: string;
  insideEmissivity: string;
  outsideEmissivity: string;
  thermalResistance: string;
  layerNotes?: ApiLayerNotesResponse;
  customReferenceLayer?: ApiCustomReferenceLayerResponse;
  layerBridging?: ApiLayerBridgingResponse;
  mechanicalFastener?: ApiLayerMechanicalFastenerResponse;
  airGapsCorrection?: ApiLayerAirGapsCorrectionResponse;
  constructionDetails?: ApiConstructionDetailsResponse;
  vapourResistance?: string;
  vapourResistivity?: string;
};

export type ApiConstructionDetailsResponse = ApiConstructionDetailsRequest & {
  groundThermalConductivity: string | null;
};

export type CalculationLayer = ApiCalculationResponseLayer;

export enum EnvConditionType {
  InternalTemperature = 'InternalTemperature',
  InternalRelativeHumidity = 'InternalRelativeHumidity',
  ExternalTemperature = 'ExternalTemperature',
  ExternalRelativeHumidity = 'ExternalRelativeHumidity',
}

export type ApiMonthValueResponse = {
  id: number;
  name: string;
  value: string;
};

export type ApiEnvConditionResponse = {
  envConditionType: EnvConditionType;
  name: string;
  months: ApiMonthValueResponse[];
  unit: string;
};

export type ApiEnvSettingsRequest = {
  buildingRegionId?: number;
  buildingTypeId?: number;
  internalTemperature?: string;
  riskLevelId?: number;
  envConditionOverrides?: ApiEnvConditionOverride[];
};

export type ApiInterstitialMonthResponse = {
  condensationAccumulationAmount: string;
  condensationAmount: string;
  id: number;
  saturationVapourPressure: string;
  vapourPressure: string;
};

export type ApiInterstitialInterfaceResponse = {
  id: string;
  innerLayerInstanceId: string;
  outerLayerInstanceId: string;
  months: ApiInterstitialMonthResponse[];
  name: string;
  peakCondensationAccumulationAmount: string;
  peakCondensationAccumulationAmountMonth: string;
  peakCondensationAmount: string;
  peakCondensationAmountMonth: string;
  worstMonthDewpointTemperature: string;
  worstMonthInterfaceTemperature: string;
};

export type InterstitialMonth = ApiInterstitialMonthResponse;

export type InterstitialInterface = ApiInterstitialInterfaceResponse;

export type ApiEnvSettingsResponse = ApiEnvSettingsRequest;

export type EnvSettings = ApiEnvSettingsRequest;

export type MonthValue = ApiMonthValueResponse;

export type EnvCondition = ApiEnvConditionResponse & {
  months: MonthValue[];
};

export type ApiMonthOverride = {
  id: number;
  value: string;
};

export type ApiEnvConditionOverride = {
  envConditionType: EnvConditionType;
  months: ApiMonthOverride[];
};

export type MonthOverride = ApiMonthOverride;

export type EnvConditionOverride = ApiEnvConditionOverride;

export type Calculation = ApiCalculationResponse & {
  order: number;
  envConditions?: EnvCondition[];
  envSettings?: EnvSettings;
  layers: Array<CalculationLayer>;

};

export type CalculationState = {
  currentCalculation: Calculation | null;
  interimCalculation: Calculation | null;
  savedSuccessfully: boolean | null;
};

export type RegisterCalculationAction = {
  type: actionTypes.REGISTER_CALCULATION;
  payload: {
    calculation: ApiCalculationResponse;
    order: number;
  };
};

export type ChangeActiveCalculationAction = {
  type: actionTypes.CHANGE_ACTIVE_CALCULATION;
  payload: {
    previousActiveCalculation: Calculation;
    newActiveCalculation: Calculation;
  };
};

export type MakeAllCalculationsInactiveAction = {
  type: actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE;
  payload: {
    previousActiveCalculation: Calculation | null;
  };
};

export type CloseActiveCalculationAction = {
  type: actionTypes.CLOSE_ACTIVE_CALCULATION;
  payload: {
    newActiveCalculation?: Calculation;
    newInactiveCalculations: InactiveCalculationsState;
  };
};

export type SetActiveCalculationDataAction = {
  type: actionTypes.SET_ACTIVE_CALCULATION_DATA;
  payload: {
    newCalculationData: ApiCalculationResponse | Calculation | null;
    shouldUseExistingCalculationId: boolean;
    order: number;
  };
};

export type AddLayerToCalculationAction = {
  type: actionTypes.ADD_LAYER_TO_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type EditLayerInCalculationAction = {
  type: actionTypes.EDIT_LAYER_IN_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type MoveLayerInCalculationAction = {
  type: actionTypes.MOVE_LAYER_IN_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type InterimMoveLayerInCalculationAction = {
  type: actionTypes.INTERIM_MOVE_LAYER_IN_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type DuplicateLayerInCalculationAction = {
  type: actionTypes.DUPLICATE_LAYER_IN_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type RemoveLayerFromCalculationAction = {
  type: actionTypes.REMOVE_LAYER_FROM_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type LockCalculationAction = {
  type: actionTypes.LOCK_CALCULATION;
  payload: {
    isCurrentCalculation: boolean;
    calculation: ApiCalculationResponse;
  };
};

export type AddLayerToInterimCalculationAction = {
  type: actionTypes.ADD_LAYER_TO_INTERIM_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type EditLayerInInterimCalculationAction = {
  type: actionTypes.EDIT_LAYER_IN_INTERIM_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type AddConstructionDetailsToCalculationAction = {
  type: actionTypes.ADD_CONSTRUCTION_DETAILS_TO_CALCULATION;
  payload: {
    calculation: Calculation;
  };
};

export type ClearInterimCalculationAction = {
  type: actionTypes.CLEAR_INTERIM_CALCULATION;
};

export type EditEnvSettingsAction = {
  type: actionTypes.EDIT_ENV_SETTINGS;
  payload: {
    calculation: Calculation;
  };
};

export type EditEnvConditionsAction = {
  type: actionTypes.EDIT_ENV_CONDITIONS;
  payload: {
    calculation: Calculation;
  };
};

export type SaveEnvConditionsAction = {
  type: actionTypes.SAVE_ENV_CONDITIONS;
  payload: {
    calculation: Calculation;
  };
};

export type ClearCalculationSaveSuccessAction = {
  type: actionTypes.CLEAR_CALCULATION_SAVE_SUCCESS;
};

export type SetCalculationSaveSuccessAction = {
  type: actionTypes.SET_CALCULATION_SAVE_SUCCESS;
};

export type UpdateCalculationProjectAction = {
  type: actionTypes.UPDATE_CALCULATION_PROJECT;
  payload: { project: Project };
};

export type UpdateInactiveCalculationsProjectAction = {
  type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_PROJECT;
  payload: { project: Project };
};

export type UpdateCalculationContactAction = {
  type: actionTypes.UPDATE_CALCULATION_CONTACT;
  payload: { contact: Contact };
};

export type UpdateInactiveCalculationsContactAction = {
  type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_CONTACT;
  payload: { contact: Contact };
};

export type CalculationAction =
  | ChangeActiveCalculationAction
  | MakeAllCalculationsInactiveAction
  | SetActiveCalculationDataAction
  | AddLayerToCalculationAction
  | EditLayerInCalculationAction
  | InterimMoveLayerInCalculationAction
  | MoveLayerInCalculationAction
  | DuplicateLayerInCalculationAction
  | RemoveLayerFromCalculationAction
  | RegisterCalculationAction
  | CloseActiveCalculationAction
  | AddLayerToInterimCalculationAction
  | EditLayerInInterimCalculationAction
  | ClearInterimCalculationAction
  | AddConstructionDetailsToCalculationAction
  | EditEnvSettingsAction
  | EditEnvConditionsAction
  | SaveEnvConditionsAction
  | ClearCalculationSaveSuccessAction
  | SetCalculationSaveSuccessAction
  | UpdateCalculationProjectAction
  | UpdateCalculationContactAction
  | LockCalculationAction;
