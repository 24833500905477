import React, { FormEvent } from 'react';
import download from 'downloadjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import { without, uniq } from 'lodash';

import { exportPdf } from '../../actions/pdfActions';
import { MasterDataBranding } from '../../types/store/masterDataTypes';
import {
  masterDataBradingSelector,
  pdfExportsSelector,
  userProfileSelector,
  interimOrCurrentCalculationSelector,
  pdfPendingDownloadsSelector,
  inactiveCalculationsByBusinessUnitSelector,
} from '../../store/selectors';
import { UserProfileState } from '../../types/store/userProfileTypes';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './PdfModal.module.scss';
import { callApi } from '../../common/api';
import { Calculation } from '../../types/store/calculationTypes';
import { InactiveCalculationsState } from '../../types/store/inactiveCalculationsTypes';
import { actionTypes } from '../../actions/actionTypes';
import { ApiResponseExportPdf } from '../../types/store/pdfTypes';

type Props = {
  onClose: () => void;
};

enum PreviewStatus {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

enum ExportStatus {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export function PdfModal(props: Props) {
  const { onClose } = props;
  const dispatch = useDispatch();

  const exports = useSelector(pdfExportsSelector);
  const pendingDownloads = useSelector(pdfPendingDownloadsSelector);
  const branding: MasterDataBranding[] = useSelector(masterDataBradingSelector);
  const userProfile: UserProfileState = useSelector(userProfileSelector);
  const currentCalculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);
  const inactiveCalculations: InactiveCalculationsState = useSelector(inactiveCalculationsByBusinessUnitSelector);

  const [previewStatus, setPreviewStatus] = React.useState<PreviewStatus | null>(null);
  const [exportStatus, setExportStatus] = React.useState<ExportStatus | null>(null);
  const [selectedCalculations, setSelectedCalculations] = React.useState<string[]>(
    currentCalculation ? [currentCalculation.calculationId] : []
  );
  const [previewErrors, setPreviewErrors] = React.useState<string[]>([]);
  const [exportErrors, setExportErrors] = React.useState<string[]>([]);
  const [shouldApplyWatermark, setShouldApplyWatermark] = React.useState<boolean>(false);

  const userGroup = branding.find(b => b.adGroups === userProfile?.primaryBusinessUnitId)?.id ?? branding[0]?.id ?? 0;
  const [brandId, setBrandId] = React.useState(userGroup);

  const changeTemplate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBrandId(event.target.value as number);
  };

  const countPreviewErrors = previewErrors.length;
  const countExportErrors = exportErrors.length;

  React.useEffect(() => {
    if (countPreviewErrors > 0) {
      setPreviewStatus(PreviewStatus.ERROR);
    }

    if (countExportErrors > 0) {
      setExportStatus(ExportStatus.ERROR);
    }
  }, [countPreviewErrors, countExportErrors]);

  const handlePreviewPdf = async () => {
    if (currentCalculation) {
      setPreviewErrors([]);
      setExportErrors([]);
      setPreviewStatus(PreviewStatus.PENDING);
      setExportStatus(null);

      try {
        await Promise.all(
          selectedCalculations.map(async selectedCalculation => {
            try {
              const data = await callApi<Blob>(
                dispatch,
                'GET',
                `/PDF/${selectedCalculation}/${brandId}?showWatermark=${shouldApplyWatermark}`,
                undefined,
                'blob'
              );

              if (!data) return;

              const previewUrl = window.URL.createObjectURL(data);

              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              window.open(previewUrl, '_blank')!;

              window.URL.revokeObjectURL(previewUrl);
            } catch (e) {
              setPreviewErrors(prevState => [...prevState, selectedCalculation]);

              throw e;
            }
          })
        );

        setPreviewStatus(PreviewStatus.SUCCESS);
      } catch (e) { }
    }
  };

  React.useEffect(() => {
    pendingDownloads.forEach(({ uploadSuccess, pdfFile, fileName, calculationId }) => {
      if (uploadSuccess) {
        if (pdfFile && fileName) {
          download(pdfFile, fileName, 'application/pdf');
        }

        setExportStatus(ExportStatus.SUCCESS);
      } else {
        setExportErrors(prevState => uniq([...prevState, calculationId]));
        setExportStatus(ExportStatus.ERROR);
      }
    });
  }, [pendingDownloads]);

  const handleExportPdf = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setPreviewErrors([]);
      setExportErrors([]);
      setPreviewStatus(null);
      setExportStatus(ExportStatus.PENDING);

      try {
        await Promise.all(
          selectedCalculations.map(async selectedCalculation => {
            const exported = exports.find(e => e.calculationId === selectedCalculation);
            if (!exported?.uploadSuccess) {
              try {
                await dispatch(exportPdf(selectedCalculation, brandId, shouldApplyWatermark));

                dispatch({
                  type: actionTypes.CLEAR_PENDING_PDF_DOWNLOADS,
                });
              } catch (e) {
                setExportErrors(prevState => [...prevState, selectedCalculation]);

                throw e;
              }
            }
          })
        );
      } catch (e) { }
    },
    [dispatch, brandId, exports, selectedCalculations, shouldApplyWatermark]
  );

  const updateCalculationsList = React.useCallback(
    (isChecked, calculationId) => {
      if (isChecked) {
        setSelectedCalculations(prevState => [...prevState, calculationId]);
      } else {
        setSelectedCalculations(prevState => without(prevState, calculationId));
      }
    },
    [setSelectedCalculations]
  );

  const areNoCalculationsSelected = selectedCalculations.length === 0;
  const areSomeNotAllCalculationsSelected =
    selectedCalculations.length !== 0 && selectedCalculations.length !== inactiveCalculations.length + 1;
  const areAllCalculationsSelected = selectedCalculations.length === inactiveCalculations.length + 1;
  const areOnlyExportedCalculationsSelected =
    !areNoCalculationsSelected && selectedCalculations.every(c => exports.find(e => e.calculationId === c)?.uploadSuccess);

  const disableExport =
    areNoCalculationsSelected ||
    areOnlyExportedCalculationsSelected ||
    previewStatus === PreviewStatus.PENDING ||
    exportStatus === ExportStatus.PENDING;

  const renderExportStatusIcon = (pdfExport?: ApiResponseExportPdf) =>
    pdfExport ? (
      <Tooltip arrow placement="right" title="Open in SharePoint">
        <a href={pdfExport.url} target="_blank" rel="noopener noreferrer" className={styles.exportIconContainer}>
          <CloudDoneIcon className={styles.cloudDoneIcon} />
        </a>
      </Tooltip>
    ) : (
      <Tooltip arrow placement="right" title="Not yet exported to SharePoint">
        <span className={styles.exportIconContainer}>
          <CloudOutlinedIcon className={styles.cloudIcon} />
        </span>
      </Tooltip>
    );

  return (
    <Dialog open onClose={onClose}>
      <form data-qa-id="pdfModalForm" onSubmit={handleExportPdf} noValidate>
        <div className={styles.modalHeader}>
          <DialogTitle id="modal-layer-title">
            <Typography component="span" variant="h5">Export PDF</Typography>
          </DialogTitle>
        </div>

        <div className={`${[modalStyles.modalContent, styles.modalContent].join(' ')}`}>
          <DialogContent>
            {previewStatus === PreviewStatus.ERROR && (
              <Alert severity="error" className={styles.alert} data-qa-id="previewPdfError">
                Could not generate a preview for the following calculation(s):
                <ul>
                  {previewErrors.map(id => (
                    <li key={id}>{id}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {exportStatus === ExportStatus.ERROR && (
              <Alert severity="error" className={styles.alert} data-qa-id="exportPdfError">
                Could not export the following calculation(s) to SharePoint:
                <ul>
                  {exportErrors.map(id => (
                    <li key={id}>{id}</li>
                  ))}
                </ul>
              </Alert>
            )}

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={styles.checkboxListContainer} data-qa-id="calculationCheckboxContainer">
                  <div className={styles.checkboxList}>
                    <FormControlLabel
                      label={
                        <InputLabel htmlFor="pdfSelectCalculationsCheckbox" data-qa-id="pdfSelectCalculationsLabel">
                          Select Calculations:
                        </InputLabel>
                      }
                      control={
                        <div className={styles.raisedCheckboxContainer}>
                          <Checkbox
                            id="pdfSelectCalculationsCheckbox"
                            data-qa-id="pdfSelectCalculationsCheckbox"
                            name={'All'}
                            checked={areAllCalculationsSelected}
                            indeterminate={areSomeNotAllCalculationsSelected}
                            onChange={(_: any, isChecked: boolean) => {
                              if (!currentCalculation) return;

                              if (isChecked) {
                                setSelectedCalculations(
                                  areSomeNotAllCalculationsSelected
                                    ? []
                                    : [currentCalculation.calculationId, ...inactiveCalculations.map(({ calculationId }) => calculationId)]
                                );
                              } else {
                                setSelectedCalculations([]);
                              }
                            }}
                          />
                        </div>
                      }
                    />

                    <hr />

                    {currentCalculation && (
                      <FormGroup>
                        <FormControlLabel key="FormControlLabel-currentCalculation"
                          label={
                            <div>
                              <span className={styles.checkboxListLabel}>{currentCalculation.calculationId}</span>
                              <span className={styles.checkboxListLabelEmphasis}>(current)</span>
                              {renderExportStatusIcon(
                                exports.find(pdf => pdf.uploadSuccess && pdf.calculationId === currentCalculation.calculationId)
                              )}
                            </div>
                          }
                          control={
                            <Checkbox
                              key={currentCalculation.calculationId}
                              name={currentCalculation.calculationId}
                              checked={selectedCalculations.includes(currentCalculation.calculationId)}
                              onChange={(_: any, isChecked: boolean) => updateCalculationsList(isChecked, currentCalculation.calculationId)}
                            />
                          }
                        />

                        {inactiveCalculations.map(inactiveCalculation => (
                          <FormControlLabel key={`FormControlLabel-inactiveCalculation-${inactiveCalculation.calculationId}`}
                            label={
                              <div>
                                <span>{inactiveCalculation.calculationId}</span>
                                {renderExportStatusIcon(
                                  exports.find(pdf => pdf.uploadSuccess && pdf.calculationId === inactiveCalculation.calculationId)
                                )}
                              </div>
                            }
                            control={
                              <Checkbox
                                key={inactiveCalculation.calculationId}
                                name={inactiveCalculation.calculationId}
                                checked={selectedCalculations.includes(inactiveCalculation.calculationId)}
                                onChange={(_: any, isChecked: boolean) =>
                                  updateCalculationsList(isChecked, inactiveCalculation.calculationId)
                                }
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="pdfTemplateInput" data-qa-id="pdfTemplateLabel">
                  Select Template:
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="pdfTemplateInput"
                  id="pdfTemplateInput"
                  value={brandId}
                  onChange={changeTemplate}
                  variant="outlined"
                >
                  {branding.map(({ id, brandName, country }) => (
                    <MenuItem key={id} value={id}>
                      {brandName} {country}
                    </MenuItem>
                  ))}
                </Select>

                <div className={styles.watermarkCheckboxContainer} data-qa-id="watermarkCheckboxContainer">
                  <FormControlLabel
                    label="Apply a 'Not Recommended' watermark"
                    control={
                      <Checkbox
                        name="watermark"
                        checked={shouldApplyWatermark}
                        onChange={(_: any, isChecked: boolean) => setShouldApplyWatermark(isChecked)}
                      />
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </div>

        <div className={styles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={6} justify="flex-start" alignItems="center">
                <span>
                  <b>Note</b>: Exporting will lock the calculation
                </span>
              </Grid>
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="cancelPdfButton" onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="button"
                  onClick={handlePreviewPdf}
                  data-qa-id="previewPdfButton"
                  disabled={areNoCalculationsSelected || previewStatus === PreviewStatus.PENDING || exportStatus === ExportStatus.PENDING}
                  fullWidth
                  color="primary"
                  variant="outlined"
                >
                  {previewStatus === PreviewStatus.PENDING ? <CircularProgress size={28} /> : 'Preview'}
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button type="submit" data-qa-id="exportPdfButton" disabled={disableExport} fullWidth color="primary" variant="contained">
                  {exportStatus === ExportStatus.PENDING ? <CircularProgress size={28} /> : 'Export'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}
