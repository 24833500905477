import { getEmptyMasterData, MasterDataAction, MasterDataState } from '../types/store/masterDataTypes';
import { actionTypes } from '../actions/actionTypes';

const initialState: MasterDataState = {
  ...getEmptyMasterData(),
  isLoadingMasterData: false,
};

export const masterDataReducer = (state: MasterDataState = initialState, action: MasterDataAction) => {
  switch (action.type) {
    case actionTypes.FETCH_MASTER_DATA:
      return {
        ...action.payload.masterData,
        isLoadingMasterData: state.isLoadingMasterData,
      };
    case actionTypes.SET_IS_LOADING_MASTER_DATA:
      return {
        ...state,
        isLoadingMasterData: action.payload.isLoadingMasterData,
      };
    default:
      return state;
  }
};
