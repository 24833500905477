import { callApi } from '../common/api';
import { AppDispatch } from '../store/store';
import { MasterDataType } from '../types/store/masterDataUploadTypes';

const utf8EncodeFile = async (file: File): Promise<ProgressEvent<FileReader>> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = resolve;
    reader.readAsText(file);
  });

export const uploadMasterData = (dataType: MasterDataType, file: File, businessUnitId: number) => {
  return async (dispatch: AppDispatch) => {
    await callApi<any>(dispatch, 'POST', `/MasterDataUpload`, {
      masterDataType: dataType,
      csvFormData: (await utf8EncodeFile(file)).target?.result as string,
      businessUnitId,
    });
  };
};
