import { cloneDeep } from 'lodash';

import { actionTypes } from '../actions/actionTypes';
import { InactiveCalculationsAction, InactiveCalculationsState } from '../types/store/inactiveCalculationsTypes';

const initialState: InactiveCalculationsState = [];

export const inactiveCalculationsReducer = (state: InactiveCalculationsState = initialState, action: InactiveCalculationsAction) => {
  switch (action.type) {
    case actionTypes.CHANGE_ACTIVE_CALCULATION: {
      const newState = cloneDeep(state);
      const previousActiveCalculation = cloneDeep(action.payload.previousActiveCalculation);
      const indexOfCalculationToRemove = newState.findIndex(c => c.calculationId === action.payload.newActiveCalculation.calculationId);

      newState.splice(indexOfCalculationToRemove, 1);

      return [previousActiveCalculation, ...newState];
    }
    case actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE: {
      return action.payload.previousActiveCalculation ? [action.payload.previousActiveCalculation, ...state] : state;
    }
    case actionTypes.CLOSE_ACTIVE_CALCULATION: {
      return action.payload.newInactiveCalculations;
    }
    case actionTypes.LOCK_CALCULATION: {
      if (!action.payload.isCurrentCalculation) {
        const newState = cloneDeep(state);
        const { calculation } = action.payload;
        return newState.map(calc => (calc.calculationId === calculation.calculationId ? { ...calculation, order: calc.order } : calc));
      } else {
        return state;
      }
    }
    case actionTypes.UPDATE_INACTIVE_CALCULATIONS_PROJECT: {
      return state.map(calculation => ({ ...calculation, project: action.payload.project }));
    }
    case actionTypes.UPDATE_INACTIVE_CALCULATIONS_CONTACT: {
      return state.map(calculation => ({ ...calculation, contact: action.payload.contact }));
    }
    default:
      return state;
  }
};
