import React, { ChangeEvent, useState } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';

import styles from '../LayerModal/LayerModal.module.scss';
import { TabPanel } from '../LayerModal/TabPanel';
import { ConstructionModalState } from './ConstructionModalStateTypes';
import { MasterDataCalculationTitle } from '../../types/store/masterDataTypes';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { calculationTitleSelector } from '../../store/selectors';

export type NotesTabProps = {
  selectedTab: number | false;
  calculationTitle: string | null;
  notes: string | null;
  setLocalState: (value: React.SetStateAction<ConstructionModalState>) => void;
  isLocked?: boolean;
};

export function NotesTab(props: NotesTabProps) {
  const { selectedTab, calculationTitle, notes, setLocalState } = props;
  const calculationTitles: MasterDataCalculationTitle[] = useSelector(calculationTitleSelector);
  const [filteredCalculationTitles, setFilteredCalculationTitles] = useState<MasterDataCalculationTitle[]>(calculationTitles)

  const filterCalculationTitles = (filterValue: string) => {
    var filteredList: MasterDataCalculationTitle[] = []
    calculationTitles.forEach(element => {
      if (element.title.includes(filterValue)) {
        filteredList.push(element)
      }
    });
    setFilteredCalculationTitles(filteredList)
  }

  return (
    <TabPanel id="notes-tab-panel" data-qa-id="notes-tab-panel" targetIndex={2} currentTabIndex={selectedTab} aria-labelledby="notes-tab">
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel htmlFor="calculation-notes-calculation-title" data-qa-id="calculationNotesCalculationTitleLabel">
              Calculation Title
            </InputLabel>

            <Grid item xs={10}>
              <Autocomplete
                id="calculation-notes-calculation-title"
                data-qa-id="calculationNotesCalculationTitleInput"
                freeSolo
                value={calculationTitle ?? calculationTitles[0].title}
                autoHighlight
                disabled={!!props.isLocked}
                onInputChange={(event: any, newValue: string | null) => {
                  if (newValue) {
                    filterCalculationTitles(newValue)
                  }
                  setLocalState(prevState => ({
                    ...prevState,
                    calculationNotes: {
                      ...prevState.calculationNotes,
                      calculationTitle: newValue,
                    },
                  }));
                }}
                options={filteredCalculationTitles.map((option) => option.title)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                }
              />
            </Grid>
          </Grid>
          <div className={styles.verticalDivider} />
          <Grid item xs={12}>
            <InputLabel htmlFor="calculation-notes-calculation-notes" data-qa-id="calculationNotesCalculationNotesLabel">
              Supporting information about this calculation
            </InputLabel>

            <Grid item xs={10}>
              <TextField
                id="calculation-notes-calculation-notes"
                multiline
                fullWidth
                rows={4}
                data-qa-id="calculationNotesCalculationNotesInput"
                variant="outlined"
                value={notes || ''}
                disabled={!!props.isLocked}
                onChange={(event: ChangeEvent<any>) => {
                  const value = event.target.value;
                  setLocalState(prevState => ({
                    ...prevState,
                    calculationNotes: {
                      ...prevState.calculationNotes,
                      notes: value,
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
