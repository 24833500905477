import { AxiosError, AxiosResponse } from 'axios';
import { actionTypes } from '../../actions/actionTypes';

export type ApiClientError = {
  errorCode: string;
  message: string;
  args?: { [key: string]: string };
};

export type ApiServerError = {
  errorMessage: string;
  path: string | null;
  method: string | null;
  requestId: string | null;
  exception: string; // Will always equal "[REACTED]" in non-Dev/Test environments
};

export function isApiClientError(e: unknown): e is AxiosError<ApiClientError> & { response: AxiosResponse<ApiClientError> } {
  const responseData = (e as AxiosError<ApiClientError | null>)?.response?.data;
  const candidate: ApiClientError | null | undefined = responseData;
  return candidate !== null && candidate !== undefined &&
    typeof candidate === 'object' &&
    typeof candidate.message === 'string' &&
    typeof candidate.errorCode === 'string';
}

function getGenericErrorMessage(e: unknown) {
  const statusCode = (e as AxiosError<unknown>)?.response?.status;
  return statusCode ? `Unknown error (status code ${statusCode})` : 'Unknown error';
}

export function getApiClientErrorMessage(e: unknown): string {
  return isApiClientError(e) ? e.response.data.message : getGenericErrorMessage(e);
}

export function isApiServerError(e: unknown): e is AxiosError<ApiServerError> & { response: AxiosResponse<ApiServerError> } {
  const responseData = (e as AxiosError<ApiServerError | null>)?.response?.data;
  const candidate: ApiServerError | null | undefined = responseData;
  return candidate !== null && candidate !== undefined &&
    typeof candidate === 'object' &&
    typeof candidate.errorMessage === 'string' &&
    typeof candidate.exception === 'string'; // Will always equal "[REACTED]" in non-Dev/Test environments
}

export function getApiServerErrorMessage(e: unknown): string {
  return isApiServerError(e)
    ? `${e.response.data.errorMessage} (${e.response.data.exception.substring(0, 150)}) [${e.response.data.requestId}]`
    : getGenericErrorMessage(e);
}

export type ApiState = {
  isFirstCallSuccessful: boolean;
  isAuthenticated: boolean;
  isAuthorised: boolean;
};

export type ApiCallSuccessfulAction = {
  type: actionTypes.API_CALL_SUCCESSFUL;
};

export type SetUnauthenticatedAction = {
  type: actionTypes.SET_UNAUTHENTICATED;
};

export type SetUnauthorisedAction = {
  type: actionTypes.SET_UNAUTHORISED;
};

export type ApiAction = ApiCallSuccessfulAction | SetUnauthenticatedAction | SetUnauthorisedAction;
