export enum actionTypes {
  // API action types
  API_CALL_SUCCESSFUL = 'API_CALL_SUCCESSFUL',
  SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED',
  SET_UNAUTHORISED = 'SET_UNAUTHORISED',
  // MasterData action types
  FETCH_MASTER_DATA = 'FETCH_MASTER_DATA',
  SET_IS_LOADING_MASTER_DATA = 'SET_IS_LOADING_MASTER_DATA',
  // MasterDataUpload action types
  UPLOAD_MASTER_DATA = 'UPLOAD_MASTER_DATA',
  // Calculation action types
  REGISTER_CALCULATION = 'REGISTER_CALCULATION',
  CLOSE_ACTIVE_CALCULATION = 'CLOSE_ACTIVE_CALCULATION',
  ADD_LAYER_TO_CALCULATION = 'ADD_LAYER_TO_CALCULATION',
  EDIT_LAYER_IN_CALCULATION = 'EDIT_LAYER_IN_CALCULATION',
  INTERIM_MOVE_LAYER_IN_CALCULATION = 'INTERIM_MOVE_LAYER_IN_CALCULATION',
  MOVE_LAYER_IN_CALCULATION = 'MOVE_LAYER_IN_CALCULATION',
  DUPLICATE_LAYER_IN_CALCULATION = 'DUPLICATE_LAYER_IN_CALCULATION',
  REMOVE_LAYER_FROM_CALCULATION = 'REMOVE_LAYER_FROM_CALCULATION',
  LOCK_CALCULATION = 'LOCK_CALCULATION',
  ADD_CONSTRUCTION_DETAILS_TO_CALCULATION = 'ADD_CONSTRUCTION_DETAILS_TO_CALCULATION',
  CHANGE_ACTIVE_CALCULATION = 'CHANGE_ACTIVE_CALCULATION',
  MAKE_ALL_CALCULATIONS_INACTIVE = 'MAKE_ALL_CALCULATIONS_INACTIVE',
  SET_ACTIVE_CALCULATION_DATA = 'SET_ACTIVE_CALCULATION_DATA',
  CLEAR_CALCULATION_SAVE_SUCCESS = 'CLEAR_CALCULATION_SAVE_SUCCESS',
  SET_CALCULATION_SAVE_SUCCESS = 'SET_CALCULATION_SAVE_SUCCESS',
  UPDATE_CALCULATION_PROJECT = 'UPDATE_CALCULATION_PROJECT',
  UPDATE_INACTIVE_CALCULATIONS_PROJECT = 'UPDATE_INACTIVE_CALCULATIONS_PROJECT',
  UPDATE_CALCULATION_CONTACT = 'UPDATE_CALCULATION_CONTACT',
  UPDATE_INACTIVE_CALCULATIONS_CONTACT = 'UPDATE_INACTIVE_CALCULATIONS_CONTACT',
  // Interim calculation action types
  ADD_LAYER_TO_INTERIM_CALCULATION = 'ADD_LAYER_TO_INTERIM_CALCULATION',
  EDIT_LAYER_IN_INTERIM_CALCULATION = 'EDIT_LAYER_IN_INTERIM_CALCULATION',
  CLEAR_INTERIM_CALCULATION = 'CLEAR_INTERIM_CALCULATION',
  // Condensation action types
  EDIT_ENV_SETTINGS = 'EDIT_ENV_SETTINGS',
  EDIT_ENV_CONDITIONS = 'EDIT_ENV_CONDITIONS',
  SAVE_ENV_CONDITIONS = 'SAVE_ENV_CONDITIONS',
  //PDF action types
  EXPORT_PDF = 'EXPORT_PDF',
  CLEAR_PENDING_PDF_DOWNLOADS = 'CLEAR_PENDING_PDF_DOWNLOADS',
  // User Profile types
  FETCH_USER_PROFILE = 'FETCH_USER_PROFILE',
}
