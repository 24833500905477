import { actionTypes } from '../actions/actionTypes';
import { UserProfileAction, UserProfileState } from '../types/store/userProfileTypes';

const initialState: UserProfileState = null;

export const userProfileReducer = (state: UserProfileState = initialState, action: UserProfileAction) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return action.payload.userProfile;
    default:
      return state;
  }
};
