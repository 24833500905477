import { cloneDeep } from 'lodash';

import { PdfState, PdfAction } from '../types/store/pdfTypes';
import { actionTypes } from '../actions/actionTypes';

const initialState: PdfState = {
  exports: [],
  pendingDownloads: [],
};

export const pdfReducer = (state: PdfState = initialState, action: PdfAction) => {
  switch (action.type) {
    case actionTypes.EXPORT_PDF: {
      const pendingDownloads = [...state.pendingDownloads, action.payload.export];
      const existingIndex = state.exports.findIndex(r => r.calculationId === action.payload.export.calculationId);

      if (existingIndex === -1) {
        return {
          exports: [...state.exports, action.payload.export],
          pendingDownloads,
        };
      }

      const exports = cloneDeep(state.exports);
      exports.splice(existingIndex, 1, action.payload.export);

      return {
        exports,
        pendingDownloads,
      };
    }
    case actionTypes.CLEAR_PENDING_PDF_DOWNLOADS:
      return {
        ...state,
        pendingDownloads: [],
      };
    default:
      return state;
  }
};
