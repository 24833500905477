import { actionTypes } from '../../actions/actionTypes';

export enum AirGapsCorrectionLevel {
  Level0 = 'Level0',
  Level1 = 'Level1',
  Level2 = 'Level2',
}

export type ApiMasterData = {
  businessUnitId: number;
  layers: ApiMasterDataLayer[];
  bridgeMaterials: ApiMasterDataBridgeMaterial[];
  mechanicalFasteners: ApiMasterDataMechanicalFasteners[];
  airGapsCorrectionRecords: ApiAirGapsCorrectionLevels;
  soilTypes: ApiMasterDataSoilType[];
  applications: ApiMasterDataApplicationType[];
  buildingRegions: ApiMasterDataBuildingRegion[];
  buildingTypes: ApiMasterDataBuildingType[];
  riskLevels: ApiMasterDataRiskLevel[];
  rainscreenCladdingDefaults: ApiRainscreenCladdingDefaults;
  drainageFx: ApiMasterDataDrainageFx[];
  precipitationByPostCodeArea: ApiMasterDataPrecipitationByPostCodeArea[];
  buildingUsage: ApiMasterDataBuildingUsage[];
  projectRegion: ApiMasterDataProjectRegion[];
  brands: MasterDataBranding[];
  roofCharacteristicsOptions: ApiMasterDataRoofCharacteristics[];
  salesContactTeams: ApiMasterDataSalesContactTeam[];
  projectCountyStates: ApiMasterDataProjectCountyState[];
  projectStages: ApiMasterDataProjectStage[];
  productSectors: ApiMasterDataProductSector[];
  calculationTitles: ApiMasterDataCalculationTitle[];
};

export type MasterData = {
  businessUnitId: number;
  layers: MasterDataLayer[];
  floorLayers: MasterDataLayer[];
  bridgeMaterials: MasterDataBridgeMaterial[];
  mechanicalFasteners: MasterDataMechanicalFasteners[];
  airGapsCorrectionLevels: MasterDataAirGapsCorrectionLevels;
  applications: MasterDataApplicationType[];
  buildingRegions: MasterDataBuildingRegion[];
  buildingTypes: MasterDataBuildingType[];
  riskLevels: MasterDataRiskLevel[];
  soilTypes: MasterDataSoilType[];
  rainscreenCladdingDefaults: MasterRainscreenCladdingDefaults;
  drainageFx: MasterDataDrainageFx[];
  precipitationByPostCodeArea: MasterDataPrecipitationByPostCodeArea[];
  buildingUsage: MasterDataBuildingUsage[];
  projectRegion: MasterDataProjectRegion[];
  branding: MasterDataBranding[];
  roofCharacteristicsOptions: MasterDataRoofCharacteristics[];
  salesContactTeams: MasterDataSalesContactTeam[];
  projectCountyStates: MasterDataProjectCountyState[];
  projectStages: MasterDataProjectStage[];
  productSectors: MasterDataProductSector[];
  calculationTitles: MasterDataCalculationTitle[];
};

export const getEmptyMasterData = (): MasterData => ({
  businessUnitId: -1,
  layers: [],
  floorLayers: [],
  bridgeMaterials: [],
  applications: [],
  mechanicalFasteners: [],
  airGapsCorrectionLevels: {
    level0: {
      level: AirGapsCorrectionLevel.Level0,
      value: '',
    },
    level1: {
      level: AirGapsCorrectionLevel.Level1,
      value: '',
    },
    level2: {
      level: AirGapsCorrectionLevel.Level2,
      value: '',
    },
  },
  buildingRegions: [],
  buildingTypes: [],
  riskLevels: [],
  soilTypes: [],
  rainscreenCladdingDefaults: {
    bracketsPerMetreSquared: '',
    chiValue: '',
  },
  drainageFx: [],
  precipitationByPostCodeArea: [],
  buildingUsage: [],
  projectRegion: [],
  branding: [],
  roofCharacteristicsOptions: [],
  salesContactTeams: [],
  projectCountyStates: [],
  projectStages: [],
  productSectors: [],
  calculationTitles: [],
});

export type ApiMasterDataLayer = {
  id: number;
  isActive: boolean;
  name: string;
  shortCode: string;
  thicknessMillimetres: string;
  thermalConductivity: string;
  insideEmissivity: string;
  outsideEmissivity: string;
  layerMaterialType: string;
  isCustom: false;
  vapourResistance?: string | null;
  vapourResistivity?: string | null;
  isFloorInsulation: boolean;
  vr1: string | null;
  vr2: string | null;
  vr3: string | null;
  vr4: string | null;
  vrBreak1: string | null;
  vrBreak2: string | null;
  vrBreak3: string | null;
  bridgeMaterial: ApiMasterDataBridgeMaterial | null;
};

export type MasterDataLayer = {
  id: number;
  isActive: boolean;
  name: string;
  shortCode: string;
  defaultThickness: string;
  thermalConductivity: string;
  insideEmissivity: string;
  outsideEmissivity: string;
  layerMaterialType: string;
  isCustom: false;
  vapourResistance?: string | null;
  vapourResistivity?: string | null;
  isFloorInsulation: boolean;
  vr1: string | null;
  vr2: string | null;
  vr3: string | null;
  vr4: string | null;
  vrBreak1: string | null;
  vrBreak2: string | null;
  vrBreak3: string | null;
  bridgeMaterial: MasterDataBridgeMaterial | null;
};

export type ApiMasterDataBridgeMaterial = {
  id: number;
  name: string;
  bridgeDescription: string;
  bridgeWidthMillimetres: string;
  bridgeThermalConductivity: string;
  centresDistanceMillimetres: string;
  nonBridgeHeightMillimetres: string | null;
};

export type MasterDataBridgeMaterial = {
  id: number;
  name: string;
  bridgeDescription: string;
  bridgeThermalConductivity: string;
  defaultBridgeWidth: string;
  defaultCentresDistance: string;
  defaultNonBridgeHeight: string | null;
};

export type ApiMasterDataMechanicalFasteners = {
  id: number;
  name: string;
  alphaCoefficient: string;
  thermalConductivity: string;
  crossSectionalAreaMillimetresSquared: string;
  fastenersPerMetreSquared: string;
};

export type MasterDataMechanicalFasteners = {
  id: number;
  name: string;
  defaultCrossArea: string;
  defaultNumber: string;
};

export type ApiAirGapsCorrectionLevels = {
  level0: {
    level: AirGapsCorrectionLevel.Level0;
    value: string;
  };
  level1: {
    level: AirGapsCorrectionLevel.Level1;
    value: string;
  };
  level2: {
    level: AirGapsCorrectionLevel.Level2;
    value: string;
  };
};

export type MasterDataAirGapsCorrectionLevel = {
  level: AirGapsCorrectionLevel;
  value: string;
};

export type MasterDataAirGapsCorrectionLevels = {
  level0: MasterDataAirGapsCorrectionLevel;
  level1: MasterDataAirGapsCorrectionLevel;
  level2: MasterDataAirGapsCorrectionLevel;
};

export type ApiMasterDataApplicationType = {
  id: number;
  type: string;
  name: string;
};

export type MasterDataApplicationType = ApiMasterDataApplicationType;

export type ApiMasterDataSoilType = {
  id: number;
  description: string;
  lambda: string;
  vapourResistivity: string;
};

export type MasterDataSoilType = ApiMasterDataSoilType;

export type MasterDataBuildingRegion = {
  id: number;
  name: string;
};

export type ApiMasterDataBuildingRegion = {
  id: number;
  name: string;
};

export type MasterDataBuildingType = {
  id: number;
  name: string;
};

export type ApiMasterDataBuildingType = {
  id: number;
  name: string;
};

export type ApiMasterDataRiskLevel = {
  id: number;
  name: string;
};

export type ApiMasterDataBuildingUsage = MasterDataBuildingUsage;

export type ApiMasterDataProjectRegion = MasterDataProjectRegion;

export type MasterDataRiskLevel = {
  id: number;
  name: string;
};

export type MasterDataDrainageFx = {
  id: number;
  covering: string;
  fxFactor: string;
};

export type MasterDataPrecipitationByPostCodeArea = {
  id: number;
  postCodeArea: string;
  rainfallMillimetres: string;
};

export type MasterDataBuildingUsage = {
  id: number;
  name: string;
};

export type MasterDataProjectRegion = {
  id: number;
  name: string;
  country: string;
  isActive: boolean;
};

export type ApiMasterDataDrainageFx = {
  id: number;
  covering: string;
  fxFactor: string;
};

export type ApiMasterDataPrecipitationByPostCodeArea = {
  id: number;
  postCodeArea: string;
  rainfallMillimetres: string;
};

export type MasterDataBranding = {
  id: number;
  brandName: string;
  country: string;
  adGroups: number;
};

export type ApiRainscreenCladdingDefaults = {
  bracketsPerMetreSquared: string;
  chiValue: string;
};

export type MasterRainscreenCladdingDefaults = ApiRainscreenCladdingDefaults;

export type ApiMasterDataRoofCharacteristics = {
  id: number;
  description: string;
  roofSpaceThermalResistance: string;
};

export type MasterDataRoofCharacteristics = ApiMasterDataRoofCharacteristics;

export type MasterDataState = MasterData & {
  isLoadingMasterData?: boolean;
};

export type FetchMasterDataAction = {
  type: actionTypes.FETCH_MASTER_DATA;
  payload: {
    masterData: MasterData;
  };
};

export type SetIsFetchingMasterDataAction = {
  type: actionTypes.SET_IS_LOADING_MASTER_DATA;
  payload: {
    isLoadingMasterData: boolean;
  };
};

export type MasterDataAction = FetchMasterDataAction | SetIsFetchingMasterDataAction;

export type ApiMasterDataCalculationTitle = {
  id: number;
  businessUnitId: number;
  title: string;
}

export type MasterDataCalculationTitle = ApiMasterDataCalculationTitle;
export type ApiMasterDataProductSector = {
  id: number;
  businessUnitId: number;
  sector: string;
}

export type MasterDataProductSector = ApiMasterDataProductSector;
export type ApiMasterDataSalesContactTeam = {
  id: number;
  businessUnitId: number;
  areaCode: string;
}

export type MasterDataSalesContactTeam = ApiMasterDataSalesContactTeam;

export type ApiMasterDataProjectCountyState = {
  id: number,
  businessUnitId: number;
  county: string;
}

export type MasterDataProjectCountyState = ApiMasterDataProjectCountyState;

export type ApiMasterDataProjectStage = {
  id: number;
  businessUnitId: number;
  code: string;
}

export type MasterDataProjectStage = ApiMasterDataProjectStage;
