import { Button, Container, Fade, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import { changeActiveCalculation, closeActiveCalculation, copyCalculation } from '../../../actions/calculationActions';
import { checkIsPowerUser } from '../../../common/userProfile';
import { uuid } from '../../../common/uuid';
import {
  applicationTypeSelector,
  currentBuildingRegionNameSelector,
  currentCalculationBusinessUnitSelector,
  inactiveCalculationsSelector,
  interimOrCurrentCalculationSelector,
  isLoadingMasterDataSelector,
  saveSuccessSelector,
  userProfileSelector,
} from '../../../store/selectors';
import { Calculation } from '../../../types/store/calculationTypes';
import { InactiveCalculationsState } from '../../../types/store/inactiveCalculationsTypes';
import { MasterDataApplicationType } from '../../../types/store/masterDataTypes';
import { UserProfileState } from '../../../types/store/userProfileTypes';
import { CalculationNavigation } from '../../CalculationNavigation/CalculationNavigation';
import { CalculationResults } from '../../CalculationResults/CalculationResults';
import { CondensationRiskModal } from '../../CondensationRiskModal/CondensationRiskModal';
import { ConstructionModal } from '../../ConstructionModal/ConstructionModal';
import { bridgingTabIndex, LayerModal, LayerModalMode, vapourTabIndex } from '../../LayerModal/LayerModal';
import { LayersTable, LayersTableMode } from '../../LayersTable/LayersTable';
import { MasterDataUploadModal } from '../../MasterDataUploadModal/MasterDataUploadModal';
import { NewCalculationModal } from '../../NewCalculationModal/NewCalculationModal';
import { OpenCalculationModal } from '../../OpenCalculationModal/OpenCalculationModal';
import { WelcomeModal } from '../../WelcomeModal/WelcomeModal';
import { PdfModal } from '../../PdfModal/PdfModal';
import { ProjectInfoModal } from '../../ProjectInfoModal/ProjectInfoModal';
import { VerticalAlign } from '../../Utilities/VerticalAlign/VerticalAlign';
import styles from './CalculationScreen.module.scss';
import { ContactInfoModal } from '../../ContactInfoModal/ContactInfoModal';

const getCondensationMessage = (calculation: Calculation | null) => {
  if (!calculation) {
    return '';
  }
  if (!calculation.envSettings) {
    return 'Not Set';
  }
  if (calculation.hasCondensation) {
    return 'Condensation';
  }
  return 'No Condensation';
};

export function CalculationScreen() {
  const dispatch = useDispatch();

  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);
  const applicationTypes: MasterDataApplicationType[] = useSelector(applicationTypeSelector);
  const inactiveCalculations: InactiveCalculationsState = useSelector(inactiveCalculationsSelector);
  const currentBuildingRegionName: string | undefined = useSelector(currentBuildingRegionNameSelector);
  const saveSuccess: boolean | null = useSelector(saveSuccessSelector);
  const userProfile: UserProfileState = useSelector(userProfileSelector);
  const isLoadingMasterData = useSelector(isLoadingMasterDataSelector);
  const currentCalculationBusinessUnit = useSelector(currentCalculationBusinessUnitSelector);

  const [isLayerModalOpen, setIsLayerModalOpen] = React.useState(false);
  const [layerModalLayerInstanceId, setLayerModalLayerInstanceId] = React.useState<string | undefined>();
  const [layerModalMode, setLayerModalMode] = React.useState(LayerModalMode.ADD);
  const [isConstructionModalOpen, setIsConstructionModalOpen] = React.useState(false);
  const [isEnvConditionsModalOpen, setIsEnvConditionsModalOpen] = React.useState(false);
  const [layersTableMode, setLayersTableMode] = React.useState(LayersTableMode.THERMAL);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = React.useState(true);
  const [isNewCalculationModalOpen, setIsNewCalculationModalOpen] = React.useState(false);
  const [isOpenCalculationModalOpen, setIsOpenCalculationModalOpen] = React.useState(false);
  const [canDismissNewCalculationModal, setCanDismissNewCalculationModal] = React.useState(false);
  const [isPrintPdfModalOpen, setIsPrintPdfModalOpen] = React.useState(false);
  const [isMasterDataUploadModalOpen, setIsMasterDataUploadModalOpen] = React.useState(false);
  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = React.useState(false);
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = React.useState(false);
  const [layerModalInitialTabIndex, setLayerModalInitialTabIndex] = React.useState<false | number>(false);

  const { enqueueSnackbar } = useSnackbar();

  const applicationType = applicationTypes.find(applicationType => applicationType.id === calculation?.applicationDetails.id)?.name;
  const activeCalculationId = calculation?.calculationId;

  React.useEffect(() => {
    if (saveSuccess) {
      enqueueSnackbar('Saved', { variant: 'success', autoHideDuration: 1500, TransitionComponent: Fade });
    }
  }, [enqueueSnackbar, saveSuccess]);

  const handleOpenEnvConditionsModal = () => {
    setIsEnvConditionsModalOpen(true);
  };

  const handleOpenConstructionModal = () => {
    setIsConstructionModalOpen(true);
  };

  const handleCreateNewCalculationModal = () => {
    setCanDismissNewCalculationModal(true);
    setIsNewCalculationModalOpen(true);
  };

  const handleOpenCalculationModal = () => {
    setIsOpenCalculationModalOpen(true);
  };

  const handleOpenPrintPdfModal = () => {
    setIsPrintPdfModalOpen(true);
  };

  const handleOpenMasterDataUploadModal = () => {
    setIsMasterDataUploadModalOpen(true);
  };

  const handleClosePrintPdfModal = () => {
    setIsPrintPdfModalOpen(false);
  };

  const handleOpenProjectInfoModal = () => {
    setIsProjectInfoModalOpen(true);
  };

  const handleCloseProjectInfoModal = () => {
    setIsProjectInfoModalOpen(false);
  };

  const handleOpenContactInfoModal = () => {
    setIsContactInfoModalOpen(true);
  }

  const handleCloseContactInfoModal = () => {
    setIsContactInfoModalOpen(false);
  }

  const handleOpenLayerModal = (mode: LayerModalMode, instanceId?: string, tabIndex?: number) => {
    if (instanceId) {
      setLayerModalLayerInstanceId(instanceId);
    } else {
      /*
        When the mode is add and there isn't a selected layer,
        we set the default now so that the interim calculation results for the layer
        can be accessed.
      */
      setLayerModalLayerInstanceId(uuid());
    }

    setLayerModalMode(mode);

    if (mode === LayerModalMode.ADD) {
      setLayerModalInitialTabIndex(false);
    } else if (tabIndex !== undefined) {
      setLayerModalInitialTabIndex(tabIndex);
    } else if (layersTableMode === LayersTableMode.CONDENSATION) {
      setLayerModalInitialTabIndex(vapourTabIndex);
    } else {
      setLayerModalInitialTabIndex(bridgingTabIndex);
    }

    setIsLayerModalOpen(true);
  };

  const handleCloseEnvConditionsModal = () => {
    setIsEnvConditionsModalOpen(false);
  };

  const handleCloseConstructionModal = () => {
    setIsConstructionModalOpen(false);
  };

  const handleCloseLayerModal = () => {
    setLayerModalLayerInstanceId(undefined);
    setIsLayerModalOpen(false);
  };

  const handleWelcomeModalCreateNewCalculation = () => {
    setIsWelcomeModalOpen(false);
    handleCreateNewCalculationModal();
  };

  const handleWelcomeModalOpenCalculation = () => {
    setIsWelcomeModalOpen(false);
    handleOpenCalculationModal();
  };

  const handleWelcomeModalUpload = () => {
    setIsWelcomeModalOpen(false);
    handleOpenMasterDataUploadModal();
  };

  const handleCloseNewCalculationModal = (forceWelcome?: boolean) => {
    setIsNewCalculationModalOpen(false);

    if (forceWelcome && calculationNavigationItems.length === 0) {
      setIsWelcomeModalOpen(true);
    }
  };

  const handleCloseOpenCalculationModal = (forceWelcome?: boolean) => {
    setIsOpenCalculationModalOpen(false);

    if (forceWelcome && calculationNavigationItems.length === 0) {
      setIsWelcomeModalOpen(true);
    }
  };

  const handleCloseMasterDataUploadModal = (forceWelcome?: boolean) => {
    setIsMasterDataUploadModalOpen(false);

    if (forceWelcome && calculationNavigationItems.length === 0) {
      setIsWelcomeModalOpen(true);
    }
  };

  const handleLayerTableModeSwitch = () => {
    setLayersTableMode(prevLayersTableMode =>
      prevLayersTableMode === LayersTableMode.THERMAL ? LayersTableMode.CONDENSATION : LayersTableMode.THERMAL
    );
  };

  const handleNavigateCalculation = React.useCallback(
    (_: any, selectedItem: string) => {
      if (selectedItem === activeCalculationId) return;

      dispatch(changeActiveCalculation(selectedItem));
    },
    [dispatch, activeCalculationId]
  );

  const getCalculationNavigationItems = (): string[] => {
    const unorderedCalculations = [...(calculation ? [calculation] : []), ...inactiveCalculations];
    const orderedCalculations = unorderedCalculations.sort((a, b) => (a.order > b.order ? 1 : -1));

    return orderedCalculations.map(c => c?.calculationId);
  };

  const calculationNavigationItems = getCalculationNavigationItems();

  const handleCloseCalculation = React.useCallback(() => {
    dispatch(closeActiveCalculation());

    // If there'll be no calculation left open the welcome modal automatically.
    if (calculationNavigationItems.length === 1) {
      setIsWelcomeModalOpen(true);
    }
  }, [dispatch, calculationNavigationItems]);

  const handleCopyCalculation = React.useCallback(() => {
    dispatch(copyCalculation());
  }, [dispatch]);

  // Power user groups are configurable so dynamically determine it
  const isPowerUser = checkIsPowerUser(userProfile);

  return (
    <div>
      <Grid container>
        <Grid item xs={3} />

        <Grid item xs={9}>
          <CalculationResults
            uValue={calculation ? calculation.uValue : ''}
            uValue2DP={calculation ? calculation.uValue2DP : ''}
            totalThermalResistance={calculation ? calculation.totalThermalResistance : ''}
            isTotalCorrectionFactorBelowThreshold={calculation ? calculation.isTotalCorrectionFactorBelowThreshold : false}
            condensationResult={getCondensationMessage(calculation)}
            isLocked={calculation?.locked}
            businessUnit={currentCalculationBusinessUnit}
            isLoadingMasterData={isLoadingMasterData}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <div className={styles.buttonLinkLabel}>Construction Type</div>

          <button data-qa-id="applicationTypeButton" className={styles.buttonLink} onClick={handleOpenConstructionModal}>
            {calculation ? applicationType : '—'}
          </button>
        </Grid>

        <Grid item xs={2}>
          <div className={styles.buttonLinkLabel}>Env. Conditions</div>

          <button data-qa-id="envConditionsButton" className={styles.buttonLink} onClick={handleOpenEnvConditionsModal}>
            {calculation ? currentBuildingRegionName || 'Not set' : '—'}
          </button>
        </Grid>

        <Grid item xs={2}>
          <div className={styles.buttonLinkLabel} data-qa-id="openProjectInfoModalLabel">
            Project Info
          </div>

          <button data-qa-id="openProjectInfoModalButton" className={styles.buttonLink} onClick={handleOpenProjectInfoModal}>
            {calculation ? calculation.project?.name ?? 'Not set' : '—'}
          </button>
        </Grid>

        <Grid item xs={2}>
          <div className={styles.buttonLinkLabel} data-qa-id="openContactInfoModalLabel">
            Project Contact
          </div>

          <button data-qa-id="openContactInfoModalButton" className={styles.buttonLink} onClick={handleOpenContactInfoModal}>
            {calculation ? ((calculation?.contact?.firstName && calculation.contact.lastName) ? calculation.contact?.firstName + " " + calculation.contact?.lastName : 'Not set') : '—'}
          </button>
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            data-qa-id="condensationViewSwitchLabel"
            control={
              <Switch
                color="primary"
                checked={layersTableMode === LayersTableMode.CONDENSATION}
                data-qa-id="condensationViewSwitch"
                name="checkedB"
                onChange={handleLayerTableModeSwitch}
              />
            }
            label="Condensation"
            labelPlacement="end"
          />
        </Grid>

        <Grid item xs={2}>
          <VerticalAlign align="bottom">
            <Button
              onClick={() => handleOpenLayerModal(LayerModalMode.ADD)}
              data-qa-id="addLayerButton"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              startIcon={<AddCircle />}
              autoFocus
              disabled={calculation?.locked}
            >
              Add Layer
            </Button>
          </VerticalAlign>
        </Grid>
      </Grid>

      <Grid container>
        <DndProvider backend={Backend}>
          <LayersTable
            layersTableMode={layersTableMode}
            onLayerClick={(instanceId: string, tabIndex?: number) => handleOpenLayerModal(LayerModalMode.EDIT, instanceId, tabIndex)}
            isLocked={calculation?.locked}
          />
        </DndProvider>
      </Grid>

      <div className={styles.navigationBarContainer}>
        <div className={styles.navigationBar}>
          <Container fixed disableGutters>
            <Grid container spacing={3}>
              <Grid item xs={isPowerUser ? 8 : 9} data-qa-id="navigationBarGrid">
                <div className={calculation === null ? styles.navigationBarDisabled : ''}>
                  <CalculationNavigation
                    items={calculationNavigationItems}
                    currentItem={calculation?.calculationId}
                    handleChange={handleNavigateCalculation}
                    handleTabClose={handleCloseCalculation}
                    handleTabCopy={handleCopyCalculation}
                  />
                </div>
              </Grid>

              {isPowerUser && (
                <Grid item xs={1}>
                  <VerticalAlign align="center">
                    <Button
                      onClick={handleOpenMasterDataUploadModal}
                      data-qa-id="openMasterDataUploadModalButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      fullWidth
                    >
                      Upload
                    </Button>
                  </VerticalAlign>
                </Grid>
              )}

              <Grid item xs={1}>
                <VerticalAlign align="center">
                  <Button
                    onClick={handleOpenPrintPdfModal}
                    data-qa-id="exportPdfButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                  >
                    Export
                  </Button>
                </VerticalAlign>
              </Grid>

              <Grid item xs={1}>
                <VerticalAlign align="center">
                  <Button
                    onClick={handleCreateNewCalculationModal}
                    data-qa-id="newCalculationButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                  >
                    Create
                  </Button>
                </VerticalAlign>
              </Grid>

              <Grid item xs={1}>
                <VerticalAlign align="center">
                  <Button
                    onClick={handleOpenCalculationModal}
                    data-qa-id="openCalculationButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                  >
                    Open
                  </Button>
                </VerticalAlign>
              </Grid>

            </Grid>
          </Container>
        </div>
      </div>

      {isConstructionModalOpen && <ConstructionModal onClose={handleCloseConstructionModal} />}
      {isLayerModalOpen && (
        <LayerModal
          mode={layerModalMode}
          layerInstanceId={layerModalLayerInstanceId}
          setLayerInstanceId={setLayerModalLayerInstanceId}
          onClose={handleCloseLayerModal}
          initialTabIndex={layerModalInitialTabIndex}
        />
      )}
      {isEnvConditionsModalOpen && <CondensationRiskModal onClose={handleCloseEnvConditionsModal} />}
      {isWelcomeModalOpen && <WelcomeModal onCreateNewCalculationClick={handleWelcomeModalCreateNewCalculation} onOpenCalculationClick={handleWelcomeModalOpenCalculation} onMasterDataUploadClick={handleWelcomeModalUpload} />}
      {isNewCalculationModalOpen && <NewCalculationModal onClose={handleCloseNewCalculationModal} canClose={canDismissNewCalculationModal} />}
      {isOpenCalculationModalOpen && <OpenCalculationModal onClose={handleCloseOpenCalculationModal} />}
      {isPrintPdfModalOpen && <PdfModal onClose={handleClosePrintPdfModal} />}
      {isMasterDataUploadModalOpen && <MasterDataUploadModal onClose={handleCloseMasterDataUploadModal} />}
      {isProjectInfoModalOpen && <ProjectInfoModal onClose={handleCloseProjectInfoModal} />}
      {isContactInfoModalOpen && <ContactInfoModal onClose={handleCloseContactInfoModal} />}
    </div>
  );
}
