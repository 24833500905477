import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uuid } from '../../common/uuid';
import MaterialTable, { Column, Icons, MTableCell, MTableHeader } from 'material-table';
import { Paper } from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Cancel,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FileCopy,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Chat,
} from '@material-ui/icons';
import classnames from 'classnames';

import styles from './LayersTable.module.scss';
import { CalculationLayer, Calculation } from '../../types/store/calculationTypes';
import { interimOrCurrentCalculationSelector } from '../../store/selectors';
import { duplicateLayerInCalculation, removeLayerFromCalculation } from '../../actions/calculationActions';
import { LayersTableRow } from './LayersTableRow/LayersTableRow';
import iconBridged from '../../assets/icons/bridged.svg';
import iconFastened from '../../assets/icons/fastened.svg';
import iconBridgedAndFastened from '../../assets/icons/bridged-and-fastened.svg';

export enum LayersTableMode {
  THERMAL = 0,
  CONDENSATION = 1,
}

export type CalculationLayerDisplay = CalculationLayer & {
  dewpointTemperature: string;
  interfaceTemperature: string;
  peakCondensationAccumulationAmount: string;
  peakCondensationAccumulationAmountMonth: string;
  peakCondensationAmount: string;
  peakCondensationAmountMonth: string;
};

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Cancel {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const typesWithGroundAsOuterSurface = ['SolidGroundFloor', 'SuspendedFloor', 'BasementWall', 'BasementFloor'];
const typesWithSoilInterface = ['SolidGroundFloor', 'BasementWall', 'BasementFloor'];

const renderLabel = (text: string, unit: string) => (
  <div className={styles.tableHeaderText}>
    {text}

    <div className={styles.tableHeaderUnit}>({unit})</div>
  </div>
);

const renderValueWithMonth = (value: string, month: string) => {
  if (value === '') {
    return '';
  }
  if (value === '0.0' || value === '-') {
    return '-';
  }
  return `${value} in ${month}`;
};

const descriptionColumn = {
  title: 'Layer Description',
  field: 'name',
};

const thermalColumns: Column<CalculationLayerDisplay>[] = [
  descriptionColumn,
  {
    field: 'icon',
    render: ({ layerBridging, mechanicalFastener }) => {
      if (!layerBridging && !mechanicalFastener) {
        return null;
      }

      if (layerBridging && mechanicalFastener) {
        return (
          <img
            src={iconBridgedAndFastened}
            data-qa-id="iconBridgedAndFastened"
            title="Bridged and fastened"
            className={styles.iconBridgedAndFastened}
            alt=""
          />
        );
      }

      if (layerBridging) {
        return <img src={iconBridged} data-qa-id="iconBridged" title="Bridged" className={styles.iconBridged} alt="" />;
      }

      if (mechanicalFastener) {
        return <img src={iconFastened} data-qa-id="iconFastened" title="Fastened" className={styles.iconFastened} alt="" />;
      }
    },
  },
  {
    title: renderLabel('Thickness', 'mm'),
    field: 'thicknessMillimetres',
    type: 'numeric',
  },
  {
    title: renderLabel('Therm Cond', 'W/mK'),
    field: 'thermalConductivity',
    type: 'numeric',
  },
  {
    title: renderLabel('Therm Res', 'm²K/W'),
    field: 'thermalResistance',
    type: 'numeric',
  },
  {
    title: <div className={styles.thermalBridgingColumnCell}>Thermal Bridging</div>,
    render: ({ layerBridging }) => (
      <div className={styles.thermalBridgingColumnCell}>{layerBridging ? layerBridging.bridgeDescription : ''}</div>
    ),
  },
];

const condensationColumns: Column<CalculationLayerDisplay>[] = [
  descriptionColumn,
  {
    title: renderLabel('V. Resistivity', 'MNs/gm'),
    field: 'vapourResistivity',
    type: 'numeric',
  },
  {
    title: renderLabel('V. Resistance', 'MNs/g'),
    field: 'vapourResistance',
    type: 'numeric',
  },
  {
    title: renderLabel('Interface Temp', '°C'),
    field: 'interfaceTemperature',
    type: 'numeric',
    render: (rowdata: CalculationLayerDisplay) => <div>{rowdata.interfaceTemperature}</div>,
  },
  {
    title: renderLabel('Dewpoint Temp', '°C'),
    field: 'dewpointTemperature',
    type: 'numeric',
    render: (rowdata: CalculationLayerDisplay) => <div>{rowdata.dewpointTemperature}</div>,
  },
  {
    title: renderLabel('Worst Build-up (Gc)', 'g/m²'),
    render: (rowdata: CalculationLayerDisplay) => (
      <div>{renderValueWithMonth(rowdata.peakCondensationAmount, rowdata.peakCondensationAmountMonth)}</div>
    ),
    type: 'numeric',
  },
  {
    title: renderLabel('Peak Accumulation (Ma)', 'g/m²'),
    render: (rowdata: CalculationLayerDisplay) => (
      <div>{renderValueWithMonth(rowdata.peakCondensationAccumulationAmount, rowdata.peakCondensationAccumulationAmountMonth)}</div>
    ),
    type: 'numeric',
  },
];

type Props = {
  layersTableMode: LayersTableMode;
  onLayerClick: (instanceId: string, tabIndex?: number | undefined) => void;
  isLocked?: boolean;
};

export function LayersTable({ layersTableMode, onLayerClick, isLocked }: Props) {
  const dispatch = useDispatch();

  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);

  const layers: CalculationLayerDisplay[] = [
    {
      id: 0,
      instanceId: uuid(),
      name: 'Inside Surface',
      thicknessMillimetres: '-',
      thermalConductivity: '-',
      thermalResistance: calculation?.applicationDetails?.insideSurfaceThermalResistance ?? '',
      insideEmissivity: '-',
      outsideEmissivity: '-',
      vapourResistance: '-',
      vapourResistivity: '-',
      interfaceTemperature: '',
      dewpointTemperature: '',
      layerNotes: undefined,
      peakCondensationAccumulationAmount: '',
      peakCondensationAccumulationAmountMonth: '',
      peakCondensationAmount: '',
      peakCondensationAmountMonth: '',
    },
    ...(calculation && calculation.layers
      ? calculation.layers.map((layer: CalculationLayer) => {
          const associatedInterface = calculation.interstitialInterfaces?.find(i => i.innerLayerInstanceId === layer.instanceId);
          return {
            id: layer.id,
            instanceId: layer.instanceId,
            name: layer.name,
            thicknessMillimetres: layer.thicknessMillimetres,
            thermalConductivity: layer.thermalConductivity,
            insideEmissivity: layer.insideEmissivity,
            outsideEmissivity: layer.outsideEmissivity,
            thermalResistance: layer.thermalResistance,
            layerBridging: layer.layerBridging,
            mechanicalFastener: layer.mechanicalFastener,
            vapourResistance: layer.vapourResistance || '-',
            vapourResistivity: layer.vapourResistivity || '-',
            dewpointTemperature: associatedInterface?.worstMonthDewpointTemperature || '-',
            interfaceTemperature: associatedInterface?.worstMonthInterfaceTemperature || '-',
            layerNotes: layer.layerNotes,
            peakCondensationAccumulationAmount: associatedInterface?.peakCondensationAccumulationAmount || '-',
            peakCondensationAccumulationAmountMonth: associatedInterface?.peakCondensationAccumulationAmountMonth || '-',
            peakCondensationAmount: associatedInterface?.peakCondensationAmount || '-',
            peakCondensationAmountMonth: associatedInterface?.peakCondensationAmountMonth || '-',
            isReadOnly: layer.isReadOnly,
          };
        })
      : []),
    {
      id: 0,
      instanceId: uuid(),
      name: typesWithGroundAsOuterSurface.includes(calculation?.applicationDetails?.type ?? '') ? 'Ground' : 'Outside Surface',
      thicknessMillimetres: '-',
      thermalConductivity: '-',
      thermalResistance: calculation?.applicationDetails?.outsideSurfaceThermalResistance ?? '',
      insideEmissivity: '-',
      outsideEmissivity: '-',
      vapourResistance: '-',
      vapourResistivity: '-',
      interfaceTemperature: '',
      dewpointTemperature: '',
      peakCondensationAccumulationAmount: '',
      peakCondensationAccumulationAmountMonth: '',
      peakCondensationAmount: '',
      peakCondensationAmountMonth: '',
    },
  ];

  const hideLastInterface =
    LayersTableMode.CONDENSATION && layers.length > 2 && !typesWithSoilInterface.includes(calculation?.applicationDetails?.type ?? '');

  return (
    <MaterialTable
      icons={tableIcons}
      columns={layersTableMode === LayersTableMode.THERMAL ? thermalColumns : condensationColumns}
      data={layers}
      options={{
        actionsColumnIndex: layersTableMode === LayersTableMode.THERMAL ? 6 : 8,
        sorting: false,
        showTitle: false,
        search: false,
        paging: false,
        toolbar: false,
        grouping: false,
        selection: false,
        draggable: false, // Relates to draggable columns, not rows
      }}
      actions={[
        layer => ({
          icon: () => <div>{layer.layerNotes?.notes !== '' && layer.layerNotes?.notes !== undefined ? <Chat /> : null}</div>,
          onClick: (event, layer: CalculationLayer | CalculationLayer[]) => {
            if (Array.isArray(layer)) return;

            onLayerClick(layer.instanceId, 4);
          },
        }),
        layer => ({
          icon: () => (
            <div data-qa-id="duplicateLayerButton" title="Duplicate layer">
              <FileCopy />
            </div>
          ),
          hidden: isLocked || layer.isReadOnly,
          onClick: (event, layer: CalculationLayer | CalculationLayer[]) => {
            // Unlike selection, we're never expecting duplication of multiple records at once
            if (Array.isArray(layer)) return;

            if (layer.isReadOnly) return;

            dispatch(duplicateLayerInCalculation(layer.instanceId));
          },
        }),
        layer => ({
          icon: () => (
            <div data-qa-id="deleteLayerButton" title="Delete layer">
              <Cancel />
            </div>
          ),
          hidden: isLocked || layer.isReadOnly,
          onClick: (event, layer: CalculationLayer | CalculationLayer[]) => {
            // Unlike selection, we're never expecting deletion of multiple records at once
            if (Array.isArray(layer)) return;

            if (layer.isReadOnly) return;

            dispatch(removeLayerFromCalculation(layer.instanceId));
          },
        }),
      ]}
      components={{
        Container: renderProps => (
          <div
            className={classnames(styles.tableContainer, {
              [styles.hideLastInterface]: hideLastInterface,
            })}
          >
            <Paper {...renderProps} className={styles.table} variant="outlined" />
          </div>
        ),
        Header: renderProps => (
          <div
            className={classnames(styles.tableHeaderContainer, {
              [styles.condensation]: layersTableMode === LayersTableMode.CONDENSATION,
              [styles.thermal]: layersTableMode === LayersTableMode.THERMAL,
            })}
          >
            <MTableHeader {...renderProps} />
          </div>
        ),
        Row: renderProps => (
          <LayersTableRow
            renderProps={renderProps}
            onLayerClick={onLayerClick}
            disableDrag={isLocked || layersTableMode === LayersTableMode.CONDENSATION}
          />
        ),
        Cell: renderProps => (
          <tbody
            className={classnames(styles.tableCellContainer, {
              [styles.condensation]: layersTableMode === LayersTableMode.CONDENSATION,
              [styles.thermal]: layersTableMode === LayersTableMode.THERMAL,
            })}
          >
            <MTableCell {...renderProps} />
          </tbody>
        ),
      }}
    />
  );
}
