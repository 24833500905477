import React, { ChangeEvent } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import { TabIndexInfo } from '../../types/components/tabTypes';
import styles from '../LayerModal/LayerModal.module.scss';
import { TabPanel } from '../LayerModal/TabPanel';

export type CoreLayerNotesTabProps = {
  notes: string | null;
  setLayerNotes: (notes: string) => void;
  isLocked?: boolean;
};

export type LayerNotesTabProps = CoreLayerNotesTabProps & TabIndexInfo;

export function LayerNotesTab(props: LayerNotesTabProps) {
  const { notes, setLayerNotes, targetIndex, currentTabIndex, isLocked } = props;

  return (
    <TabPanel
      id="layer-notes-tab-panel"
      data-qa-id="layer-notes-tab-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="layer-notes-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel htmlFor="layer-notes" data-qa-id="layerNotesLabel">
              Supporting information about this layer
            </InputLabel>

            <Grid item xs={10}>
              <TextField
                id="layer-notes"
                multiline
                fullWidth
                rows={4}
                data-qa-id="layerNotesInput"
                variant="outlined"
                value={notes || ''}
                disabled={isLocked}
                onChange={(event: ChangeEvent<any>) => setLayerNotes(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
