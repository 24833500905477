import React, { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core';

import { LayerDetailsTabs } from './LayerDetailsTabs';
import { CoreLayerDetails } from './CoreLayerDetails';
import {
  MasterDataLayer,
  MasterDataBridgeMaterial,
  MasterDataMechanicalFasteners,
  AirGapsCorrectionLevel,
  MasterData,
} from '../../types/store/masterDataTypes';
import { addLayerToCalculation, editLayerInCalculation, clearInterimCalculation } from '../../actions/calculationActions';
import {
  CalculationLayer,
  ApiLayerBridgingRequest,
  Calculation,
  ApiLayerMechanicalFastenerRequest,
  CustomReferenceLayer,
  ApiCustomReferenceLayerRequest,
  customLayerDefaults,
  ApiLayerNotesRequest,
  ApiLayerBridgingResponse,
} from '../../types/store/calculationTypes';
import styles from './LayerModal.module.scss';
import { interimOrCurrentCalculationSelector, masterDataSelector } from '../../store/selectors';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
import {
  isStringNotNullishOrWhitespace,
  isStringValidNumberInRangeInclusive,
  isStringValidPostiveNumber,
} from '../../common/inputValidation';
import { uuid } from '../../common/uuid';

export enum LayerModalMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export type Props = {
  mode: LayerModalMode;
  layerInstanceId: string | undefined;
  setLayerInstanceId: (value: string | undefined) => void;
  onClose: () => void;
  initialTabIndex: false | number;
};

export type DefaultModalState = {
  masterLayer: MasterDataLayer | null;
  layerThickness: string | null;
  shortCode: string | null;
  hasShortCodeError: boolean;
  customReferenceLayer: CustomReferenceLayer | null;
  bridgeMaterial: MasterDataBridgeMaterial | null;
  bridgeWidth: string;
  bridgeCentres: string;
  bridgeNonBridgedHeight: string | null;
  fasteners: MasterDataMechanicalFasteners | null;
  fastenersCrossArea: string;
  fastenersNumber: string;
  airGapsCorrectionLevelOverride: AirGapsCorrectionLevel | undefined;
  notes: string | null;
  tabIndex: false | number;
};

export const defaultState: DefaultModalState = {
  masterLayer: null,
  layerThickness: null,
  shortCode: null,
  hasShortCodeError: false,
  customReferenceLayer: null,
  bridgeMaterial: null,
  bridgeWidth: '',
  bridgeCentres: '',
  bridgeNonBridgedHeight: null,
  fasteners: null,
  fastenersCrossArea: '',
  fastenersNumber: '',
  airGapsCorrectionLevelOverride: undefined,
  notes: null,
  tabIndex: false,
};

type InitializerArgs = {
  defaults: DefaultModalState;
  masterData: MasterData;
  mode: LayerModalMode;
  interimLayer: CalculationLayer | null;
  initialTabIndex: false | number;
};

type ModalState = {
  masterLayer: MasterDataLayer | null;
  layerThickness: string | null;
  shortCode: string | null;
  hasShortCodeError: boolean;
  customReferenceLayer: CustomReferenceLayer | null;
  bridgeMaterial: MasterDataBridgeMaterial | null;
  bridgeWidth: string;
  bridgeCentres: string;
  bridgeNonBridgedHeight: string | null;
  fasteners: MasterDataMechanicalFasteners | null;
  fastenersCrossArea: string;
  fastenersNumber: string;
  airGapsCorrectionLevelOverride: AirGapsCorrectionLevel | undefined;
  notes: string | null;
  tabIndex: false | number;
  vapourResistance: string | null;
  vapourResistivity: string | null;
};

type VariableVapourResistance = {
  vapourResistance: number;
  vapourResistanceBreak: number;
};

const getInitMasterDataLayer = ({ defaults, masterData, mode, interimLayer }: InitializerArgs): MasterDataLayer | null => {
  if (mode === LayerModalMode.ADD || !masterData.layers) {
    return defaults.masterLayer;
  }

  const masterDataLayer = masterData.layers.find(masterDataLayer => masterDataLayer.id === (interimLayer ? interimLayer.id : ''));

  return masterDataLayer || defaults.masterLayer;
};

const getInitLayerThickness = ({ mode, interimLayer, defaults }: InitializerArgs): string | null =>
  mode === LayerModalMode.EDIT ? (interimLayer ? interimLayer.thicknessMillimetres : defaults.layerThickness) : defaults.layerThickness;

const getInitShortCode = ({ interimLayer }: InitializerArgs): string | null => '';

const getInitHasShortCodeError = ({ defaults }: InitializerArgs): boolean => defaults.hasShortCodeError;

const getInitCustomReferenceLayer = ({ defaults, mode, interimLayer }: InitializerArgs): CustomReferenceLayer | null => {
  if (mode === LayerModalMode.ADD || !interimLayer?.customReferenceLayer) {
    return defaults.customReferenceLayer;
  }

  return interimLayer?.customReferenceLayer || defaults.customReferenceLayer;
};

const getInitMasterDataBridgeMaterial = ({
  defaults,
  masterData,
  mode,
  interimLayer,
}: InitializerArgs): MasterDataBridgeMaterial | null => {
  if (mode === LayerModalMode.ADD || !masterData.bridgeMaterials || !interimLayer?.layerBridging) {
    return defaults.bridgeMaterial;
  }

  let material: MasterDataBridgeMaterial | undefined;

  if (interimLayer?.layerBridging?.id === -1) {
    material = {
      id: interimLayer.layerBridging.id,
      name: interimLayer.layerBridging.name,
      bridgeDescription: interimLayer.layerBridging.bridgeDescription,
      bridgeThermalConductivity: interimLayer.layerBridging.bridgeThermalConductivity,
      defaultBridgeWidth: interimLayer.layerBridging.bridgeWidthMillimetres,
      defaultCentresDistance: interimLayer.layerBridging.centresDistanceMillimetres,
      defaultNonBridgeHeight: interimLayer.layerBridging.nonBridgeHeightMillimetres,
    };
  } else {
    material = masterData.bridgeMaterials.find(masterMaterial => masterMaterial.id === (interimLayer?.layerBridging?.id ?? ''));
  }

  return material || defaults.bridgeMaterial;
};

const getInitBridgeWidth = ({ defaults, mode, interimLayer }: InitializerArgs): string =>
  mode === LayerModalMode.EDIT ? interimLayer?.layerBridging?.bridgeWidthMillimetres ?? defaults.bridgeWidth : defaults.bridgeWidth;

const getInitBridgeCentres = ({ defaults, mode, interimLayer }: InitializerArgs): string =>
  mode === LayerModalMode.EDIT ? interimLayer?.layerBridging?.centresDistanceMillimetres ?? defaults.bridgeCentres : defaults.bridgeCentres;

const getInitBridgeNonBridgedHeight = ({ defaults, mode, interimLayer }: InitializerArgs): string | null =>
  mode === LayerModalMode.EDIT
    ? interimLayer?.layerBridging?.nonBridgeHeightMillimetres ?? defaults.bridgeNonBridgedHeight
    : defaults.bridgeNonBridgedHeight;

const getInitMasterDataMechanicalFastener = ({
  defaults,
  masterData,
  mode,
  interimLayer,
}: InitializerArgs): MasterDataMechanicalFasteners | null => {
  if (mode === LayerModalMode.ADD || !masterData.mechanicalFasteners || !interimLayer?.mechanicalFastener) {
    return defaults.fasteners;
  }

  const fasteners = masterData.mechanicalFasteners.find(fastener => fastener.id === (interimLayer?.mechanicalFastener?.id ?? ''));

  return fasteners || defaults.fasteners;
};

const getInitFastenersCrossArea = ({ defaults, mode, interimLayer }: InitializerArgs): string =>
  mode === LayerModalMode.EDIT
    ? interimLayer?.mechanicalFastener?.crossSectionalAreaMillimetresSquared ?? defaults.fastenersCrossArea
    : defaults.fastenersCrossArea;

const getInitFastenersNumber = ({ defaults, mode, interimLayer }: InitializerArgs): string => {
  return mode === LayerModalMode.EDIT
    ? interimLayer?.mechanicalFastener?.fasteningsPerMetreSquared ?? defaults.fastenersNumber
    : defaults.fastenersNumber;
};

const getInitAirGapsCorrectionLevelOverride = ({ defaults, interimLayer }: InitializerArgs): AirGapsCorrectionLevel | undefined =>
  interimLayer?.airGapsCorrection?.overrideCorrectionFactor ?? defaults.airGapsCorrectionLevelOverride;

const getInitTabIndex = ({ initialTabIndex }: InitializerArgs): false | number => initialTabIndex;

const getInitVapourResistance = ({ interimLayer }: InitializerArgs): string | null => interimLayer?.vapourResistance || null;

const getInitVapourResistivity = ({ interimLayer }: InitializerArgs): string | null => interimLayer?.vapourResistivity || null;

const getInitNotes = ({ defaults, mode, interimLayer }: InitializerArgs): string | null =>
  mode === LayerModalMode.EDIT ? interimLayer?.layerNotes?.notes ?? defaults.notes : defaults.notes;

export function stateInitializer(args: InitializerArgs): ModalState {
  return {
    masterLayer: getInitMasterDataLayer(args),
    layerThickness: getInitLayerThickness(args),
    shortCode: getInitShortCode(args),
    hasShortCodeError: getInitHasShortCodeError(args),
    notes: getInitNotes(args),
    customReferenceLayer: getInitCustomReferenceLayer(args),
    bridgeMaterial: getInitMasterDataBridgeMaterial(args),
    bridgeWidth: getInitBridgeWidth(args),
    bridgeCentres: getInitBridgeCentres(args),
    bridgeNonBridgedHeight: getInitBridgeNonBridgedHeight(args),
    fasteners: getInitMasterDataMechanicalFastener(args),
    fastenersCrossArea: getInitFastenersCrossArea(args),
    fastenersNumber: getInitFastenersNumber(args),
    airGapsCorrectionLevelOverride: getInitAirGapsCorrectionLevelOverride(args),
    tabIndex: getInitTabIndex(args),
    vapourResistance: getInitVapourResistance(args),
    vapourResistivity: getInitVapourResistivity(args),
  };
}

const getVapourResistance = (masterLayer: MasterDataLayer | null, layerThickness: string) => {
  if (!masterLayer) {
    return null;
  }

  if (masterLayer.vapourResistance) {
    return masterLayer.vapourResistance;
  }

  const variableResistances: VariableVapourResistance[] = [
    { vapourResistance: Number(masterLayer.vr4), vapourResistanceBreak: Number(masterLayer.vrBreak3) },
    { vapourResistance: Number(masterLayer.vr3), vapourResistanceBreak: Number(masterLayer.vrBreak2) },
    { vapourResistance: Number(masterLayer.vr2), vapourResistanceBreak: Number(masterLayer.vrBreak1) },
    { vapourResistance: Number(masterLayer.vr1), vapourResistanceBreak: 0 },
  ];

  return variableResistances.find(vvr => Number(layerThickness) > vvr.vapourResistanceBreak)?.vapourResistance.toString() || null;
};

const getSetMasterLayer = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (defaults: DefaultModalState) => (
  masterLayer: MasterDataLayer,
  apiBridgeResponse: ApiLayerBridgingResponse | undefined
): void => {
  const bridgeMaterial = masterLayer.bridgeMaterial;

  if (bridgeMaterial) {
    setLocalState(prevState => ({
      ...prevState,
      bridgeMaterial,
      bridgeWidth: bridgeMaterial.defaultBridgeWidth,
      bridgeCentres: bridgeMaterial.defaultCentresDistance,
      bridgeNonBridgedHeight: bridgeMaterial.defaultNonBridgeHeight,
      tabIndex: bridgingTabIndex,
    }));
  } else if (apiBridgeResponse?.id === -1) {
    getResetBridgingDetails(setLocalState)(defaults);
  }

  setLocalState(prevState => ({
    ...prevState,
    masterLayer,
    layerThickness: masterLayer.defaultThickness,
    customReferenceLayer: masterLayer != null ? defaults.customReferenceLayer : prevState.customReferenceLayer,
    airGapsCorrectionLevelOverride: !(masterLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation)
      ? defaults.airGapsCorrectionLevelOverride
      : prevState.airGapsCorrectionLevelOverride,
    vapourResistance: getVapourResistance(masterLayer, masterLayer.defaultThickness),
    vapourResistivity: masterLayer?.vapourResistivity || null,
  }));
};

const getSetLayerThickness = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (layerThickness: string): void => {
  setLocalState((prevState: ModalState) => ({
    ...prevState,
    layerThickness,
    vapourResistance: getVapourResistance(prevState.masterLayer, layerThickness),
  }));
};

const getSetShortCode = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (shortCode: string): void => {
  setLocalState(prevState => ({
    ...prevState,
    shortCode,
  }));
};

const getSetHasShortCodeError = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (hasShortCodeError: boolean): void => {
  setLocalState(prevState => ({
    ...prevState,
    hasShortCodeError,
  }));
};

const getSetCustomReferenceLayer = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (defaults: DefaultModalState) => (
  customReferenceLayer: CustomReferenceLayer
): void => {
  setLocalState(prevState => ({
    ...prevState,
    masterLayer: customReferenceLayer != null ? defaults.masterLayer : prevState.masterLayer,
    // Only clear the layer thickness if we are chaging customReferenceLayer from null to a value
    layerThickness: prevState.customReferenceLayer != null ? prevState.layerThickness : defaults.layerThickness,
    airGapsCorrectionLevelOverride:
      customReferenceLayer == null || !(customReferenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation)
        ? defaults.airGapsCorrectionLevelOverride
        : prevState.airGapsCorrectionLevelOverride,
    customReferenceLayer,
  }));
};

const getUpdateCustomReferenceLayer = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  defaults: DefaultModalState
) => (customReferenceLayer: Partial<CustomReferenceLayer>): void => {
  setLocalState(prevState => ({
    ...prevState,
    masterLayer: defaults.masterLayer,
    airGapsCorrectionLevelOverride:
      customReferenceLayer == null || !(customReferenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation)
        ? defaults.airGapsCorrectionLevelOverride
        : prevState.airGapsCorrectionLevelOverride,
    customReferenceLayer:
      prevState.customReferenceLayer == null
        ? { ...customLayerDefaults, ...customReferenceLayer }
        : { ...prevState.customReferenceLayer, ...customReferenceLayer },
  }));
};

const getSetBridgeMaterial = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  bridgeMaterial: MasterDataBridgeMaterial
): void => {
  setLocalState(prevState => ({
    ...prevState,
    bridgeMaterial,
    bridgeWidth: bridgeMaterial.defaultBridgeWidth,
    bridgeCentres: bridgeMaterial.defaultCentresDistance,
    bridgeNonBridgedHeight: bridgeMaterial.defaultNonBridgeHeight,
  }));
};

const getSetBridgeWidth = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (bridgeWidth: string): void => {
  setLocalState(prevState => ({
    ...prevState,
    bridgeWidth,
  }));
};

const getSetBridgeCentres = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (bridgeCentres: string): void => {
  setLocalState(prevState => ({
    ...prevState,
    bridgeCentres,
  }));
};

const getSetBridgeNonBridgedHeight = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  bridgeNonBridgedHeight: string | null
): void => {
  setLocalState(prevState => ({
    ...prevState,
    bridgeNonBridgedHeight,
  }));
};

const getSetFasteners = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  fasteners: MasterDataMechanicalFasteners
): void => {
  setLocalState(prevState => ({
    ...prevState,
    fasteners,
    fastenersCrossArea: fasteners.defaultCrossArea,
    fastenersNumber: fasteners.defaultNumber,
  }));
};

const getSetFastenersCrossArea = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  fastenersCrossArea: string
): void => {
  setLocalState(prevState => ({
    ...prevState,
    fastenersCrossArea,
  }));
};

const getSetFastenersNumber = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (fastenersNumber: string): void => {
  setLocalState(prevState => ({
    ...prevState,
    fastenersNumber,
  }));
};

const getSetAirGapsCorrectionLevelOverride = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  airGapsCorrectionLevelOverride: AirGapsCorrectionLevel | undefined
): void => {
  setLocalState(prevState => ({
    ...prevState,
    airGapsCorrectionLevelOverride,
  }));
};

const getSetNotes = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (notes: string): void => {
  setLocalState(prevState => ({
    ...prevState,
    notes,
  }));
};

const getResetCoreDetails = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (defaults: DefaultModalState): void => {
  setLocalState(prevState => ({
    ...prevState,
    masterLayer: defaults.masterLayer,
    layerThickness: defaults.layerThickness,
    shortCode: defaults.shortCode,
    airGapsCorrectionLevelOverride: defaults.airGapsCorrectionLevelOverride,
    customReferenceLayer: defaults.customReferenceLayer,
    notes: defaults.notes,
  }));
};

const getResetBridgingDetails = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  defaults: DefaultModalState
): void => {
  setLocalState(prevState => ({
    ...prevState,
    bridgeMaterial: defaults.bridgeMaterial,
    bridgeWidth: defaults.bridgeWidth,
    bridgeCentres: defaults.bridgeCentres,
    bridgeNonBridgedHeight: defaults.bridgeNonBridgedHeight,
  }));
};

const getResetFastenerDetails = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  defaults: DefaultModalState
): void => {
  setLocalState(prevState => ({
    ...prevState,
    fasteners: defaults.fasteners,
    fastenersCrossArea: defaults.fastenersCrossArea,
    fastenersNumber: defaults.fastenersNumber,
  }));
};

const getResetAllDetails = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (defaults: DefaultModalState): void => {
  getResetCoreDetails(setLocalState)(defaults);
  getResetBridgingDetails(setLocalState)(defaults);
  getResetFastenerDetails(setLocalState)(defaults);
};

const getSetTabIndex = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (tabIndex: false | number): void => {
  setLocalState(prevState => ({
    ...prevState,
    tabIndex,
  }));
};

const getSetVapourResistances = (setLocalState: React.Dispatch<React.SetStateAction<ModalState>>) => (
  vapourResistance: string | null,
  vapourResistivity: string | null
): void => {
  setLocalState(prevState => ({
    ...prevState,
    vapourResistance,
    vapourResistivity,
  }));
};

export function getSetters(setLocalState: React.Dispatch<React.SetStateAction<ModalState>>, defaults: DefaultModalState) {
  return {
    setMasterLayer: getSetMasterLayer(setLocalState)(defaults),
    setLayerThickness: getSetLayerThickness(setLocalState),
    setShortCode: getSetShortCode(setLocalState),
    setHasShortCodeError: getSetHasShortCodeError(setLocalState),
    setLayerNotes: getSetNotes(setLocalState),
    setCustomReferenceLayer: getSetCustomReferenceLayer(setLocalState)(defaults),
    updateCustomReferenceLayer: getUpdateCustomReferenceLayer(setLocalState)(defaults),
    setBridgeMaterial: getSetBridgeMaterial(setLocalState),
    setBridgeWidth: getSetBridgeWidth(setLocalState),
    setBridgeCentres: getSetBridgeCentres(setLocalState),
    setBridgeNonBridgedHeight: getSetBridgeNonBridgedHeight(setLocalState),
    setFasteners: getSetFasteners(setLocalState),
    setFastenersCrossArea: getSetFastenersCrossArea(setLocalState),
    setFastenersNumber: getSetFastenersNumber(setLocalState),
    setAirGapsCorrectionLevelOverride: getSetAirGapsCorrectionLevelOverride(setLocalState),
    resetCoreDetails: getResetCoreDetails(setLocalState),
    resetBridgingDetails: getResetBridgingDetails(setLocalState),
    resetFastenerDetails: getResetFastenerDetails(setLocalState),
    resetAllDetails: getResetAllDetails(setLocalState),
    setTabIndex: getSetTabIndex(setLocalState),
    setVapourResistances: getSetVapourResistances(setLocalState),
  };
}

// The logic in this function must reflect the buisiness logic in the `Kics.Technical.Calculation` project, `LayerCalculator.cs::CalculateBridgingPercentage`
function isBridgingPercentageInvalid(bridgeWidth: string, bridgeCentres: string, nonBridgeHeight: string | null): boolean {
  const decimalPlaces = 1;
  const precision = decimalPlaces * 10;
  const width: number = Math.round(+bridgeWidth * precision) / precision;
  const centres: number = Math.round(+bridgeCentres * precision) / precision;

  if (nonBridgeHeight == null || nonBridgeHeight === '') {
    if (width < centres) {
      return false;
    }
  } else {
    // 2 Dimensional
    const height: number = Math.round(+nonBridgeHeight * precision) / precision;

    const percentage = 1 - ((centres - width) * height) / (centres * (height + width));
    if (percentage > 0 && percentage < 1) {
      return false;
    }
  }

  return true;
}

function isModalStateInvalid(state: ModalState) {
  const {
    masterLayer,
    layerThickness,
    customReferenceLayer,
    bridgeMaterial,
    bridgeWidth,
    bridgeCentres,
    bridgeNonBridgedHeight,
    fasteners,
    fastenersCrossArea,
    fastenersNumber,
  } = state;

  const isThereAValidReferenceLayerSelected = masterLayer != null || customReferenceLayer != null;
  const isLayerThicknessValid = customReferenceLayer?.isBlank || isStringValidPostiveNumber(layerThickness);
  const isACustomReferenceLayerSelectedAndTheCustomValuesAreValid =
    !customReferenceLayer ||
    (isStringNotNullishOrWhitespace(customReferenceLayer.name) &&
      (customReferenceLayer?.isBlank ||
        (isStringValidPostiveNumber(customReferenceLayer.thermalConductivity) &&
          !isStringNotNullishOrWhitespace(customReferenceLayer.thermalResistance)) ||
        (isStringValidPostiveNumber(customReferenceLayer.thermalResistance) &&
          !isStringNotNullishOrWhitespace(customReferenceLayer.thermalConductivity))) &&
      (customReferenceLayer?.isBlank || isStringValidNumberInRangeInclusive(0, 1, customReferenceLayer.insideEmissivity)) &&
      (customReferenceLayer?.isBlank || isStringValidNumberInRangeInclusive(0, 1, customReferenceLayer.outsideEmissivity)));
  const areAllBridgingFieldsEmpty = [bridgeMaterial, bridgeWidth, bridgeCentres, bridgeNonBridgedHeight].every(property => !property);
  const doesBridgingHaveEmptyRequiredField = [bridgeMaterial, bridgeWidth, bridgeCentres].some(property => !property);
  const areAllBridgingFieldsValid = [bridgeWidth, bridgeCentres, bridgeNonBridgedHeight].every(
    property => !property || Number(property) > 0
  );

  const areAllFastenerFieldsEmpty = [fasteners, fastenersCrossArea, fastenersNumber].every(property => !property);
  const doesFastenerHaveEmptyRequiredField = [fasteners, fastenersCrossArea, fastenersNumber].some(property => !property);
  const areAllFastenerFieldsValid = [fastenersCrossArea, fastenersNumber].every(property => !property || Number(property) > 0);

  return (
    !isThereAValidReferenceLayerSelected ||
    !isLayerThicknessValid ||
    !isACustomReferenceLayerSelectedAndTheCustomValuesAreValid ||
    (!areAllFastenerFieldsEmpty && (doesFastenerHaveEmptyRequiredField || !areAllFastenerFieldsValid)) ||
    (!areAllBridgingFieldsEmpty &&
      (doesBridgingHaveEmptyRequiredField ||
        !areAllBridgingFieldsValid ||
        isBridgingPercentageInvalid(bridgeWidth, bridgeCentres, bridgeNonBridgedHeight)))
  );
}

export const closedTabIndex = false;
export const bridgingTabIndex = 0;
export const fastenersTabIndex = 1;
export const airGapsTabIndex = 2;
export const vapourTabIndex = 3;
export const layerNotesTabIndex = 4;

function LayerModalWithDefaults(defaults: DefaultModalState, props: Props) {
  const { layerInstanceId, mode, onClose, setLayerInstanceId, initialTabIndex } = props;
  const dispatch = useDispatch();

  const masterData: MasterData = useSelector(masterDataSelector);
  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);

  const refShortCodeField = React.createRef<HTMLElement>();
  const refThicknessField = React.createRef<HTMLElement>();

  const interimLayer = calculation
    ? calculation.layers.find((layer: CalculationLayer) => layer.instanceId === layerInstanceId) || null
    : null;

  const initializerArgs = { defaults, masterData, interimLayer, mode, initialTabIndex };

  function initState() {
    return stateInitializer(initializerArgs);
  }

  const [localState, setLocalState] = React.useState<ModalState>(initState);

  const {
    masterLayer,
    notes,
    layerThickness,
    shortCode,
    hasShortCodeError,
    customReferenceLayer,
    bridgeMaterial,
    bridgeWidth,
    bridgeCentres,
    bridgeNonBridgedHeight,
    fasteners,
    fastenersCrossArea,
    fastenersNumber,
    airGapsCorrectionLevelOverride,
    tabIndex,
    vapourResistance,
    vapourResistivity,
  } = localState;

  const {
    setMasterLayer,
    setLayerThickness,
    setShortCode,
    setHasShortCodeError,
    setLayerNotes,
    setCustomReferenceLayer,
    updateCustomReferenceLayer,
    setBridgeMaterial,
    setBridgeWidth,
    setBridgeCentres,
    setBridgeNonBridgedHeight,
    setFasteners,
    setFastenersCrossArea,
    setFastenersNumber,
    setAirGapsCorrectionLevelOverride,
    resetBridgingDetails,
    resetFastenerDetails,
    resetAllDetails,
    setTabIndex,
    setVapourResistances,
  } = getSetters(setLocalState, defaults);

  const clearBridgingDetails = () => resetBridgingDetails(defaults);
  const clearFastenersDetails = () => resetFastenerDetails(defaults);
  const clearAllDetails = React.useCallback(() => {
    resetAllDetails(defaults);
  }, [resetAllDetails, defaults]);

  const focusOnShortCodeField = React.useCallback(() => {
    if (refShortCodeField.current) {
      refShortCodeField?.current?.getElementsByTagName('input')[0].focus();
    }
  }, [refShortCodeField]);

  const selectAllTextInShortCodeField = React.useCallback(() => {
    if (refShortCodeField.current) {
      refShortCodeField?.current?.getElementsByTagName('input')[0].select();
    }
  }, [refShortCodeField]);

  const focusOnThicknessField = React.useCallback(() => {
    if (refThicknessField.current) {
      refThicknessField.current.getElementsByTagName('input')[0].select();
    }
  }, [refThicknessField]);

  const calculatedCorrectionFactor = interimLayer?.airGapsCorrection?.calculatedCorrectionFactor;

  React.useEffect(() => {
    if (isModalStateInvalid(localState)) {
      return;
    }

    const customReferenceLayerRequest: ApiCustomReferenceLayerRequest | undefined =
      customReferenceLayer != null
        ? {
          ...customReferenceLayer,
        }
        : undefined;

    const layerBridging: ApiLayerBridgingRequest | undefined =
      bridgeMaterial != null
        ? {
          id: bridgeMaterial.id,
          bridgeWidthMillimetres: bridgeWidth,
          centresDistanceMillimetres: bridgeCentres,
          nonBridgeHeightMillimetres: bridgeNonBridgedHeight,
        }
        : undefined;

    const mechanicalFasteners: ApiLayerMechanicalFastenerRequest | undefined =
      fasteners != null
        ? {
          id: fasteners.id,
          crossSectionalAreaMillimetresSquared: fastenersCrossArea,
          fasteningsPerMetreSquared: fastenersNumber,
        }
        : undefined;

    const layerNotes: ApiLayerNotesRequest | undefined =
      isStringNotNullishOrWhitespace(notes) && notes != null
        ? {
          notes,
        }
        : undefined;

    if (mode === LayerModalMode.EDIT && layerInstanceId) {
      dispatch(
        editLayerInCalculation(
          layerInstanceId,
          masterLayer,
          layerThickness,
          customReferenceLayerRequest,
          layerBridging,
          mechanicalFasteners,
          calculatedCorrectionFactor,
          airGapsCorrectionLevelOverride,
          layerNotes,
          vapourResistance,
          vapourResistivity,
          true
        )
      );
    } else if (mode === LayerModalMode.ADD && layerInstanceId) {
      dispatch(
        addLayerToCalculation(
          layerInstanceId,
          masterLayer,
          layerThickness,
          customReferenceLayerRequest,
          layerBridging,
          mechanicalFasteners,
          calculatedCorrectionFactor,
          airGapsCorrectionLevelOverride,
          layerNotes,
          vapourResistance,
          vapourResistivity,
          true
        )
      );
    }
  }, [
    dispatch,
    mode,
    layerInstanceId,
    notes,
    localState,
    masterLayer,
    layerThickness,
    customReferenceLayer,
    bridgeMaterial,
    bridgeWidth,
    bridgeCentres,
    bridgeNonBridgedHeight,
    fasteners,
    fastenersCrossArea,
    fastenersNumber,
    calculatedCorrectionFactor,
    airGapsCorrectionLevelOverride,
    vapourResistance,
    vapourResistivity,
  ]);

  const handleSubmitLayer = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (isModalStateInvalid(localState)) {
        return;
      }

      const customReferenceLayerRequest: ApiCustomReferenceLayerRequest | undefined =
        customReferenceLayer != null
          ? {
            ...customReferenceLayer,
          }
          : undefined;

      const layerBridging: ApiLayerBridgingRequest | undefined =
        bridgeMaterial != null
          ? {
            id: bridgeMaterial.id,
            bridgeWidthMillimetres: bridgeWidth,
            centresDistanceMillimetres: bridgeCentres,
            nonBridgeHeightMillimetres: bridgeNonBridgedHeight,
          }
          : undefined;

      const mechanicalFasteners: ApiLayerMechanicalFastenerRequest | undefined =
        fasteners != null
          ? {
            id: fasteners.id,
            crossSectionalAreaMillimetresSquared: fastenersCrossArea,
            fasteningsPerMetreSquared: fastenersNumber,
          }
          : undefined;

      const layerNotes: ApiLayerNotesRequest | undefined =
        isStringNotNullishOrWhitespace(notes) && notes != null
          ? {
            notes,
          }
          : undefined;

      if (mode === LayerModalMode.EDIT && layerInstanceId) {
        dispatch(
          editLayerInCalculation(
            layerInstanceId,
            masterLayer,
            layerThickness,
            customReferenceLayerRequest,
            layerBridging,
            mechanicalFasteners,
            calculatedCorrectionFactor,
            airGapsCorrectionLevelOverride,
            layerNotes,
            vapourResistance,
            vapourResistivity
          )
        );

        onClose();
      } else if (mode === LayerModalMode.ADD && layerInstanceId) {
        dispatch(
          addLayerToCalculation(
            layerInstanceId,
            masterLayer,
            layerThickness,
            customReferenceLayerRequest,
            layerBridging,
            mechanicalFasteners,
            calculatedCorrectionFactor,
            airGapsCorrectionLevelOverride,
            layerNotes,
            vapourResistance,
            vapourResistivity
          )
        );

        clearAllDetails();

        setTabIndex(false);

        setLayerInstanceId(uuid());

        focusOnShortCodeField();
      }
    },
    [
      dispatch,
      mode,
      localState,
      notes,
      layerInstanceId,
      onClose,
      masterLayer,
      layerThickness,
      customReferenceLayer,
      bridgeMaterial,
      bridgeWidth,
      bridgeCentres,
      bridgeNonBridgedHeight,
      fasteners,
      fastenersCrossArea,
      fastenersNumber,
      calculatedCorrectionFactor,
      airGapsCorrectionLevelOverride,
      clearAllDetails,
      setTabIndex,
      focusOnShortCodeField,
      setLayerInstanceId,
      vapourResistance,
      vapourResistivity,
    ]
  );

  const handleDialogCancel = () => {
    dispatch(clearInterimCalculation());
    onClose();
  };

  const setLayerFromShortCode = React.useCallback(
    (shortCode: string, apiBridgeResponse: ApiLayerBridgingResponse | undefined) => {
      const layer = masterData.layers.find(layer => layer.shortCode.toLowerCase() === shortCode.toLowerCase());

      if (shortCode && layer) {
        setHasShortCodeError(false);

        setMasterLayer(layer, apiBridgeResponse);

        setShortCode(defaults.shortCode || '');

        focusOnThicknessField();
      } else {
        setHasShortCodeError(true);

        selectAllTextInShortCodeField();
      }
    },
    [
      masterData.layers,
      setMasterLayer,
      setShortCode,
      focusOnThicknessField,
      defaults.shortCode,
      setHasShortCodeError,
      selectAllTextInShortCodeField,
    ]
  );

  return (
    <Dialog
      open
      onClose={handleDialogCancel}
      onEntered={() => {
        if (mode === LayerModalMode.ADD) {
          focusOnShortCodeField();
        } else if (mode === LayerModalMode.EDIT) {
          focusOnThicknessField();
        }
      }}
      aria-labelledby="modal-layer-title"
    >
      <form data-qa-id="layerModalForm" onSubmit={handleSubmitLayer} noValidate>
        <div className={styles.modalHeader}>
          <DialogTitle id="modal-layer-title">
            <Typography component="span" variant="h5">{mode === LayerModalMode.EDIT ? 'Edit Layer' : 'Add Layer'}</Typography>
          </DialogTitle>
        </div>

        <div className={styles.modalContent}>
          <DialogContent>
            <CoreLayerDetails
              masterData={masterData}
              clearAllDetails={clearAllDetails}
              layerThermalResistance={interimLayer?.thermalResistance}
              apiBridgeResponse={interimLayer?.layerBridging}
              masterLayer={masterLayer}
              setMasterLayer={setMasterLayer}
              layerThickness={layerThickness}
              setLayerThickness={setLayerThickness}
              shortCode={shortCode}
              setShortCode={setShortCode}
              hasShortCodeError={hasShortCodeError}
              setHasShortCodeError={setHasShortCodeError}
              setLayerFromShortCode={setLayerFromShortCode}
              customReferenceLayer={customReferenceLayer}
              setCustomReferenceLayer={setCustomReferenceLayer}
              updateCustomReferenceLayer={updateCustomReferenceLayer}
              refShortCodeField={refShortCodeField}
              refThicknessField={refThicknessField}
              isLocked={calculation?.locked}
            />

            {!customReferenceLayer?.isBlank && (
              <LayerDetailsTabs
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                mode={mode}
                masterData={masterData}
                referenceLayer={masterLayer ?? customReferenceLayer}
                bridgeMaterial={bridgeMaterial}
                setBridgeMaterial={setBridgeMaterial}
                bridgeWidth={bridgeWidth}
                setBridgeWidth={setBridgeWidth}
                bridgeCentres={bridgeCentres}
                setBridgeCentres={setBridgeCentres}
                bridgeNonBridgedHeight={bridgeNonBridgedHeight}
                setBridgeNonBridgedHeight={setBridgeNonBridgedHeight}
                layerBridgePercentage={interimLayer?.layerBridging?.bridgePercentage}
                clearBridgingDetails={clearBridgingDetails}
                fasteners={fasteners}
                setFasteners={setFasteners}
                fastenersCrossArea={fastenersCrossArea}
                setFastenersCrossArea={setFastenersCrossArea}
                fastenersNumber={fastenersNumber}
                setFastenersNumber={setFastenersNumber}
                clearFastenersDetails={clearFastenersDetails}
                fastenersAlpha={interimLayer?.mechanicalFastener?.alphaCoefficient}
                fastenersThermalConductivity={interimLayer?.mechanicalFastener?.thermalConductivity}
                fastenersCorrectionFactor={interimLayer?.mechanicalFastener?.correctionFactor}
                airGapsCorrectionLevel={interimLayer?.airGapsCorrection?.correctionFactorResult}
                airGapsCorrectionLevelOverride={airGapsCorrectionLevelOverride}
                setAirGapsCorrectionLevelOverride={setAirGapsCorrectionLevelOverride}
                insideEmissivity={interimLayer?.insideEmissivity}
                outsideEmissivity={interimLayer?.outsideEmissivity}
                airGapsCorrectionResultValue={interimLayer?.airGapsCorrection?.airGapsCorrectionResultValue}
                totalAirGapsCorrectionFactor={calculation?.airGapsCorrectionFactor}
                setVapourResistances={setVapourResistances}
                vapourResistance={interimLayer?.vapourResistance || null}
                vapourResistivity={interimLayer?.vapourResistivity || null}
                notes={notes}
                setLayerNotes={setLayerNotes}
                isLocked={calculation?.locked}
              />
            )}
          </DialogContent>
        </div>

        <div className={styles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="layerCloseButton" onClick={handleDialogCancel} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  data-qa-id="layerSubmitButton"
                  fullWidth
                  disabled={calculation?.locked || isModalStateInvalid(localState)}
                  onClick={handleSubmitLayer}
                  color="primary"
                  variant="contained"
                >
                  {mode === LayerModalMode.EDIT ? 'Save' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}

export function getLayerModelComponent(defaults: DefaultModalState) {
  return function LayerModal(props: Props) {
    return LayerModalWithDefaults(defaults, props);
  };
}

export const LayerModal = getLayerModelComponent(defaultState);
