import React from 'react';
import { debounce, Cancelable } from 'lodash';

export const useDebounce = function<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<any>(null);

  const debounceState = React.useRef<((value: any) => void) & Cancelable>(debounce(newValue => setDebouncedValue(newValue), delay));

  React.useEffect(() => {
    const { current } = debounceState;
    current(value);
    return () => current.cancel();
  }, [delay, value]);

  return debouncedValue;
};
