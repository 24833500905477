import React, { ChangeEvent } from 'react';
import { Grid, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TabPanel } from './TabPanel';
import { TabIndexInfo } from '../../types/components/tabTypes';
import { MasterDataBridgeMaterial, MasterData } from '../../types/store/masterDataTypes';
import styles from './LayerModal.module.scss';
import { ReferenceLayer } from '../../types/components/layerSelectionTypes';

export type CoreBridgingTabProps = {
  masterData: MasterData;
  clearBridgingDetails: () => void;
  referenceLayer: ReferenceLayer | null;
  bridgeMaterial: MasterDataBridgeMaterial | null;
  setBridgeMaterial: (bridgeMaterial: MasterDataBridgeMaterial) => void;
  bridgeWidth: string;
  setBridgeWidth: (bridgeWidth: string) => void;
  bridgeCentres: string;
  setBridgeCentres: (bridgeCentres: string) => void;
  bridgeNonBridgedHeight: string | null;
  setBridgeNonBridgedHeight: (bridgeNonBridgedHeight: string | null) => void;
  layerBridgePercentage?: string;
  isLocked?: boolean;
};

export type BridgingTabProps = CoreBridgingTabProps & TabIndexInfo;

export function BridgingTab(props: BridgingTabProps) {
  const {
    masterData,
    clearBridgingDetails,
    referenceLayer,
    bridgeMaterial,
    setBridgeMaterial,
    bridgeWidth,
    setBridgeWidth,
    bridgeCentres,
    setBridgeCentres,
    bridgeNonBridgedHeight,
    setBridgeNonBridgedHeight,
    layerBridgePercentage,
    targetIndex,
    currentTabIndex,
    isLocked,
  } = props;

  return (
    <TabPanel
      id="layer-bridging-details-panel"
      data-qa-id="layer-bridging-details-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="layer-bridging-details-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel htmlFor="modal-layer-bridge-material" data-qa-id="layerBridgeMaterialLabel">
              Bridge Material
            </InputLabel>

            <Autocomplete
              id="modal-layer-bridge-material"
              data-qa-id="layerBridgeMaterialInput"
              options={masterData.bridgeMaterials as MasterDataBridgeMaterial[]}
              autoHighlight
              getOptionLabel={(material: MasterDataBridgeMaterial) => material.name}
              onChange={(event: ChangeEvent<{}>, material: MasterDataBridgeMaterial | null) => {
                if (!material) {
                  clearBridgingDetails();
                  return;
                }

                setBridgeMaterial(material);
              }}
              value={bridgeMaterial}
              disabled={isLocked}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Browse catalogue..."
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // Disable browser autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={2}>
            <InputLabel htmlFor="modal-layer-bridge-width" data-qa-id="bridgeWidthLabel">
              Bridge Width
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="modal-layer-bridge-width"
                  data-qa-id="bridgeWidthInput"
                  variant="outlined"
                  value={bridgeWidth}
                  disabled={isLocked}
                  placeholder="0.0"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setBridgeWidth(event.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerBridgeWidthUnits">
                  mm
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <InputLabel htmlFor="modal-layer-bridge-centres" data-qa-id="bridgeCentresLabel">
              Centres
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="modal-layer-bridge-centres"
                  data-qa-id="bridgeCentresInput"
                  variant="outlined"
                  value={bridgeCentres}
                  disabled={isLocked}
                  placeholder="0.0"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setBridgeCentres(event.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerBridgeCentresUnits">
                  mm
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <InputLabel htmlFor="modal-layer-bridge-non-bridged-height" data-qa-id="bridgeNonBridgedHeightLabel">
              Non-Bridged Height
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="modal-layer-bridge-non-bridged-height"
                  data-qa-id="bridgeNonBridgedHeightInput"
                  variant="outlined"
                  value={bridgeNonBridgedHeight || ''}
                  disabled={isLocked}
                  helperText="(if known)"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setBridgeNonBridgedHeight(event.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerNonBridgedHeightUnits">
                  mm
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={styles.tabContentResults}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="bridgedThermalConductivityLabel">
                  Bridged Thermal Conductivity
                </Grid>

                <Grid item xs={4} data-qa-id="bridgedThermalConductivity">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="bridgedThermalConductivityValue">
                      {bridgeMaterial?.bridgeThermalConductivity ?? '0.000'}
                    </div>

                    <div className={styles.tabContentResultUnit} data-qa-id="bridgedThermalConductivityUnit">
                      W/mK
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="unbridgedThermalConductivityLabel">
                  Unbridged Thermal Conductivity
                </Grid>

                <Grid item xs={4} data-qa-id="unbridgedThermalConductivity">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="unbridgedThermalConductivityValue">
                      {referenceLayer?.thermalConductivity ?? '0.000'}
                    </div>

                    <div className={styles.tabContentResultUnit} data-qa-id="unbridgedThermalConductivityUnit">
                      W/mK
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="bridgingPercentageLabel">
                  Bridging Percentage
                </Grid>

                <Grid item xs={4} data-qa-id="bridgingPercentage">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="bridgingPercentageValue">
                      {layerBridgePercentage != null ? (Number.parseFloat(layerBridgePercentage) * 100).toFixed(1) : '0.0'}
                    </div>

                    <div className={styles.tabContentResultUnit} data-qa-id="bridgingPercentageUnit">
                      %
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
