import React, { FormEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  InputLabel,
  TextField,
  Paper,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { EnvCondition, MonthValue, EnvConditionType, EnvConditionOverride } from '../../types/store/calculationTypes';

import styles from './EnvConditionsEditorModal.module.scss';

type OverrideMonth = MonthValue & {
  overridden: boolean;
};

type OverrideEnvCondition = {
  envConditionType: EnvConditionType;
  months: OverrideMonth[];
  name: string;
  unit: string;
};

type EnvConditionsEditorModalProps = {
  envConditions: EnvCondition[];
  monthKey: number;
  onClose: () => void;
  onMoveToNextMonth: () => void;
  onMoveToPrevMonth: () => void;
  onSubmit: (overrides: EnvConditionOverride[]) => void;
  isLocked?: boolean;
};

export const EnvConditionsEditorModal: React.FC<EnvConditionsEditorModalProps> = ({
  envConditions,
  monthKey,
  onClose,
  onMoveToNextMonth,
  onMoveToPrevMonth,
  onSubmit,
  isLocked,
}) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'long' });

  const [editableConditions, setEditableConditions] = React.useState<OverrideEnvCondition[]>(
    envConditions.map(e => ({
      envConditionType: e.envConditionType,
      name: e.name,
      months: e.months.map(m => ({
        id: m.id,
        name: m.name,
        overridden: false,
        value: m.value,
      })),
      unit: e.unit,
    }))
  );

  const handleDialogCancel = () => {
    onClose();
  };

  const overrides = editableConditions
    .filter(e => e.months.some(m => m.overridden))
    .map(e => ({
      envConditionType: e.envConditionType,
      months: e.months
        .filter(m => m.overridden)
        .map(m => ({
          id: m.id,
          value: m.value,
        })),
    }));

  const handleSubmitMonthValues = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(overrides);
    onClose();
  };

  const isInvalid =
    overrides.length === 0 ||
    editableConditions.flatMap((o: OverrideEnvCondition): string[] => o.months.map(m => m.value)).some(v => v === '' || isNaN(Number(v)));

  const handleMonthValueChange = (envConditionType: EnvConditionType) => ({ target: { value } }: React.ChangeEvent<any>) => {
    setEditableConditions(prevState => {
      const updatedConditions = cloneDeep(prevState);
      const conditionToUpdate = updatedConditions.find(o => o.envConditionType === envConditionType);
      if (conditionToUpdate) {
        const monthToUpdate = conditionToUpdate.months.find(m => m.id === monthKey);
        if (monthToUpdate) {
          monthToUpdate.overridden = true;
          monthToUpdate.value = value;
          return updatedConditions;
        }
      }
      return prevState;
    });
  };

  return (
    <Dialog open onClose={handleDialogCancel} aria-labelledby="condensation-risk-title" maxWidth="xs">
      <form data-qa-id="envConditionsEditorForm" onSubmit={handleSubmitMonthValues} noValidate>
        <div className={styles.modalHeader}>
          <DialogTitle id="condensation-risk-title">Update Values</DialogTitle>
        </div>

        <div className={styles.modalContent}>
          <DialogContent className={styles.envConditionData}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs={2} container justify="flex-end">
                    <Button data-qa-id="editEnvConditionsPrevMonthButton" disabled={monthKey < 2} onClick={onMoveToPrevMonth}>
                      {'<'}
                    </Button>
                  </Grid>
                  <Grid item xs={2} container alignItems="center" justify="center">
                    <Typography align="center">{dateTimeFormat.format(new Date(1, monthKey - 1, 1))}</Typography>
                  </Grid>
                  <Grid item xs={2} container justify="flex-start">
                    <Button data-qa-id="editEnvConditionsNextMonthButton" disabled={monthKey > 11} onClick={onMoveToNextMonth}>
                      {'>'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <div className={styles.envConditions}>
                    <Grid container direction="row" justify="space-around" spacing={2}>
                      {editableConditions.map(({ envConditionType, name, months, unit }) => (
                        <Grid key={envConditionType} item xs={6}>
                          <InputLabel data-qa-id={`envCondition${envConditionType}Label`} id={`env-condition-${envConditionType}-label`}>
                            {name}
                          </InputLabel>

                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                className={styles.envConditionEditBox}
                                id={`env-condition-${envConditionType}`}
                                data-qa-id={`envCondition${envConditionType}Input`}
                                placeholder="0.0"
                                variant="outlined"
                                value={months[monthKey - 1]?.value}
                                onChange={handleMonthValueChange(envConditionType)}
                                disabled={isLocked}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <div className={styles.inputUnit} data-qa-id={`envCondition${envConditionType}Units`}>
                                {unit}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        </div>

        <div className={styles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="editEnvConditionsCloseButton" onClick={handleDialogCancel} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  data-qa-id="editEnvConditionsSubmitButton"
                  disabled={isLocked || isInvalid}
                  fullWidth
                  onClick={handleSubmitMonthValues}
                  color="primary"
                  variant="contained"
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
};
