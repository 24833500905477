import React, { ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, Autocomplete, createFilterOptions, CreateFilterOptionsConfig } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './ProjectInfoModal.module.scss';
import { buildingUsagesSelector, currentCalculationBusinessUnitIdSelector, interimOrCurrentCalculationSelector, projectCountyStateSelector, projectRegionsSelector, projectStageSelector } from '../../store/selectors';
import { Project } from '../../types/store/projectTypes';
import { associateProject } from '../../actions/projectActions';
import { callApi } from '../../common/api';
import { useDebounce } from '../Utilities/hooks/useDebounce';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

type Props = {
  onClose: () => void;
};

type State = {
  newProject: Project | null;
  existingProject: Project | null;
};

const config: CreateFilterOptionsConfig<Project> = {
  stringify: (option: Project) => option.name,
};

const filter = createFilterOptions<Project>(config);

export function ProjectInfoModal({ onClose }: Props) {
  const dispatch = useDispatch();

  const calculation = useSelector(interimOrCurrentCalculationSelector);
  const currentCalculationBusinessUnitId: number | null = useSelector(currentCalculationBusinessUnitIdSelector);
  const projectUsages = useSelector(buildingUsagesSelector);
  const projectRegions = useSelector(projectRegionsSelector);
  const projectCountyStates = useSelector(projectCountyStateSelector);
  const projectStages = useSelector(projectStageSelector);

  const defaultState: State = {
    newProject: {
      id: null,
      name: '',
      displayId: '',
      type: '',
      businessUnitId: null,
      supportingInfo: '',
      size: '',
      projectUsage: '',
      projectUsageValue: '',
      buildingHeight: '',
      region: '',
      regionValue: '',
      enquiryType: '',
      projectStartDate: new Date().toDateString(),
      projectStage: '',
      projectStageValue: '',
      projectAddressLine1: '',
      projectAddressLine2: '',
      projectCity: '',
      projectCountyState: '',
      projectCountyStateValue: '',
      projectPostCode: '',
    },
    existingProject: null,
  };

  const [state, setState] = React.useState<State>(defaultState);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [existingProjects, setExistingProjects] = React.useState<Project[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());

  const disableButtons = calculation?.locked || (state.newProject ? !state.newProject.name : !state.existingProject?.name);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const updateField = (fieldName: string, value: string) => {
    setState(currentState => ({
      ...currentState,
      ...(state.newProject
        ? {
          newProject: {
            ...currentState.newProject!,
            [fieldName]: value,
          },
        }
        : {
          existingProject: {
            ...currentState.existingProject!,
            [fieldName]: value,
          },
        }),
    }));
  };

  const handleProjectNameChange = (project: Project | null) => {
    if (!project) {
      return setState(defaultState);
    }

    project.businessUnitId = currentCalculationBusinessUnitId;

    if (project.id != null) {
      setState(currentState => ({
        ...currentState,
        newProject: null,
        existingProject: project,
      }));
      setStartDate(project.projectStartDate ? new Date(project.projectStartDate) : new Date());
    } else {
      if (state.newProject) {
        setState(currentState => ({
          ...currentState,
          newProject: {
            ...currentState.newProject!,
            name: project.name,
            businessUnitId: project.businessUnitId,
          },
          existingProject: null,
        }));
        setStartDate(new Date());
      } else {
        setState(currentState => ({
          ...currentState,
          newProject: project,
          existingProject: null,
        }));
        setStartDate(project.projectStartDate ? new Date(project.projectStartDate) : new Date());
      }
    }
  }

  const handleSubmit = React.useCallback(
    async (event: FormEvent, shouldAssociateToAll: boolean) => {
      event.preventDefault();
      try {
        if (state.newProject) {
          state.newProject.projectStartDate = startDate?.toDateString() ?? '';
          await dispatch(associateProject(state.newProject, true, shouldAssociateToAll));
        } else if (state.existingProject) {
          state.existingProject.projectStartDate = startDate?.toDateString() ?? '';
          await dispatch(associateProject(state.existingProject, false, shouldAssociateToAll));
        }
        onClose();
      } catch (e) {
        setError('Your project could not be saved and associated, please try again.');
      }
    },
    [dispatch, state, onClose, startDate]
  );

  React.useEffect(() => {
    if (calculation?.project) {
      (async () => {
        const existingProject = await callApi<Project>(dispatch, 'GET', `/Project/${currentCalculationBusinessUnitId}/${calculation.project?.id}`);

        if (existingProject) {
          setState({
            newProject: null,
            existingProject,
          });
          setStartDate(existingProject.projectStartDate ? new Date(existingProject.projectStartDate) : null);
        }
      })();
    }
  }, [dispatch, calculation, currentCalculationBusinessUnitId]);


  React.useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        if (searchTerm.length < 3) {
          return setExistingProjects([]);
        }

        const existingProjects = await callApi<Project[]>(dispatch, 'GET', `/Project/search/${currentCalculationBusinessUnitId}/${debouncedSearchTerm}`);

        if (existingProjects) {
          setExistingProjects(existingProjects);
        }
      })();
    }
  }, [dispatch, searchTerm, debouncedSearchTerm, setExistingProjects, currentCalculationBusinessUnitId]);

  return (
    <Dialog open onClose={onClose} aria-labelledby="modal-project-info-title">
      <form data-qa-id="projectInfoModalForm" onSubmit={(event: FormEvent) => handleSubmit(event, false)} noValidate>
        <div className={modalStyles.modalHeader}>
          <DialogTitle id="modal-project-info-title">
            <Typography component="span" variant="h5">Project Information</Typography>
          </DialogTitle>
        </div>

        <div className={`${[modalStyles.modalContent, styles.modalContent].join(' ')}`}>
          <DialogContent>
            {error && (
              <Alert severity="error" className={styles.alert} data-qa-id="projectInfoError">
                {error}
              </Alert>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <InputLabel htmlFor="projectInfoProjectNameInput" data-qa-id="projectInfoProjectNameInputLabel">
                      Project Name
                    </InputLabel>

                    <Autocomplete
                      id="projectInfoProjectNameInput"
                      data-qa-id="projectInfoProjectNameInput"
                      options={existingProjects}
                      autoHighlight
                      disabled={calculation?.locked}
                      getOptionLabel={(option: Project) => option.name}
                      onInputChange={(event: ChangeEvent<any>, searchTerm: string) => setSearchTerm(searchTerm)}
                      onChange={(event: ChangeEvent<{}>, project: Project | null) => handleProjectNameChange(project)}
                      value={state.newProject ?? state.existingProject}
                      forcePopupIcon
                      freeSolo
                      renderOption={(project: Project) =>
                        project.id != null ? (
                          project.name
                        ) : (
                          <Grid container justify="space-between" alignItems="center">
                            <span data-qa-id="newProjectOptionName">{project.name}</span>

                            <Grid item>
                              <Button variant="outlined" data-qa-id="newProjectOptionButton">
                                {state.newProject?.name ? 'Update' : '+ New Project'}
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params) as Project[];

                        if (params.inputValue !== '') {
                          const option: Project = state.newProject
                            ? {
                              ...state.newProject,
                              name: params.inputValue,
                            }
                            : {
                              ...defaultState.newProject!,
                              name: params.inputValue,
                            };

                          filtered.push(option);
                        }

                        return filtered.reduce<Project[]>((acc, project) => {
                          const isNewOption = project.id === null;
                          const isAlreadyASuggestion = existingProjects.find(p => p.name.toLowerCase() === project.name.toLowerCase());

                          return isNewOption && isAlreadyASuggestion ? acc : [...acc, project];
                        }, []);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Enter 3 or more characters to search..."
                          variant="outlined"
                          autoComplete="off" // Disable browser autocomplete and autofill
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectIdInput" data-qa-id="projectInfoProjectIdInputLabel">
                      Project ID
                    </InputLabel>

                    <TextField
                      id="projectInfoProjectIdInput"
                      data-qa-id="projectInfoProjectIdInput"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.displayId : state.existingProject?.displayId}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('displayId', value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <InputLabel htmlFor="projectInfoProjectAddressLine1Input" data-qa-id="projectInfoProjectAddressLine1Label">
                      Project Address Line 1
                    </InputLabel>

                    <TextField
                      id="projectInfoProjectAddressLine1Input"
                      data-qa-id="projectInfoProjectAddressLine1Input"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.projectAddressLine1 : state.existingProject?.projectAddressLine1}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('projectAddressLine1', value)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectStartDateInput" data-qa-id="projectInfoProjectStartDateInputLabel">
                      Project Start Date
                    </InputLabel>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box border={1} borderRadius={4} borderColor="lightGray">
                          <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="projectInfoProjectStartDateInput"
                            data-qa-id="projectInfoProjectStartDateInput"
                            value={startDate}
                            onChange={setStartDate}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{ disableUnderline: true }}
                            style={{ paddingLeft: '14px' }}
                          />
                          </Box>
                      </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <InputLabel htmlFor="projectInfoProjectAddressLine2Input" data-qa-id="projectInfoProjectAddressLine2Label">
                      Project Address Line 2
                    </InputLabel>

                    <TextField
                      id="projectInfoProjectAddressLine2Input"
                      data-qa-id="projectInfoProjectAddressLine2Input"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.projectAddressLine2 : state.existingProject?.projectAddressLine2}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('projectAddressLine2', value)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectStageInput" data-qa-id="projectInfoProjectStageInputLabel">
                      Project Stage
                    </InputLabel>

                    <Select
                      fullWidth
                      data-qa-id="projectInfoProjectStageInput"
                      id="projectInfoProjectStageInput"
                      value={state.newProject ? state.newProject.projectStage : state.existingProject?.projectStage}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<any>) => {
                        updateField('projectStage', value)
                        updateField('projectStageValue', projectStages.find(ps => ps.id === value)?.code ?? '')
                      }}
                      variant="outlined"
                    >
                      <MenuItem key="null" value="">
                        &nbsp;
                      </MenuItem>

                      {projectStages.map(({ id, code }) => (
                        <MenuItem key={id} value={id}>
                          {code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectCityInput" data-qa-id="projectInfoProjectCityInputLabel">
                      Project City
                    </InputLabel>

                    <TextField
                      id="projectInfoProjectCityInput"
                      data-qa-id="projectInfoProjectCityInput"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.projectCity : state.existingProject?.projectCity}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('projectCity', value)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoCountyStateInput" data-qa-id="projectInfoCountyStateInputLabel">
                      Project County State
                    </InputLabel>

                    <Select
                      fullWidth
                      data-qa-id="projectInfoCountyStateInput"
                      id="projectInfoCountyStateInput"
                      value={state.newProject ? state.newProject.projectCountyState : state.existingProject?.projectCountyState}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<any>) => {
                        updateField('projectCountyState', value)
                        updateField('projectCountyStateValue', projectCountyStates.find(pcs => pcs.id === value)?.county ?? '')
                      }}
                      variant="outlined"
                    >
                      <MenuItem key="null" value="">
                        &nbsp;
                      </MenuItem>

                      {projectCountyStates.map(({ id, county }) => (
                        <MenuItem key={id} value={id}>
                          {county}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectPostCodeInput" data-qa-id="projectInfoProjectPostCodeLabel">
                      Project Postcode
                    </InputLabel>

                    <TextField
                      id="projectInfoProjectPostCodeInput"
                      data-qa-id="projectInfoProjectPostCodeInput"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.projectPostCode : state.existingProject?.projectPostCode}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('projectPostCode', value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectUsageInput" data-qa-id="projectInfoProjectUsageInputLabel">
                      Project Usage
                    </InputLabel>

                    <Select
                      fullWidth
                      data-qa-id="projectInfoProjectUsageInput"
                      id="projectInfoProjectUsageInput"
                      value={state.newProject ? state.newProject.projectUsage : state.existingProject?.projectUsage}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<any>) => {
                        updateField('projectUsage', value);
                        updateField('projectUsageValue', projectUsages.find(pu => pu.id === value)?.name ?? '');
                      }}
                      variant="outlined"
                    >
                      <MenuItem key="null" value="">
                        &nbsp;
                      </MenuItem>

                      {projectUsages.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoProjectTypeInput" data-qa-id="projectInfoProjectTypeInputLabel">
                      Project Type
                    </InputLabel>

                    <Select
                      fullWidth
                      data-qa-id="projectInfoProjectTypeInput"
                      id="projectInfoProjectTypeInput"
                      value={state.newProject ? state.newProject.type : state.existingProject?.type}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<any>) => updateField('type', value)}
                      variant="outlined"
                    >
                      <MenuItem key="null" value="">
                        &nbsp;
                      </MenuItem>

                      {['New Build', 'Extension', 'Refurbishment'].map(projectType => (
                        <MenuItem key={projectType} value={projectType}>
                          {projectType}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoRegionInput" data-qa-id="projectInfoRegionInputLabel">
                      Region
                    </InputLabel>

                    <Select
                      fullWidth
                      data-qa-id="projectInfoRegionInput"
                      id="projectInfoRegionInput"
                      value={state.newProject ? state.newProject.region : state.existingProject?.region}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<any>) => {
                        updateField('region', value);
                        updateField('regionValue', projectRegions.find(pr => pr.id === value)?.name ?? '');
                      }}
                      variant="outlined"
                    >
                      <MenuItem key="null" value="">
                        &nbsp;
                      </MenuItem>

                      {projectRegions.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <InputLabel htmlFor="projectInfoBuildingHeightInput" data-qa-id="projectInfoBuildingHeightInputLabel">
                      Building Height
                    </InputLabel>

                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={11}>
                        <TextField
                          id="projectInfoBuildingHeightInput"
                          data-qa-id="projectInfoBuildingHeightInput"
                          fullWidth
                          variant="outlined"
                          value={state.newProject ? state.newProject.buildingHeight : state.existingProject?.buildingHeight}
                          disabled={calculation?.locked}
                          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('buildingHeight', value)}
                        />
                      </Grid>

                      <Grid item xs={1}>
                        <div className={modalStyles.inputUnit} data-qa-id="projectInfoBuildingHeightInputUnit">
                          m
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <InputLabel data-qa-id="projectInfoProjectSizeInputLabel">Project Size</InputLabel>

                    <div className={modalStyles.relative}>
                      <ButtonGroup
                        size="large"
                        variant="contained"
                        className={styles.multiButtonContainer}
                        data-qa-id="projectInfoProjectSizeButtons"
                      >
                        {['Small', 'Large (not in CRM)', 'Large (in CRM)'].map((size, index) => (
                          <Button
                            key={size}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            disabled={calculation?.locked}
                            className={
                              (state.newProject
                                ? state.newProject.size === size
                                : state.existingProject?.size === size)
                                ? styles.multiButtonSelected
                                : ''
                            }
                            onClick={() => updateField('size', size)}
                            data-qa-id={`projectInfoProjectSizeButton${index}`}
                          >
                            {size}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="projectInfoSupportingInformationInput" data-qa-id="projectInfoSupportingInformationInputLabel">
                      Project Supporting Information
                    </InputLabel>

                    <TextField
                      id="projectInfoSupportingInformationInput"
                      data-qa-id="projectInfoSupportingInformationInput"
                      fullWidth
                      variant="outlined"
                      value={state.newProject ? state.newProject.supportingInfo : state.existingProject?.supportingInfo}
                      disabled={calculation?.locked}
                      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => updateField('supportingInfo', value)}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel data-qa-id="projectInfoEnquiryTypeInputLabel">Source of Enquiry</InputLabel>
                    <div className={modalStyles.relative}>
                      <ButtonGroup size="large" variant="contained" className={styles.multiButtonContainer} data-qa-id="projectInfoEnquiryType">
                        {['Telephone', 'Email', 'Other', 'Website'].map((enquiryType, index) => (
                          <Button
                            key={enquiryType}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            className={
                              (state.newProject
                                ? state.newProject.enquiryType === enquiryType
                                : state.existingProject?.enquiryType === enquiryType)
                                ? styles.multiButtonSelected
                                : ''
                            }
                            onClick={() => updateField('enquiryType', enquiryType)}
                            data-qa-id={`projectInfoProjectEnquiryTypeButton${index}`}
                          >
                            {enquiryType}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </div>

        <div className={modalStyles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="projectInfoCloseButton" onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid container item xs={3} justify="flex-end">
                <Button
                  type="button"
                  data-qa-id="projectInfoAssociateAllButton"
                  fullWidth
                  onClick={(event: FormEvent) => handleSubmit(event, true)}
                  color="primary"
                  variant="outlined"
                  disabled={disableButtons}
                >
                  Associate to all
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button
                  type="submit"
                  data-qa-id="projectInfoSubmitButton"
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={disableButtons}
                >
                  Associate to current
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}
