import React from 'react';
import { Grid, InputLabel, Select, MenuItem, Paper } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';

import { TabPanel } from '../LayerModal/TabPanel';
import { interstitialInterfaceSelector } from '../../store/selectors';
import { TabIndexInfo } from '../../types/components/tabTypes';

import modalStyles from './CondensationRiskModal.module.scss';
import tableStyles from './EnvConditionsTab.module.scss';
import { useSelector } from 'react-redux';
import { InterstitialInterface } from '../../types/store/calculationTypes';

export type CondensationResultsTabProps = TabIndexInfo & { isLocked?: boolean };

type TableRow = {
  condensationProperty: string;
  months: string[];
};

const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'short' });
const monthColumns: Column<TableRow>[] = new Array(12).fill({}).map((_, i) => ({
  field: `months[${i}]`,
  key: i + 1,
  title: dateTimeFormat.format(new Date(1, i, 1)),
}));
const columns = [
  ...[],
  {
    field: 'name',
    key: 0,
    render: (rowdata: TableRow) => <span className={tableStyles.tableCellContainer}>{rowdata.condensationProperty}</span>,
    title: '',
  },
  ...monthColumns,
];

export function CondensationResultsTab({ currentTabIndex, targetIndex, isLocked }: CondensationResultsTabProps) {
  const interstitialInterfaces: InterstitialInterface[] = useSelector(interstitialInterfaceSelector);
  const [selectedInterfaceId, setSelectedInterfaceId] = React.useState<string>();
  const selectedInterface = interstitialInterfaces.find(i => i.id === selectedInterfaceId);

  const handleSelectedInterfaceIdChange = ({ target: { value } }: React.ChangeEvent<any>) => {
    setSelectedInterfaceId(value);
  };

  const createTableRows: () => TableRow[] = () => {
    if (selectedInterface) {
      return [
        {
          condensationProperty: 'Saturation Vapour Pressure (kPa)',
          months: selectedInterface.months.map(m => m.saturationVapourPressure),
        },
        {
          condensationProperty: 'Vapour Pressure (kPa)',
          months: selectedInterface.months.map(m => m.vapourPressure),
        },
        {
          condensationProperty: 'Gc (kg/m2)',
          months: selectedInterface.months.map(m => m.condensationAmount),
        },
        {
          condensationProperty: 'Ma (kg/m2)',
          months: selectedInterface.months.map(m => m.condensationAccumulationAmount),
        },
      ];
    }
    return [];
  };

  React.useEffect(() => {
    setSelectedInterfaceId(interstitialInterfaces[0]?.id);
  }, [interstitialInterfaces]);

  return (
    <TabPanel
      id="condensation-results-panel"
      data-qa-id="condensation-results-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="condensation-results-tab"
    >
      <div className={modalStyles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel data-qa-id="interface-select-label" id="interface-select-label">
              Interface
            </InputLabel>

            <Select
              fullWidth
              data-qa-id="interface-select-input"
              id="interface-select-input"
              value={selectedInterface?.id || ''}
              onChange={handleSelectedInterfaceIdChange}
              variant="outlined"
              disabled={isLocked || interstitialInterfaces.length === 0}
            >
              {interstitialInterfaces.map(interstitialInterface => {
                return (
                  <MenuItem key={interstitialInterface.id} value={interstitialInterface.id}>
                    {interstitialInterface.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columns}
              data={createTableRows()}
              options={{
                sorting: false,
                showTitle: false,
                search: false,
                paging: false,
                toolbar: false,
                grouping: false,
                selection: false,
                draggable: false, // Relates to draggable columns, not rows
              }}
              components={{
                Container: renderProps => (
                  <div className={tableStyles.tableContainer}>
                    <Paper {...renderProps} className={tableStyles.table} variant="outlined" />
                  </div>
                ),
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No interfaces defined',
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
