import React from 'react';

import styles from './Header.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';

export function Header() {
  return (
    <div className={styles.header}>
      <img src={logo} alt="Logo" className={styles.logo} />
    </div>
  );
}
