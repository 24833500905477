import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
  InputLabel,
  TextField,
  Typography,
  Box,
  CircularProgress,
  ListItemAvatar,
  List,
  ListItem,
  Backdrop,
 } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';

import { openCalculation } from '../../actions/calculationActions';
import { callApi } from '../../common/api';
import { utcDateStringToDate } from '../../common/dates';
import { inactiveCalculationsSelector, interimOrCurrentCalculationSelector } from '../../store/selectors';
import { Calculation } from '../../types/store/calculationTypes';
import { InactiveCalculationsState } from '../../types/store/inactiveCalculationsTypes';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './OpenCalculationModal.module.scss';
import { TechnicalCalculationModel } from './TechnicalCalculationModel';
import { AppDispatch } from '../../store/store';

type Props = {
  onClose: (forceWelcome?: boolean) => void;
};

export function OpenCalculationModal({ onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);
  const inactiveCalculations: InactiveCalculationsState = useSelector(inactiveCalculationsSelector);

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const [isOpening, setIsOpening] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [previousCalcs, setPreviousCalcs] = React.useState<TechnicalCalculationModel[]>([]);

  const handleOpen = async (calculationId: string) => {
    const openCalculationIds = [calculation, ...inactiveCalculations].map(c => c && c.calculationId);

    if (openCalculationIds.includes(calculationId)) {
      setError('The requested calculation is already open, please try again.');
      return;
    }

    setIsOpening(true);
    try {
      await dispatch(openCalculation(calculationId));

      onClose();
    } catch (e) {
      const status = (e as any)?.response?.status;

      const message = status === 400 /* Bad Request */
        ? `Unable to load calculation. ${(e as any)?.response?.data?.message}.`
        : status === 404 /* Not Found */
          ? 'Your search did not match an existing calculation ID, please try again.'
          : 'An unexpected error occurred.';

      setError(message);
      setIsOpening(false);
    }
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await handleOpen(searchTerm);
  };

  useEffect(() => {
    setIsLoading(true);
    callApi<TechnicalCalculationModel[]>(dispatch, 'GET', '/calculations').then(result => {
      setPreviousCalcs(result ?? []);
      setIsLoading(false);
    }).catch(error => {
      console.error(error);
      setIsLoading(false);
    });
  }, [dispatch]);

  const calculationTitleIsSpecified = (calculation: TechnicalCalculationModel) =>
    calculation.calculationTitle?.length > 0 && calculation.calculationTitle !== calculation.applicationTypeName;

  return (
    <Dialog
      open
      onClose={() => onClose}
      aria-labelledby="modal-open-calculation-title"
    >
      <form data-qa-id="openCalculationModalForm" onSubmit={handleSubmit} noValidate>
        <div className={modalStyles.modalHeader}>
          <DialogTitle id="modal-open-calculation-title">
            <Typography component="span" variant="h5">Open Calculation</Typography>
          </DialogTitle>
        </div>

        <Backdrop open={isOpening} className={styles.loadingBackdrop}>
          <CircularProgress />
        </Backdrop>

        <div className={modalStyles.modalContent}>
          <DialogContent className={styles.modalContent}>
            {error && (
              <Alert severity="error" className={styles.alert} data-qa-id="openExistingCalculationError">
                {error}
              </Alert>
            )}

            <Grid container>
              <Grid item xs={12}>
                <InputLabel htmlFor="openExistingCalculationSearchInput" data-qa-id="openExistingCalculationSearchInputLabel">
                  Existing Calculation ID
                </InputLabel>

                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      id="openExistingCalculationSearchInput"
                      data-qa-id="openExistingCalculationSearchInput"
                      placeholder='e.g. "1-AA-200101-123000-5"'
                      variant="outlined"
                      value={searchTerm}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      type="submit"
                      data-qa-id="openExistingCalculationButton"
                      onClick={handleSubmit}
                      disabled={isOpening}
                      startIcon={isOpening && <CircularProgress size={20} />}
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={styles.openButton}
                    >
                      Open
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={styles.topMargin}>
                <InputLabel>Recent Calculations</InputLabel>

                {isLoading && <Box m={2}><CircularProgress /></Box>}

                {!isLoading &&
                  <List className={styles.calculationList}>
                    {previousCalcs.map(calculation => (
                      <ListItem key={calculation.calculationId} button={true} onClick={async () => await handleOpen(calculation.calculationId)}>
                        <ListItemAvatar>
                          <Avatar>{calculation.advisorId}</Avatar>
                        </ListItemAvatar>
                        <div>
                          <Typography variant='h6'>
                            {calculation.calculationId}
                            {calculationTitleIsSpecified(calculation) && <span>&nbsp;- {calculation.calculationTitle}</span>}
                          </Typography>
                          <Typography>{calculation.applicationTypeName} | {calculation.businessUnitName}</Typography>
                          <Typography>{formatDistanceToNow(utcDateStringToDate(calculation.lastUpdated), { addSuffix: true })}</Typography>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                }
              </Grid>
            </Grid>
          </DialogContent>
        </div>

        <div className={modalStyles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="openCalculationCloseButton" onClick={() => onClose(true)} variant="outlined" disabled={isOpening}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}
