import React, { ReactNode } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import styles from './LandingPage.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';
import { VerticalAlign } from '../Utilities/VerticalAlign/VerticalAlign';

type Props = {
  qaIdBase: string;
  title: ReactNode;
  shouldShowLoadingIcon?: boolean;
  content?: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

export const LandingPage = ({ qaIdBase, shouldShowLoadingIcon, title, content, buttonText, onButtonClick }: Props) => {
  return (
    <div className={styles.landingPageContainer} data-qa-id={qaIdBase}>
      <div className={styles.landingPage}>
        <VerticalAlign align="center">
          <div className={styles.logoContainer}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>

          <div className={styles.titleContainer}>
            <Typography variant="h4" className={styles.title}>
              {shouldShowLoadingIcon && (
                <div className={styles.loadingIconContainer}>
                  <CircularProgress size={24} />
                </div>
              )}

              <span data-qa-id={`${qaIdBase}-title`}>{title}</span>
            </Typography>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.content} data-qa-id={`${qaIdBase}-content`}>
              {content}
            </div>
          </div>

          {buttonText && onButtonClick && (
            <div className={styles.buttonContainer}>
              <Button variant="contained" color="primary" disableElevation onClick={onButtonClick} data-qa-id={`${qaIdBase}-button`}>
                {buttonText}
              </Button>
            </div>
          )}
        </VerticalAlign>
      </div>
    </div>
  );
};
