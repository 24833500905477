import React, { ChangeEvent } from 'react';
import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions, CreateFilterOptionsConfig } from '@material-ui/lab';
import { MasterData, MasterDataLayer } from '../../types/store/masterDataTypes';
import styles from './LayerModal.module.scss';
import {
  customLayerDefaults,
  CustomLayerTypeOptions,
  customLayerTypeOptions,
  CustomReferenceLayer,
  ApiLayerBridgingResponse,
} from '../../types/store/calculationTypes';
import { ReferenceLayer } from '../../types/components/layerSelectionTypes';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
import { isStringNotNullishOrWhitespace } from '../../common/inputValidation';

export type CoreDetailsProps = {
  masterData: MasterData;
  layerThermalResistance: string | undefined;
  clearAllDetails: () => void;
  apiBridgeResponse: ApiLayerBridgingResponse | undefined;
  masterLayer: MasterDataLayer | null;
  setMasterLayer: (masterLayer: MasterDataLayer, apiBridgeResponse: ApiLayerBridgingResponse | undefined) => void;
  layerThickness: string | null;
  setLayerThickness: (shortCode: string) => void;
  shortCode: string | null;
  setShortCode: (shortCode: string) => void;
  hasShortCodeError: boolean;
  setHasShortCodeError: (setHasShortCodeError: boolean) => void;
  setLayerFromShortCode: (shortCode: string, bridgeMaterial: ApiLayerBridgingResponse | undefined) => void;
  customReferenceLayer: CustomReferenceLayer | null;
  setCustomReferenceLayer: (customReferenceLayer: CustomReferenceLayer) => void;
  updateCustomReferenceLayer: (customReferenceLayer: Partial<CustomReferenceLayer>) => void;
  refShortCodeField: React.RefObject<HTMLElement>;
  refThicknessField: React.RefObject<HTMLElement>;
  isLocked?: boolean;
};

const config: CreateFilterOptionsConfig<ReferenceLayer> = {
  stringify: (option: ReferenceLayer) => option.name,
};
const filter = createFilterOptions<ReferenceLayer>(config);

export function CoreLayerDetails(props: CoreDetailsProps) {
  const {
    masterData,
    layerThermalResistance,
    clearAllDetails,
    apiBridgeResponse,
    masterLayer,
    setMasterLayer,
    layerThickness,
    setLayerThickness,
    shortCode,
    setShortCode,
    hasShortCodeError,
    setHasShortCodeError,
    setLayerFromShortCode,
    customReferenceLayer,
    setCustomReferenceLayer,
    updateCustomReferenceLayer,
    refShortCodeField,
    refThicknessField,
    isLocked,
  } = props;

  const getCustomLayerType = (referenceLayer: CustomReferenceLayer): CustomLayerTypeOptions => {
    if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation) {
      return CustomLayerTypeOptions.Insulation;
    }

    if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.NonInsulation) {
      return CustomLayerTypeOptions.NonInsulation;
    }

    if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation) {
      return CustomLayerTypeOptions.InvertedInsulation;
    }

    if (referenceLayer.isBlank) {
      return CustomLayerTypeOptions.Blank;
    }

    return CustomLayerTypeOptions.Blank;
  };

  const shouldShowThermalResistance = (referenceLayer: CustomReferenceLayer): boolean => {
    if (isStringNotNullishOrWhitespace(referenceLayer.thermalResistance)) {
      return true;
    }

    if (
      !isStringNotNullishOrWhitespace(referenceLayer.thermalResistance) &&
      !isStringNotNullishOrWhitespace(referenceLayer.thermalConductivity)
    ) {
      return true;
    }

    return false;
  };

  const shouldShowThermalConductivity = (referenceLayer: CustomReferenceLayer): boolean => {
    if (!isStringNotNullishOrWhitespace(referenceLayer.thermalResistance)) {
      return true;
    }

    if (
      !isStringNotNullishOrWhitespace(referenceLayer.thermalResistance) &&
      !isStringNotNullishOrWhitespace(referenceLayer.thermalConductivity)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <InputLabel htmlFor="modal-layer-short-code" data-qa-id="shortCodeLabel">
            Short Code
          </InputLabel>

          <TextField
            id="modal-layer-short-code"
            data-qa-id="shortCodeInput"
            variant="outlined"
            value={shortCode || ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShortCode(event.target.value);

              setHasShortCodeError(false);
            }}
            onBlur={() => {
              setShortCode('');

              setHasShortCodeError(false);
            }}
            onKeyDown={event => {
              // If "Enter" key
              if (event.keyCode === 13) {
                event.preventDefault();

                setLayerFromShortCode(shortCode || '', apiBridgeResponse);
              }
            }}
            InputProps={{
              ref: refShortCodeField,
            }}
            error={hasShortCodeError}
            helperText={hasShortCodeError ? 'Material not found' : 'Hit enter to search'}
            disabled={isLocked}
          />
        </Grid>

        <Grid item xs={4}>
          <InputLabel htmlFor="modal-layer-name" data-qa-id="layerNameLabel">
            Layer
          </InputLabel>

          <Autocomplete
            id="modal-layer-name"
            data-qa-id="layerNameInput"
            options={masterData.layers.filter(layer => layer.isActive !== false) as ReferenceLayer[]}
            autoHighlight
            getOptionLabel={(option: ReferenceLayer) => option.name}
            onChange={(_event: ChangeEvent<{}>, layer: ReferenceLayer | null) => {
              if (!layer) {
                clearAllDetails();
                return;
              }

              if (layer.isCustom) {
                if (customReferenceLayer == null) {
                  setCustomReferenceLayer(layer);
                } else {
                  updateCustomReferenceLayer({ name: layer.name });
                }
              } else {
                setMasterLayer(layer, apiBridgeResponse);
              }
            }}
            value={customReferenceLayer ?? masterLayer}
            disabled={isLocked}
            forcePopupIcon
            freeSolo
            filterOptions={(options, params) => {
              const filtered = filter(options, params) as ReferenceLayer[];
              if (params.inputValue !== '') {
                const customLayerOption: CustomReferenceLayer =
                  customReferenceLayer != null
                    ? {
                        ...customReferenceLayer,
                        name: params.inputValue,
                      }
                    : {
                        ...customLayerDefaults,
                        name: params.inputValue,
                      };

                filtered.push(customLayerOption);
              }

              return filtered;
            }}
            renderOption={(option: ReferenceLayer) =>
              !option.isCustom ? (
                option.name
              ) : (
                <Grid container justify="space-between" alignItems="center">
                  <span data-qa-id="customLayerOptionName">{option.name}</span>
                  <Grid item>
                    <Button variant="outlined" data-qa-id="customLayerOptionButton" disabled={isLocked}>
                      {customReferenceLayer == null ? '+ Add Custom Layer' : 'Update Custom Layer Name'}
                    </Button>
                  </Grid>
                </Grid>
              )
            }
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Browse catalogue..."
                variant="outlined"
                autoComplete="off" // Disable browser autocomplete and autofill
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          {!customReferenceLayer?.isBlank && (
            <React.Fragment>
              <InputLabel htmlFor="modal-layer-thickness" data-qa-id="layerThicknessLabel">
                Thickness
              </InputLabel>

              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={10}>
                  <TextField
                    id="modal-layer-thickness"
                    data-qa-id="layerThicknessInput"
                    placeholder="0.0"
                    variant="outlined"
                    value={layerThickness || ''}
                    disabled={isLocked}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setLayerThickness(event.target.value)}
                    InputProps={{
                      ref: refThicknessField,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <div className={styles.inputUnit} data-qa-id="layerThicknessUnits">
                    mm
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={4}>
          <div className={styles.layerResult}>
            <div data-qa-id="layerThermalResistanceTitle" className={styles.layerResultTitle}>
              Layer Thermal Resistance
            </div>

            <div data-qa-id="layerThermalResistanceValue" className={styles.layerResultValue}>
              {layerThermalResistance ?? '0.000'}
            </div>

            <div data-qa-id="layerThermalResistanceUnit" className={styles.layerResultUnit}>
              m²K/W
            </div>
          </div>
        </Grid>
      </Grid>

      {customReferenceLayer == null ? null : (
        <div className={styles.customLayerDetailsWrapper} data-qa-id="customLayerDetailsWrapper">
          <div className={styles.customLayerDetails}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <InputLabel htmlFor="modal-custom-layer-type" data-qa-id="customlayerTypeLabel">
                  Custom Layer Type
                </InputLabel>

                <Select
                  fullWidth
                  data-qa-id="customLayerTypeInput"
                  id="modal-custom-layer-type"
                  value={getCustomLayerType(customReferenceLayer)}
                  disabled={isLocked}
                  onChange={(event: any) => {
                    const layerType = event.target.value;

                    if (layerType === CustomLayerTypeOptions.Blank) {
                      const capturedReferenceLayer = customReferenceLayer;

                      clearAllDetails();

                      updateCustomReferenceLayer({
                        ...customLayerDefaults,
                        isBlank: true,
                        layerMaterialType: null,
                        name: capturedReferenceLayer.name,
                      });
                    } else if (layerType === CustomLayerTypeOptions.Insulation) {
                      updateCustomReferenceLayer({
                        isBlank: false,
                        layerMaterialType: LayerMaterialTypeKeys.GenericInsulation,
                        thermalConductivity: customLayerDefaults.thermalConductivity,
                        thermalResistance: customLayerDefaults.thermalResistance,
                        insideEmissivity: '0.90',
                        outsideEmissivity: '0.90',
                      });
                    } else if (layerType === CustomLayerTypeOptions.InvertedInsulation) {
                      updateCustomReferenceLayer({
                        isBlank: false,
                        layerMaterialType: LayerMaterialTypeKeys.InvertedInsulation,
                        thermalConductivity: customLayerDefaults.thermalConductivity,
                        thermalResistance: customLayerDefaults.thermalResistance,
                        insideEmissivity: '0.90',
                        outsideEmissivity: '0.90',
                      });
                    } else {
                      updateCustomReferenceLayer({
                        isBlank: false,
                        layerMaterialType: LayerMaterialTypeKeys.NonInsulation,
                        thermalConductivity: customLayerDefaults.thermalConductivity,
                        thermalResistance: customLayerDefaults.thermalResistance,
                        insideEmissivity: '0.90',
                        outsideEmissivity: '0.90',
                      });
                    }
                  }}
                  variant="outlined"
                >
                  {customLayerTypeOptions.map(customLayerTypeOption => {
                    return (
                      <MenuItem key={customLayerTypeOption} value={customLayerTypeOption}>
                        {customLayerTypeOption}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              {!customReferenceLayer?.isBlank && (
                <Grid container item xs={9} spacing={3}>
                  {shouldShowThermalConductivity(customReferenceLayer) && (
                    <Grid item xs={3}>
                      <InputLabel htmlFor="modal-custom-layer-thermal-conductivity" data-qa-id="customLayerThermalConductivityLabel">
                        Thermal Conductivity
                      </InputLabel>

                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={8}>
                          <TextField
                            id="modal-custom-layer-thermal-conductivity"
                            data-qa-id="customLayerThermalConductivityInput"
                            placeholder="0.0"
                            variant="outlined"
                            className={styles.customLayerField}
                            value={customReferenceLayer.thermalConductivity || ''}
                            disabled={isLocked}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              updateCustomReferenceLayer({ thermalConductivity: event.target.value, thermalResistance: null })
                            }
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <div className={styles.inputUnit} data-qa-id="customLayerThermalConductivityUnits">
                            W/mk
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {shouldShowThermalResistance(customReferenceLayer) && (
                    <Grid item xs={3}>
                      <InputLabel htmlFor="modal-custom-layer-thermal-resistance" data-qa-id="customLayerThermalResistanceLabel">
                        Thermal Resistance
                      </InputLabel>

                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={8}>
                          <TextField
                            id="modal-custom-layer-thermal-resistance"
                            data-qa-id="customLayerThermalResistanceInput"
                            placeholder="0.0"
                            variant="outlined"
                            className={styles.customLayerField}
                            value={customReferenceLayer.thermalResistance || ''}
                            disabled={isLocked}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              updateCustomReferenceLayer({ thermalResistance: event.target.value, thermalConductivity: null })
                            }
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <div className={styles.inputUnit} data-qa-id="customLayerThermalResistanceUnits">
                            m²K/W
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <InputLabel htmlFor="modal-custom-layer-inside-emissivity" data-qa-id="customLayerInsideEmissivityLabel">
                      Inside Emissivity
                    </InputLabel>

                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          id="modal-custom-layer-inside-emissivity"
                          data-qa-id="customLayerInsideEmissivityInput"
                          placeholder="0.0"
                          variant="outlined"
                          className={styles.customLayerField}
                          value={customReferenceLayer.insideEmissivity}
                          disabled={isLocked}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            updateCustomReferenceLayer({ insideEmissivity: event.target.value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <InputLabel htmlFor="modal-custom-layer-outside-emissivity" data-qa-id="customLayerOutsideEmissivityLabel">
                      Outside Emissivity
                    </InputLabel>

                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          id="modal-custom-layer-outside-emissivity"
                          data-qa-id="customLayerOutsideEmissivityInput"
                          placeholder="0.0"
                          variant="outlined"
                          className={styles.customLayerField}
                          value={customReferenceLayer.outsideEmissivity}
                          disabled={isLocked}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            updateCustomReferenceLayer({ outsideEmissivity: event.target.value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
}
