import React, { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Alert } from '@material-ui/lab';

import { uploadMasterData } from '../../actions/masterDataUploadActions';
import { MasterDataType } from '../../types/store/masterDataUploadTypes';
import { userProfileSelector } from '../../store/selectors';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { BusinessUnitDropdown } from '../FormComponents/BusinessUnitDropdown/BusinessUnitDropdown';
import styles from '../MasterDataUploadModal/MasterDataUploadModal.module.scss';
import { AppDispatch } from '../../store/store';

type Props = {
  onClose: (forceWelcome?: boolean) => void;
};

enum UploadStatus {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export function MasterDataUploadModal({ onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const userProfile = useSelector(userProfileSelector);

  const [dataType, setDataType] = React.useState<MasterDataType>(MasterDataType.LAYERS);
  const [businessUnitId, setBusinessUnitId] = React.useState(userProfile?.primaryBusinessUnitId ?? -1);
  const [file, setFile] = React.useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = React.useState<UploadStatus | null>(null);
  const [errors, setErrors] = React.useState<string[] | null>(null);

  const handleDataTypeChange = ({ target: { value } }: React.ChangeEvent<any>) => {
    setDataType(value);
  };

  const onDrop = ([acceptedFile]: File[]) => {
    setFile(acceptedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: false,
    disabled: uploadStatus === UploadStatus.PENDING,
  });

  const handleSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (dataType && file) {
        try {
          setUploadStatus(UploadStatus.PENDING);

          await dispatch(uploadMasterData(dataType, file, businessUnitId));

          setUploadStatus(UploadStatus.SUCCESS);
          setFile(null);
          setErrors(null);
        } catch (e) {
          setUploadStatus(UploadStatus.ERROR);
          setFile(null);

          const responseData = (e as AxiosError<unknown | string[] | null>)?.response?.data;
          const errorMessages = Array.isArray(responseData) ? responseData as string[] : null;
          console.error('uploadMasterData failed', errorMessages);
          setErrors(errorMessages);
        }
      }
    },
    [dispatch, dataType, file, businessUnitId]
  );

  return (
    <Dialog
      open
      onClose={() => onClose}
      aria-labelledby="modal-master-data-upload-title"
      disableBackdropClick={uploadStatus === UploadStatus.PENDING}
      disableEscapeKeyDown={uploadStatus === UploadStatus.PENDING}
    >
      <form data-qa-id="newMasterDataUploadModalForm" onSubmit={handleSubmit} noValidate>
        <div className={styles.modalHeader}>
          <DialogTitle id="modal-master-data-upload-title">
            <Typography component="span" variant="h5">Upload Master Data</Typography>
          </DialogTitle>
        </div>

        <div className={styles.modalContent}>
          <DialogContent>
            {uploadStatus === UploadStatus.ERROR && !file && (
              <Alert severity="error" className={styles.alert}>
                An error has occurred, please try again.
                {errors && errors.length > 0 && <ul>
                  {errors.map(error => <li>{error}</li>)}
                </ul>}
              </Alert>
            )}

            {uploadStatus === UploadStatus.SUCCESS && !file && (
              <Alert severity="success" className={styles.alert}>
                The file has been uploaded. Please refresh the page to use the latest data or upload another dataset.
              </Alert>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BusinessUnitDropdown
                  businessUnits={userProfile?.administrableBusinessUnits}
                  businessUnitId={businessUnitId}
                  setBusinessUnitId={setBusinessUnitId}
                  disabled={uploadStatus === UploadStatus.PENDING}
                />
              </Grid>

              <Grid item xs={4}>
                <div className={styles.masterDataTypeContainer}>
                  <InputLabel data-qa-id="masterDataTypeLabel" id="master-data-type-label">
                    Master Data Type
                  </InputLabel>

                  <Select
                    fullWidth
                    data-qa-id="masterDataTypeInput"
                    id="master-data-type-input"
                    value={dataType}
                    onChange={handleDataTypeChange}
                    variant="outlined"
                    disabled={uploadStatus === UploadStatus.PENDING}
                  >
                    {Object.values(MasterDataType).map(masterDataType => {
                      return (
                        <MenuItem key={masterDataType} value={masterDataType}>
                          {masterDataType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </Grid>

              <Grid item xs={8}>
                <div className={styles.dropzoneContainer}>
                  <div {...getRootProps()} className={styles.dropzone} data-qa-id="masterDataFileInputContainer">
                    <input {...getInputProps()} data-qa-id="masterDataFileInput" />
                    {!file &&
                      (isDragActive ? (
                        <span>Drop the file here ...</span>
                      ) : (
                        <span data-qa-id="masterDataMessageWithoutFile">Drop a file here, or click to select a file</span>
                      ))}

                    {file && (
                      <div>
                        <DescriptionOutlinedIcon className={styles.fileIcon} />
                        <span data-qa-id="masterDataMessageWithFile">
                          <span className={styles.fileName}>{file.name}&nbsp;</span>
                          {uploadStatus === UploadStatus.PENDING ? 'is uploading...' : 'is ready to be uploaded'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </div>

        <div className={styles.modalActions}>
          <DialogActions>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button
                  data-qa-id="masterDataUploadCloseButton"
                  onClick={() => onClose(true)}
                  variant="outlined"
                  disabled={uploadStatus === UploadStatus.PENDING}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  data-qa-id="masterDataUploadSubmitButton"
                  fullWidth
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={!dataType || !file || uploadStatus === UploadStatus.PENDING || businessUnitId < 0}
                  className={styles.uploadButton}
                >
                  {uploadStatus === UploadStatus.PENDING ? <CircularProgress size={28} /> : 'Upload'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
}
