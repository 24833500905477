import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { DebouncedDecimalInput } from '../FormComponents/DebouncedDecimalInput/DebouncedDecimalInput';
import { TabPanel } from './TabPanel';
import { TabIndexInfo } from '../../types/components/tabTypes';
import styles from './LayerModal.module.scss';

export type CoreVapourTabProps = {
  setVapourResistances: (vapourResistance: string | null, vapourResistivity: string | null) => void;
  vapourResistance: string | null;
  vapourResistivity: string | null;
  isLocked?: boolean;
};

export type VapourTabProps = CoreVapourTabProps & TabIndexInfo;

export function VapourTab(props: VapourTabProps) {
  const { targetIndex, currentTabIndex, setVapourResistances, vapourResistance, vapourResistivity, isLocked } = props;

  const onVapourResistanceChange = (value: string) => setVapourResistances(value, value === '' ? vapourResistivity : null);

  const onVapourResistivityChange = (value: string) => setVapourResistances(value === '' ? vapourResistance : null, value);

  return (
    <TabPanel
      id="layer-vapour-panel"
      data-qa-id="layer-vapour-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="layer-vapour-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <InputLabel htmlFor="modal-layer-vapour-resistance" data-qa-id="layerVapourResistanceLabel">
              Vapour Resistance
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <DebouncedDecimalInput
                  data-qa-id="layerVapourResistanceInput"
                  delay={400}
                  onChange={onVapourResistanceChange}
                  precision={3}
                  textFieldId="modal-layer-vapour-resistance"
                  value={vapourResistance || ''}
                  disabled={isLocked}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerVapourResistanceUnits">
                  MNs/g
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="modal-layer-vapour-resistivity" data-qa-id="layerVapourResistivityLabel">
              Vapour Resistivity
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <DebouncedDecimalInput
                  data-qa-id="layerVapourResistivityInput"
                  delay={400}
                  onChange={onVapourResistivityChange}
                  precision={3}
                  textFieldId="modal-layer-vapour-resistivity"
                  value={vapourResistivity || ''}
                  disabled={isLocked}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerVapourResistivityUnits">
                  MNs/gm
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={styles.tabContentResults}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <div className={styles.tabContentResult}></div>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
