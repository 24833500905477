import { callApi } from '../common/api';
import { ApiUserProfile, FetchUserProfileAction } from '../types/store/userProfileTypes';
import { actionTypes } from './actionTypes';

export const fetchUserProfile = () => {
  return async (dispatch: (action: FetchUserProfileAction) => void) => {
    const data = await callApi<ApiUserProfile>(dispatch, 'GET', '/Profile');

    if (!data) return;

    dispatch({
      type: actionTypes.FETCH_USER_PROFILE,
      payload: {
        userProfile: data,
      },
    });
  };
};
