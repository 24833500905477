import React from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { BusinessUnitModel } from '../../../types/store/userProfileTypes';

type Props = {
  businessUnits: BusinessUnitModel[] | null | undefined;
  businessUnitId: number;
  setBusinessUnitId: (businessUnitId: number) => void;
  disabled?: boolean;
};

export function BusinessUnitDropdown(props: Props) {
  return (<>
    <InputLabel>
      Business Unit
    </InputLabel>

    <Select
      fullWidth
      id="business-unit-id"
      value={props.businessUnitId}
      onChange={({ target: { value } }: React.ChangeEvent<any>) => props.setBusinessUnitId(value)}
      variant="outlined"
      disabled={props.disabled}
    >
      {(props.businessUnits ?? []).map(businessUnit => {
        return (
          <MenuItem key={businessUnit.businessUnitId} value={businessUnit.businessUnitId}>
            {businessUnit.name}
            <Typography variant='body2' display='inline' color='textSecondary'>
              &nbsp;- Business Unit {businessUnit.businessUnitId} - {businessUnit.countryName}
            </Typography>
          </MenuItem>
        );
      })}
    </Select>
  </>);
}
