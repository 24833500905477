import React, { FormEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

import styles from './CondensationRiskModal.module.scss';
import { CondensationRiskTabs } from './CondensationRiskTabs';
import { useDispatch, useSelector } from 'react-redux';
import { clearInterimCalculation, saveEnvConditions } from '../../actions/calculationActions';
import { Calculation } from '../../types/store/calculationTypes';
import { interimOrCurrentCalculationSelector } from '../../store/selectors';

type Props = {
  onClose: () => void;
};

export function CondensationRiskModal(props: Props) {
  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = React.useState(true);
  const calculation: Calculation | null = useSelector(interimOrCurrentCalculationSelector);

  const handleDialogCancel = React.useCallback(() => {
    dispatch(clearInterimCalculation());
    props.onClose();
  }, [dispatch, props]);

  const handleSaveEnvConditions = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      dispatch(saveEnvConditions());
      props.onClose();
    },
    [dispatch, props]
  );

  return (
    <Dialog open onClose={handleDialogCancel} scroll="paper" aria-labelledby="condensation-risk-title">
      <div className={styles.modalHeader}>
        <DialogTitle id="condensation-risk-title">
          <Typography component="span" variant="h5">
            Condensation Risk
          </Typography>
        </DialogTitle>
      </div>

      <div className={styles.modalContent}>
        <DialogContent dividers={true}>
          <CondensationRiskTabs setIsInvalid={setIsInvalid} isLocked={calculation?.locked} />
        </DialogContent>
      </div>

      <div className={styles.modalActions}>
        <DialogActions>
          <form data-qa-id="condensationRiskModalForm" onSubmit={handleSaveEnvConditions} noValidate>
            <Grid container spacing={2} justify="flex-end">
              <Grid container item xs={2} justify="flex-end">
                <Button data-qa-id="envConditionsCloseButton" onClick={handleDialogCancel} variant="outlined">
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  data-qa-id="envConditionsSubmitButton"
                  disabled={calculation?.locked || isInvalid}
                  fullWidth
                  onClick={handleSaveEnvConditions}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogActions>
      </div>
    </Dialog>
  );
}
