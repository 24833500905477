import React from 'react';

import { LandingPage } from '../../LandingPage/LandingPage';

export const UnauthenticatedLandingPage = () => (
  <LandingPage
    title="Authentication Failed"
    content="Please retry or contact your system administrator."
    buttonText="Retry"
    onButtonClick={() => window.location.reload()}
    qaIdBase="landingPageAuthFailed"
  />
);
