import {
  FloorInsulationLayerReferenceType,
  ConstructionRainscreenCladdingDetails,
  CalculationNotes,
  ConstructionPitchedRoofWithLoftDetails,
} from '../../types/store/calculationTypes';

import { MasterDataLayer, MasterDataDrainageFx, MasterDataPrecipitationByPostCodeArea } from '../../types/store/masterDataTypes';

export type NoFloorReferenceOption = {
  id: null;
  type: FloorInsulationLayerReferenceType.None;
  name: 'None';
};

export type ClientSpecifiedFloorReferenceOption = {
  id: null;
  type: FloorInsulationLayerReferenceType.Custom;
  name: 'Client Specified';
};

export const noFloorReferenceOption: NoFloorReferenceOption = {
  id: null,
  type: FloorInsulationLayerReferenceType.None,
  name: 'None',
};

export const clientSpecifiedFloorReferenceOption: ClientSpecifiedFloorReferenceOption = {
  id: null,
  type: FloorInsulationLayerReferenceType.Custom,
  name: 'Client Specified',
};

export type FloorReferenceOption = MasterDataLayer | NoFloorReferenceOption | ClientSpecifiedFloorReferenceOption;

export type ConstructionModalState = {
  selectedTab: number | false;
  applicationTypeId: number;
  perimeterMetres: string | null;
  areaMetresSquared: string | null;
  perimeterAreaRatio: string | null;
  wallThicknessMetres: string | null;
  ventilationRateMillimetresSquaredPerMetre: string | null;
  thermalTransmittanceWallsInUnderFloorSpaceAboveGround: string | null;
  windSpeedMetresPerSecond: string | null;
  averageWindShieldingFactor: string | null;
  heightBelowGroundMetres: string | null;
  soilTypeId: number | null;
  floorInsulationLayer: FloorReferenceOption | null;
  floorInsulationReferenceType: FloorInsulationLayerReferenceType | null;
  floorInsulationThermalConductivity: string | null;
  floorInsulationLayerThickness: string | null;
  floorInsulationLayerInstanceId: string | null;
  isWarmSteelFramedWall: boolean | null;
  is50To80MillimetreFlange: boolean | null;
  studDepthMillimetres: string | null;
  studSpacingMillimetres: string | null;
  drainageFx: MasterDataDrainageFx | null;
  precipitationForArea: MasterDataPrecipitationByPostCodeArea | null;
  pitchAngle: string | null;
  pitchedRoofWithLoftDetails?: ConstructionPitchedRoofWithLoftDetails;
  rainscreenCladdingDetailsPartial?: Partial<ConstructionRainscreenCladdingDetails>;
  calculationNotes: CalculationNotes;
  widthOfEdgeInsulation: string | null;
  productSectorId: number;
  projectInsulationVolumeM2: number;
};
