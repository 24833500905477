import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { GridJustification } from '@material-ui/core/Grid/Grid';

import styles from './VerticalAlign.module.scss';

type Props = {
  children: ReactNode;
  align: 'top' | 'center' | 'bottom';
};

export function VerticalAlign({ align, children }: Props) {
  let justifications: { [alignment: string]: GridJustification } = {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end',
  };

  return (
    <Grid container direction="column" justify={justifications[align]} className={styles.fullHeight}>
      {children}
    </Grid>
  );
}
