import { ApiState, ApiAction } from '../types/store/apiTypes';
import { actionTypes } from '../actions/actionTypes';

const initialState: ApiState = {
  isFirstCallSuccessful: false,
  isAuthenticated: true,
  isAuthorised: true,
};

export const apiReducer = (state: ApiState = initialState, action: ApiAction) => {
  switch (action.type) {
    case actionTypes.API_CALL_SUCCESSFUL:
      return {
        isFirstCallSuccessful: true, // Set to true on successful call to create better experience when first visiting the application
        isAuthenticated: true,
        isAuthorised: true,
      };
    case actionTypes.SET_UNAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
        isAuthorised: false,
      };
    case actionTypes.SET_UNAUTHORISED:
      return {
        ...state,
        isAuthenticated: true,
        isAuthorised: false,
      };
    default:
      return state;
  }
};
