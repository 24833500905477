import React from 'react';
import { Grid, InputLabel, Button, ButtonGroup, FormHelperText } from '@material-ui/core';
import { TabPanel } from './TabPanel';
import { TabIndexInfo } from '../../types/components/tabTypes';
import { MasterDataAirGapsCorrectionLevel, AirGapsCorrectionLevel, MasterData } from '../../types/store/masterDataTypes';
import styles from './LayerModal.module.scss';
import { ReferenceLayer } from '../../types/components/layerSelectionTypes';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';

export type CoreAirGapsTabProps = {
  masterData: MasterData;
  referenceLayer: ReferenceLayer | null;
  airGapsCorrectionLevel?: AirGapsCorrectionLevel;
  airGapsCorrectionLevelOverride?: AirGapsCorrectionLevel;
  setAirGapsCorrectionLevelOverride: (airGapsCorrectionLevelOverride: AirGapsCorrectionLevel | undefined) => void;
  insideEmissivity?: string;
  outsideEmissivity?: string;
  airGapsCorrectionResultValue?: string;
  totalAirGapsCorrectionFactor?: string;
  isLocked?: boolean;
};

export type AirGapsTabProps = CoreAirGapsTabProps & TabIndexInfo;

export function AirGapsTab(props: AirGapsTabProps) {
  const {
    masterData,
    referenceLayer,
    airGapsCorrectionLevel,
    airGapsCorrectionLevelOverride,
    setAirGapsCorrectionLevelOverride,
    insideEmissivity,
    outsideEmissivity,
    airGapsCorrectionResultValue,
    totalAirGapsCorrectionFactor,
    targetIndex,
    currentTabIndex,
    isLocked,
  } = props;

  const isSelected = (masterDataAirGapsCorrectionLevel?: MasterDataAirGapsCorrectionLevel): boolean => {
    if (masterDataAirGapsCorrectionLevel?.level === AirGapsCorrectionLevel.Level0 && !airGapsCorrectionLevel) {
      return true;
    }

    return masterDataAirGapsCorrectionLevel?.level === airGapsCorrectionLevel;
  };

  const shouldDisableAirgaps =
    !referenceLayer ||
    !(
      referenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
      referenceLayer.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation
    );

  return (
    <TabPanel
      id="layer-air-gaps-correction-details-panel"
      data-qa-id="layer-air-gaps-correction-details-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="layer-air-gaps-correction-details-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel data-qa-id="layerAirGapsCorrectionLabel">Air Gaps Correction Level</InputLabel>

            <div className={styles.relative}>
              <ButtonGroup
                size="large"
                variant="contained"
                className={styles.multiButtonContainer}
                aria-label="air-gaps-correction-button-group"
                disabled={isLocked || shouldDisableAirgaps}
                title={shouldDisableAirgaps ? 'An air gaps correction can only be applied to an insulation layer' : ''}
              >
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  className={isSelected(masterData.airGapsCorrectionLevels?.level0) ? styles.multiButtonSelected : ''}
                  onClick={() => setAirGapsCorrectionLevelOverride(masterData.airGapsCorrectionLevels?.level0.level)}
                  data-qa-id="layerAirGapsCorrectionLevel0Button"
                >
                  0
                </Button>
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  className={isSelected(masterData.airGapsCorrectionLevels?.level1) ? styles.multiButtonSelected : ''}
                  onClick={() => setAirGapsCorrectionLevelOverride(masterData.airGapsCorrectionLevels?.level1.level)}
                  data-qa-id="layerAirGapsCorrectionLevel1Button"
                >
                  1
                </Button>
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  className={isSelected(masterData.airGapsCorrectionLevels?.level2) ? styles.multiButtonSelected : ''}
                  onClick={() => setAirGapsCorrectionLevelOverride(masterData.airGapsCorrectionLevels?.level2.level)}
                  data-qa-id="layerAirGapsCorrectionLevel2Button"
                >
                  2
                </Button>
              </ButtonGroup>

              {airGapsCorrectionLevelOverride && <FormHelperText>This property has been manually overridden</FormHelperText>}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.tabContentResults}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="layerAirGapsCorrectionFactorLabel">
                  Layer Air Gaps Correction Factor
                </Grid>

                <Grid item xs={4} data-qa-id="layerAirGapsCorrectionFactor">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="layerAirGapsCorrectionFactorValue">
                      {airGapsCorrectionResultValue ?? '0.000000'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="totalAirGapsCorrectionFactorLabel">
                  Total Air Gaps Correction Factor
                </Grid>

                <Grid item xs={4} data-qa-id="totalAirGapsCorrectionFactor">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="totalAirGapsCorrectionFactorValue">
                      {totalAirGapsCorrectionFactor ?? '0.000000'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="insideSurfaceEmissivityLabel">
                  Inside Surface Emissivity
                </Grid>

                <Grid item xs={4} data-qa-id="insideSurfaceEmissivity">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="insideSurfaceEmissivityValue">
                      {insideEmissivity}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="outsideSurfaceEmissivityLabel">
                  Outside Surface Emissivity
                </Grid>

                <Grid item xs={4} data-qa-id="outsideSurfaceEmissivity">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="outsideSurfaceEmissivityValue">
                      {outsideEmissivity}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
