import { Calculation, CalculationAction, CalculationState } from '../types/store/calculationTypes';
import { actionTypes } from '../actions/actionTypes';

const initialState: CalculationState = {
  currentCalculation: null,
  interimCalculation: null,
  savedSuccessfully: null,
};

export const calculationReducer = (state: CalculationState = initialState, action: CalculationAction): CalculationState => {
  switch (action.type) {
    case actionTypes.CHANGE_ACTIVE_CALCULATION:
      return {
        ...state,
        currentCalculation: action.payload.newActiveCalculation,
        interimCalculation: null,
      };
    case actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE:
      return {
        ...state,
        currentCalculation: null,
        interimCalculation: null,
      };
    case actionTypes.REGISTER_CALCULATION:
      return {
        ...state,
        currentCalculation: {
          ...action.payload.calculation,
          order: action.payload.order,
        },
        interimCalculation: null,
      };
    case actionTypes.CLOSE_ACTIVE_CALCULATION:
      return {
        ...state,
        currentCalculation: action.payload.newActiveCalculation || null,
        interimCalculation: null,
      };
    case actionTypes.SET_ACTIVE_CALCULATION_DATA:
      return {
        ...state,
        currentCalculation: {
          ...action.payload.newCalculationData,
          calculationId: action.payload.shouldUseExistingCalculationId
             ? action.payload.newCalculationData?.calculationId
             : state.currentCalculation?.calculationId,
          order: action.payload.order,
        } as Calculation,
        interimCalculation: null,
      };
    case actionTypes.LOCK_CALCULATION:
      if (action.payload.isCurrentCalculation) {
        return {
          ...state,
          currentCalculation: {
            ...state.currentCalculation,
            ...action.payload.calculation,
          } as Calculation,
          // Explicitly clearing interim calculation when current calculation is updated
          interimCalculation: null,
        };
      } else {
        return state;
      }
    case actionTypes.ADD_LAYER_TO_CALCULATION:
    case actionTypes.EDIT_LAYER_IN_CALCULATION:
    case actionTypes.INTERIM_MOVE_LAYER_IN_CALCULATION:
    case actionTypes.MOVE_LAYER_IN_CALCULATION:
    case actionTypes.DUPLICATE_LAYER_IN_CALCULATION:
    case actionTypes.REMOVE_LAYER_FROM_CALCULATION:
    case actionTypes.ADD_CONSTRUCTION_DETAILS_TO_CALCULATION:
      return {
        ...state,
        currentCalculation: action.payload.calculation,
        // Explicitly clearing interim calculation when current calculation is updated
        interimCalculation: null,
      };
    case actionTypes.ADD_LAYER_TO_INTERIM_CALCULATION:
    case actionTypes.EDIT_LAYER_IN_INTERIM_CALCULATION:
      return {
        ...state,
        interimCalculation: action.payload.calculation,
      };
    case actionTypes.CLEAR_INTERIM_CALCULATION:
      return {
        ...state,
        interimCalculation: null,
      };
    case actionTypes.UPDATE_CALCULATION_PROJECT:
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          project: action.payload.project,
        } as Calculation,
        interimCalculation: null,
      };
    case actionTypes.UPDATE_CALCULATION_CONTACT:
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          contact: action.payload.contact,
        } as Calculation,
        interimCalculation: null,
      };
    case actionTypes.EDIT_ENV_CONDITIONS:
    case actionTypes.EDIT_ENV_SETTINGS:
      return {
        ...state,
        interimCalculation: action.payload.calculation,
      };
    case actionTypes.SAVE_ENV_CONDITIONS:
      return {
        ...state,
        currentCalculation: action.payload.calculation,
        interimCalculation: null,
      };
    case actionTypes.SET_CALCULATION_SAVE_SUCCESS:
      return {
        ...state,
        savedSuccessfully: true,
      };
    case actionTypes.CLEAR_CALCULATION_SAVE_SUCCESS:
      return {
        ...state,
        savedSuccessfully: null,
      };
    default:
      return state;
  }
};
