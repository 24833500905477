import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { AuthenticationState, AzureAD, IAzureADFunctionProps } from 'react-aad-msal';
import { SnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Layout } from '../Layout/Layout';
import { CalculationScreen } from '../Screens/CalculationScreen/CalculationScreen';
import { store } from '../../store/store';
import { theme } from '../../theme/theme';
import { authProvider } from '../../auth/authProvider';
import { UnauthenticatedLandingPage } from '../LandingPages/UnauthenticatedLandingPage/UnauthenticatedLandingPage';
import { AuthorisingLandingPage } from '../LandingPages/AuthorisingLandingPage/AuthorisingLandingPage';
import styles from './App.module.scss';

export function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        maxSnack={1}
        classes={{ containerRoot: styles.snackbarRoot, anchorOriginBottomCenter: styles.snackbar }}
      >
        <AzureAD provider={authProvider} reduxStore={store} forceLogin>
          {({ authenticationState }: IAzureADFunctionProps) => (
            <ThemeProvider theme={theme}>
              <CssBaseline />

              {/**
               * "InProgress" state covers the scenario where the user isn't
               * explicitly authenticated or unauthenticated but instead in limbo.
               */
              authenticationState === AuthenticationState.InProgress && <AuthorisingLandingPage />}

              {/**
               * "Unauthenticated" state covers the scenario where the user has returned
               * from the Microsoft portal without successfully authenticating.
               */
              authenticationState === AuthenticationState.Unauthenticated && <UnauthenticatedLandingPage />}

              {/**
               * "Authenticated" state covers the scenario where the user has returned
               * from the Microsoft portal having successfully authenticating. However,
               * we may still show an error landing pages from within the Layout
               * component if the API throws authentication or authorisation errors.
               */
              authenticationState === AuthenticationState.Authenticated && (
                <Layout>
                  <BrowserRouter>
                    <Switch>
                      <Route path="/calculation">
                        <CalculationScreen />
                      </Route>

                      <Redirect to="/calculation" />
                    </Switch>
                  </BrowserRouter>
                </Layout>
              )}
            </ThemeProvider>
          )}
        </AzureAD>
      </SnackbarProvider>
      <ToastContainer />
    </Provider>
  );
}
