import React from 'react';

/**
 * Returns the value from the previous render
 * Can be used inside a useEffect to help determine which of the dependencies has changed
 *
 * @param value The value to track
 */
export const usePrevious = function<T>(value: T): T | undefined {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
