import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import styles from './CondensationRiskModal.module.scss';
import { CoreEnvConditionsTabProps, EnvConditionsTab } from './EnvConditionsTab';
import { CondensationResultsTab } from './CondensationResultsTab';

export type CondensationRiskTabsProps = CoreEnvConditionsTabProps;

const envConditionsTabIndex = 0;
const condensationResultsTabIndex = 1;

export function CondensationRiskTabs(props: CondensationRiskTabsProps) {
  const [tabIndex, setTabIndex] = React.useState<number | false>(envConditionsTabIndex);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  return (
    <React.Fragment>
      <Tabs
        className={styles.tabs}
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="condensation-risk-tabs"
        data-qa-id="condensation-risk-tabs"
      >
        <Tab
          disableRipple
          className={styles.tab}
          label="Env Conditions"
          id="env-conditions-tab"
          aria-controls="env-conditions-panel"
          data-qa-id="env-conditions-tab"
        />
        <Tab
          disableRipple
          className={styles.tab}
          label="Condensation Results"
          id="condensation-results-tab"
          aria-controls="condensation-results-panel"
          data-qa-id="condensation-results-tab"
        />
      </Tabs>

      {tabIndex !== false && (
        <div className={styles.tabbedContentContainer} data-qa-id="tab-panels-container">
          <EnvConditionsTab targetIndex={envConditionsTabIndex} currentTabIndex={tabIndex} {...props} />
          <CondensationResultsTab targetIndex={condensationResultsTabIndex} currentTabIndex={tabIndex} {...props} />
        </div>
      )}
    </React.Fragment>
  );
}
