import { AppConfig } from '../types/config/configTypes';

let appConfig: AppConfig = {
  apiUrl: '',
};

export function setAppConfig(newAppConfig: AppConfig) {
  appConfig = newAppConfig;
}

export function getAppConfig() {
  return appConfig;
}
