import { createSelector } from 'reselect';
import { ApiState } from '../types/store/apiTypes';
import { RootState } from './store';
import { Calculation, EnvCondition, EnvSettings, InterstitialInterface } from '../types/store/calculationTypes';
import { InactiveCalculationsState } from '../types/store/inactiveCalculationsTypes';
import {
  MasterDataApplicationType,
  MasterDataBuildingRegion,
  MasterDataBuildingType,
  MasterDataRiskLevel,
  MasterDataSoilType,
  MasterDataLayer,
  MasterRainscreenCladdingDefaults,
  MasterDataDrainageFx,
  MasterDataPrecipitationByPostCodeArea,
  MasterDataBuildingUsage,
  MasterDataProjectRegion,
  MasterDataBranding,
  MasterDataRoofCharacteristics,
  MasterData,
  MasterDataCalculationTitle,
  MasterDataProductSector,
  MasterDataSalesContactTeam,
  MasterDataProjectCountyState,
  MasterDataProjectStage,
} from '../types/store/masterDataTypes';
import { UserProfileState } from '../types/store/userProfileTypes';
import { ApiResponseExportPdf } from '../types/store/pdfTypes';

export function interimOrCurrentCalculationSelector(state: RootState): Calculation | null {
  return state.calculation.interimCalculation ?? state.calculation.currentCalculation;
}

export function currentCalculationBusinessUnitIdSelector(state: RootState): number | null {
  return state.calculation?.currentCalculation?.businessUnitId ?? null;
}

export function inactiveCalculationsByBusinessUnitSelector(state: RootState): InactiveCalculationsState {
  const currentBusinessUnitId = currentCalculationBusinessUnitIdSelector(state);
  return state.inactiveCalculations.filter(ic => ic.businessUnitId === currentBusinessUnitId);
}

export function inactiveCalculationsSelector(state: RootState): InactiveCalculationsState {
  return state.inactiveCalculations;
}

export function masterDataSelector(state: RootState): MasterData {
  return state.masterData;
}

export function isLoadingMasterDataSelector(state: RootState): boolean {
  return state.masterData.isLoadingMasterData === true;
}

export function floorMasterLayerSelector(state: RootState): MasterDataLayer[] {
  return state.masterData.floorLayers;
}

export function applicationTypeSelector(state: RootState): MasterDataApplicationType[] {
  return state.masterData.applications;
}

export function soilTypeSelector(state: RootState): MasterDataSoilType[] {
  return state.masterData.soilTypes;
}

export function buildingRegionSelector(state: RootState): MasterDataBuildingRegion[] {
  return state.masterData.buildingRegions;
}

export function buildingTypeSelector(state: RootState): MasterDataBuildingType[] {
  return state.masterData.buildingTypes;
}

export function riskLevelSelector(state: RootState): MasterDataRiskLevel[] {
  return state.masterData.riskLevels;
}

export function rainscreenCladdingDefaultsSelector(state: RootState): MasterRainscreenCladdingDefaults | undefined {
  return state.masterData.rainscreenCladdingDefaults;
}

export function drainageFxSelector(state: RootState): MasterDataDrainageFx[] {
  return state.masterData.drainageFx;
}

export function precipitationByPostCodeAreaSelector(state: RootState): MasterDataPrecipitationByPostCodeArea[] {
  return state.masterData.precipitationByPostCodeArea;
}

export function buildingUsagesSelector(state: RootState): MasterDataBuildingUsage[] {
  return state.masterData.buildingUsage;
}

export function projectRegionsSelector(state: RootState): MasterDataProjectRegion[] {
  return state.masterData.projectRegion;
}

export function roofCharacteristicsOptionsSelector(state: RootState): MasterDataRoofCharacteristics[] {
  return state.masterData.roofCharacteristicsOptions;
}

export function envConditionsSelector(state: RootState): EnvCondition[] | undefined {
  return state.calculation.interimCalculation?.envConditions ?? state.calculation.currentCalculation?.envConditions;
}

export function envSettingsSelector(state: RootState): EnvSettings | undefined {
  return state.calculation.interimCalculation?.envSettings ?? state.calculation.currentCalculation?.envSettings;
}

export function interstitialInterfaceSelector(state: RootState): InterstitialInterface[] {
  return state.calculation.interimCalculation?.interstitialInterfaces ?? state.calculation.currentCalculation?.interstitialInterfaces ?? [];
}

export function masterDataBradingSelector(state: RootState): MasterDataBranding[] {
  return state.masterData.branding;
}

export function pdfExportsSelector(state: RootState): ApiResponseExportPdf[] {
  return state.pdf.exports;
}

export function pdfPendingDownloadsSelector(state: RootState): ApiResponseExportPdf[] {
  return state.pdf.pendingDownloads;
}

export function apiSelector(state: RootState): ApiState {
  return state.api;
}

export function productSectorSelector(state: RootState): MasterDataProductSector[] {
  return state.masterData.productSectors;
}

export function salesContactTeamSelector(state: RootState): MasterDataSalesContactTeam[] {
  return state.masterData.salesContactTeams;
}

export function projectCountyStateSelector(state: RootState): MasterDataProjectCountyState[] {
  return state.masterData.projectCountyStates;
}

export function projectStageSelector(state: RootState): MasterDataProjectStage[] {
  return state.masterData.projectStages;
}

export function calculationTitleSelector(state: RootState): MasterDataCalculationTitle[] {
  return state.masterData.calculationTitles
}

export function currentBuildingRegionNameSelector(state: RootState): string | undefined {
  var envSettings = envSettingsSelector(state);
  if (
    !envSettings ||
    !envSettings.buildingRegionId ||
    !envSettings.buildingTypeId ||
    !envSettings.internalTemperature ||
    !envSettings.riskLevelId
  ) {
    return undefined;
  }

  return state.masterData.buildingRegions.filter(br => br.id === envSettingsSelector(state)?.buildingRegionId)[0]?.name;
}

export function saveSuccessSelector(state: RootState): boolean | null {
  return state.calculation.savedSuccessfully;
}

export function userProfileSelector(state: RootState): UserProfileState {
  return state.userProfile;
}

export const currentCalculationBusinessUnitSelector = createSelector(
  [userProfileSelector, currentCalculationBusinessUnitIdSelector],
  (userProfile, currentCalculationBusinessUnitId) => {
    return (userProfile?.useableBusinessUnits ?? []).find(bu => bu.businessUnitId === currentCalculationBusinessUnitId) ?? null;
  });
