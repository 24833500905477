import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Add, FolderOpen, Settings } from '@material-ui/icons';
import React from 'react';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './WelcomeModal.module.scss';

import { UserProfileState } from '../../types/store/userProfileTypes';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '../../store/selectors';
import { checkIsPowerUser } from '../../common/userProfile';

type Props = {
    onCreateNewCalculationClick: () => void;
    onMasterDataUploadClick: () => void;
    onOpenCalculationClick: () => void;
};

export function WelcomeModal({ onCreateNewCalculationClick, onMasterDataUploadClick, onOpenCalculationClick }: Props) {

    const userProfile: UserProfileState = useSelector(userProfileSelector);
    const isPowerUser = checkIsPowerUser(userProfile);

    return (
        <Dialog
            open
            aria-labelledby="modal-welcome-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div className={modalStyles.modalHeader}>
                <DialogTitle id="modal-welcome-title">
                    <Typography component="span" variant="h5">Kingspan Insulation Calculation Services</Typography>
                </DialogTitle>
            </div>

            <div className={`${[modalStyles.modalContent, styles.modalContent].join(' ')}`}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={isPowerUser ? 4 : 6} data-qa-id="welcomeModalCreateGrid">
                            <Button
                                onClick={onCreateNewCalculationClick}
                                data-qa-id="welcomeModalCreateButton"
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                startIcon={<Add />}
                            >
                                Create
                            </Button>
                        </Grid>

                        <Grid item xs={isPowerUser ? 4 : 6} data-qa-id="welcomeModalOpenGrid">
                            <Button
                                onClick={onOpenCalculationClick}
                                data-qa-id="welcomeModalOpenButton"
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                startIcon={<FolderOpen />}
                            >
                                Open
                            </Button>
                        </Grid>

                        {isPowerUser && (
                            <Grid item xs={4} data-qa-id="welcomeModalUploadGrid">
                                <Button
                                    onClick={onMasterDataUploadClick}
                                    data-qa-id="welcomeModalUploadButton"
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    fullWidth
                                    startIcon={<Settings />}
                                >
                                    Upload Master Data
                                </Button>
                            </Grid>
                        )}

                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}
