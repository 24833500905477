import { actionTypes } from '../../actions/actionTypes';

export enum MasterDataType {
  BALLAST_TYPES = 'Ballast Types',
  BRANDING = 'Branding',
  BRIDGE_MATERIALS = 'Bridge Materials',
  ENV_CON_BUILDING_REGIONS = 'E.C. Building Regions',
  ENV_CON_HUMIDITY_CLASSES = 'E.C. Humidity Classes',
  ENV_CON_RISK_LEVELS = 'E.C. Risk Levels',
  LAYERS = 'Layers',
  MECHANICAL_FASTENERS = 'Mechanical Fasteners',
  PRECIPITATION = 'Precipitation',
  PROJECT_REGIONS = 'Project Regions',
  PROJECT_USAGES = 'Project Usages',
  RAINSCREEN_CLADDING = 'Rainscreen Cladding',
  SOIL_TYPES = 'Soil Types',
  SALES_CONTACT_TEAMS = 'Sales Contact Teams',
  PROJECT_COUNTY_STATE = 'Project County State',
  PROJECT_STAGE = 'Project Stage',
  PRODUCT_SECTOR = 'Product Sector',
  CALCULATION_TITLE = 'Calculation Title',
}

export type UploadMasterDataAction = {
  type: actionTypes.UPLOAD_MASTER_DATA;
  payload: {
    dataType: MasterDataType;
    file: File;
  };
};
