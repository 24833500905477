import React from 'react';
import { useDispatch } from 'react-redux';
import * as ReactDnd from 'react-dnd';
import { MTableBodyRow } from 'material-table';

import { moveLayerInCalculation } from '../../../actions/calculationActions';
import styles from '../LayersTable.module.scss';

type Props = {
  disableDrag: boolean;
  renderProps: any;
  onLayerClick: (instanceId: string, tabIndex?: number | undefined) => void;
};

export function LayersTableRow({ disableDrag, renderProps, onLayerClick }: Props) {
  const dispatch = useDispatch();

  const isInsideSurface = renderProps.data.name === 'Inside Surface';
  const isOutsideSurface = renderProps.data.name === 'Outside Surface';
  const isReadOnly = renderProps.data.isReadOnly;

  const [{ isDraggingThisRow }, drag] = ReactDnd.useDrag({
    item: { type: 'ROW', dragLayerInstanceId: renderProps.data.instanceId },
    collect: monitor => ({
      isDraggingThisRow: monitor.isDragging(),
    }),
  });

  const [{ isHovered }, drop] = ReactDnd.useDrop({
    accept: 'ROW',
    drop: (item: ReactDnd.DragObjectWithType & { dragLayerInstanceId: string }) => {
      dispatch(moveLayerInCalculation(item.dragLayerInstanceId, renderProps.data.instanceId));
    },
    collect: monitor => ({
      isHovered: monitor.isOver(),
    }),
  });

  return (
    <div className={`${styles.dragDropContainer} ${styles.dragDropContainerDragging}`}>
      {!isInsideSurface && (
        <tr
          ref={drop}
          data-qa-id="layersTableDropZone"
          className={`${styles.tableRowDropZone} ${isHovered ? styles.tableRowDropZoneHovered : ''}`}
        >
          {/* Arbitrarily large colSpan to ensure it is always full width */}
          <td colSpan={9999} />
        </tr>
      )}

      <tbody
        data-qa-id="layersTableRow"
        className={`${styles.tableRowContainer} ${isDraggingThisRow ? styles.tableRowContainerDragging : ''}`}
        {...(isInsideSurface || isOutsideSurface
          ? {}
          : isReadOnly
          ? { ref: disableDrag ? null : drag }
          : { onClick: () => onLayerClick(renderProps.data.instanceId), ref: disableDrag ? null : drag })}
      >
        <MTableBodyRow {...renderProps} />
      </tbody>
    </div>
  );
}
