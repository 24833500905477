import React, { ChangeEvent } from 'react';
import { Grid, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TabPanel } from './TabPanel';
import { TabIndexInfo } from '../../types/components/tabTypes';
import { MasterData, MasterDataMechanicalFasteners } from '../../types/store/masterDataTypes';
import styles from './LayerModal.module.scss';

export type CoreFastenersTabProps = {
  masterData: MasterData;
  clearFastenersDetails: () => void;
  fasteners: MasterDataMechanicalFasteners | null;
  setFasteners: (fasteners: MasterDataMechanicalFasteners) => void;
  fastenersCrossArea: string;
  setFastenersCrossArea: (fastenersCrossArea: string) => void;
  fastenersNumber: string;
  setFastenersNumber: (fastenersCrossArea: string) => void;
  fastenersAlpha?: string;
  fastenersThermalConductivity?: string;
  fastenersCorrectionFactor?: string;
  isLocked?: boolean;
};

export type FastenersTabProps = CoreFastenersTabProps & TabIndexInfo;

export function FastenersTab(props: FastenersTabProps) {
  const {
    masterData,
    clearFastenersDetails,
    targetIndex,
    currentTabIndex,
    fasteners,
    setFasteners,
    fastenersCrossArea,
    setFastenersCrossArea,
    fastenersNumber,
    setFastenersNumber,
    fastenersAlpha,
    fastenersThermalConductivity,
    fastenersCorrectionFactor,
    isLocked,
  } = props;

  return (
    <TabPanel
      id="layer-fasteners-details-panel"
      data-qa-id="layer-fasteners-details-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="layer-fasteners-details-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel htmlFor="modal-layer-fasteners-name" data-qa-id="layerFastenersNameLabel">
              Mechanical Fasteners Name
            </InputLabel>

            <Autocomplete
              id="modal-layer-fasteners-name"
              data-qa-id="layerFastenersNameInput"
              options={masterData.mechanicalFasteners as MasterDataMechanicalFasteners[]}
              autoHighlight
              getOptionLabel={(material: MasterDataMechanicalFasteners) => material.name}
              onChange={(event: ChangeEvent<{}>, fastener: MasterDataMechanicalFasteners | null) => {
                if (!fastener) {
                  clearFastenersDetails();
                  return;
                }

                setFasteners(fastener);
                setFastenersCrossArea(fastener.defaultCrossArea);
                setFastenersNumber(fastener.defaultNumber);
              }}
              value={fasteners}
              disabled={isLocked}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Browse catalogue..."
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // Disable browser autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={2}>
            <InputLabel htmlFor="modal-layer-fasteners-cross-area" data-qa-id="layerFastenersCrossAreaLabel">
              Cross Area
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="modal-layer-fasteners-cross-area"
                  data-qa-id="layerFastenersCrossAreaInput"
                  variant="outlined"
                  value={fastenersCrossArea}
                  disabled={isLocked}
                  placeholder="0.000"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setFastenersCrossArea(event.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerFastenersCrossAreaUnits">
                  mm
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <InputLabel htmlFor="modal-layer-fasteners-number" data-qa-id="layerFastenersNumberLabel">
              No. of Fasteners
            </InputLabel>

            <Grid container alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="modal-layer-fasteners-number"
                  data-qa-id="layerFastenersNumberInput"
                  variant="outlined"
                  value={fastenersNumber}
                  disabled={isLocked}
                  placeholder="0.000"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setFastenersNumber(event.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="layerFastenersNumberUnits">
                  /m²
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={styles.tabContentResults}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="fastenersAlphaLabel">
                  Alpha
                </Grid>

                <Grid item xs={4} data-qa-id="fastenersAlpha">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="fastenersAlphaValue">
                      {fastenersAlpha ?? '0.0'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="fastenersThermalConductivityLabel">
                  Fastener Thermal Conductivity
                </Grid>

                <Grid item xs={4} data-qa-id="fastenersThermalConductivity">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="fastenersThermalConductivityValue">
                      {fastenersThermalConductivity ?? '0.000'}
                    </div>

                    <div className={styles.tabContentResultUnit} data-qa-id="fastenersThermalConductivityUnit">
                      W/mK
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={styles.tabContentResult}>
              <Grid container>
                <Grid item xs={8} data-qa-id="fastenersCorrectionFactorLabel">
                  Correction Factor
                </Grid>

                <Grid item xs={4} data-qa-id="fastenersCorrectionFactor">
                  <div className={styles.tabContentResultValueUnitContainer}>
                    <div className={styles.tabContentResultValue} data-qa-id="fastenersCorrectionFactorValue">
                      {fastenersCorrectionFactor ?? '0.000'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
}
