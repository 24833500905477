import { AppDispatch, RootState } from '../store/store';
import { Project } from '../types/store/projectTypes';
import { callApi } from '../common/api';
import { actionTypes } from './actionTypes';

export const associateProject = (project: Project, isNewProject: boolean, shouldAssociateToAll: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      calculation: { currentCalculation },
      inactiveCalculations,
    } = getState();

    const calculationIds = shouldAssociateToAll
      ? [currentCalculation?.calculationId, ...inactiveCalculations.map(c => c.calculationId)]
      : [currentCalculation?.calculationId];

    const apiMethod = isNewProject ? 'POST' : 'PUT';
    const apiEndpoint = isNewProject ? '/Project' : `/Project/${project.id}`;

    const data = await callApi<Project>(dispatch, apiMethod, apiEndpoint, {
      calculationIds,
      ...project,
      projectUsage: project.projectUsage || undefined,
      region: project.region || undefined,
      enquiryType: project.enquiryType || undefined,
      projectStage: project.projectStage || undefined,
      projectCountyState: project.projectCountyState || undefined,
      projectStartDate: new Date(project.projectStartDate ?? new Date()).toDateString()
    });

    if (!data?.id) return;

    /**
     * Use "data" rather than the project param as the data from the API will
     * contain the generated unique ID.
     */

    dispatch({
      type: actionTypes.UPDATE_CALCULATION_PROJECT,
      payload: { project: data },
    });

    if (!shouldAssociateToAll) return;

    dispatch({
      type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_PROJECT,
      payload: { project: data },
    });
  };
};
