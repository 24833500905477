import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import styles from './LayerModal.module.scss';
import { BridgingTab, CoreBridgingTabProps } from './BridgingTab';
import { CoreFastenersTabProps, FastenersTab } from './FastenersTab';
import { CoreAirGapsTabProps, AirGapsTab } from './AirGapsTab';
import { CoreVapourTabProps, VapourTab } from './VapourTab';
import { layerNotesTabIndex, airGapsTabIndex, bridgingTabIndex, fastenersTabIndex, vapourTabIndex, LayerModalMode } from './LayerModal';
import { LayerNotesTab, CoreLayerNotesTabProps } from './LayerNotesTab';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
import { Chat } from '@material-ui/icons';

export type LayerDetailsProps = CoreBridgingTabProps &
  CoreFastenersTabProps &
  CoreAirGapsTabProps &
  CoreVapourTabProps &
  CoreLayerNotesTabProps & {
    mode: LayerModalMode;
    tabIndex: number | false;
    setTabIndex: (tabIndex: number | false) => void;
  };

export function LayerDetailsTabs(props: LayerDetailsProps) {
  const { referenceLayer } = props;

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTabIndex: number) => {
    props.setTabIndex(newTabIndex);
  };

  function renderNoteTabLabel() {
    return (
      <span data-qa-id="notesTabLabel">
        Notes
        <span className={styles.layerNotesTabLabelIcon}>
          <Chat
            classes={{
              root: styles.layerNotesTabLabelIcon,
            }}
            data-qa-id="notesTabLabelIcon"
            fontSize={'small'}
          />
        </span>
      </span>
    );
  }

  return (
    <React.Fragment>
      <Tabs
        className={styles.tabs}
        value={props.tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="layer-details-tabs"
        data-qa-id="layer-details-tabs"
      >
        <Tab
          disableRipple
          disabled={referenceLayer?.layerMaterialType === LayerMaterialTypeKeys.WellVentilatedAirspace}
          className={styles.tab}
          label="Thermal Bridging"
          id="layer-bridging-details-tab"
          aria-controls="layer-bridging-details-panel"
          data-qa-id="layer-bridging-details-tab"
        />

        <Tab
          disableRipple
          disabled={referenceLayer?.layerMaterialType === LayerMaterialTypeKeys.WellVentilatedAirspace}
          className={styles.tab}
          label="Fasteners"
          id="layer-fasteners-details-tab"
          aria-controls="layer-fasteners-details-panel"
          data-qa-id="layer-fasteners-details-tab"
        />

        <Tab
          disableRipple
          className={styles.tab}
          label="Air Gaps"
          id="layer-air-gaps-details-tab"
          aria-controls="layer-air-gaps-details-panel"
          data-qa-id="layer-air-gaps-details-tab"
        />

        <Tab
          disableRipple
          className={styles.tab}
          label="Vapour"
          id="layer-vapour-tab"
          aria-controls="layer-vapour-panel"
          data-qa-id="layer-vapour-tab"
        />

        <Tab
          disableRipple
          className={styles.tab}
          label={renderNoteTabLabel()}
          id="layer-notes-tab"
          aria-controls="layer-notes-tab-panel"
          data-qa-id="layer-notes-tab"
        />
      </Tabs>

      {props.tabIndex !== false && (
        <div className={styles.tabbedContentContainer} data-qa-id="tab-panels-container">
          {referenceLayer?.layerMaterialType !== LayerMaterialTypeKeys.WellVentilatedAirspace && (
            <BridgingTab targetIndex={bridgingTabIndex} currentTabIndex={props.tabIndex} {...props} />
          )}

          {referenceLayer?.layerMaterialType !== LayerMaterialTypeKeys.WellVentilatedAirspace && (
            <FastenersTab targetIndex={fastenersTabIndex} currentTabIndex={props.tabIndex} {...props} />
          )}

          <AirGapsTab targetIndex={airGapsTabIndex} currentTabIndex={props.tabIndex} {...props} />

          <VapourTab targetIndex={vapourTabIndex} currentTabIndex={props.tabIndex} {...props} />

          <LayerNotesTab targetIndex={layerNotesTabIndex} currentTabIndex={props.tabIndex} {...props} />
        </div>
      )}
    </React.Fragment>
  );
}
