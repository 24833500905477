import { cloneDeep } from 'lodash';

import { actionTypes } from './actionTypes';
import { AppDispatch, RootState } from '../store/store';
import { AirGapsCorrectionLevel, MasterDataLayer } from '../types/store/masterDataTypes';
import {
  ApiCalculationRequestLayer,
  ApiCalculationResponse,
  ApiCalculationResponseLayer,
  ApiLayerBridgingRequest,
  ApiLayerMechanicalFastenerRequest,
  ApiPostCalculationRequest,
  ApiPutCalculationRequest,
  Calculation,
  CalculationLayer,
  CustomReferenceLayer,
  EnvSettings,
  EnvConditionOverride,
  EnvConditionType,
  CalculationNotes,
  ConstructionRainscreenCladdingDetails,
  ApiConstructionDetailsRequest,
  ApiLayerNotesRequest,
} from '../types/store/calculationTypes';
import { LayerMaterialTypeKeys } from '../types/store/LayerMaterialTypes';
import { uuid } from '../common/uuid';
import { InactiveCalculationsState } from '../types/store/inactiveCalculationsTypes';
import { callApi } from '../common/api';
import { Method } from 'axios';
import { associateProject } from './projectActions';
import { associateContact } from './contactActions';

export async function requestCalculation<T extends ApiPutCalculationRequest | ApiPostCalculationRequest>(
  dispatch: AppDispatch,
  method: Method,
  endpoint: string,
  requestData: T
): Promise<ApiCalculationResponse | undefined> {
  if (requestData.saveCalculation) {
    dispatch({ type: actionTypes.CLEAR_CALCULATION_SAVE_SUCCESS });
  }

  const data = await callApi<ApiCalculationResponse>(dispatch, method, endpoint, requestData);

  if (data && requestData.saveCalculation) {
    dispatch({ type: actionTypes.SET_CALCULATION_SAVE_SUCCESS });
  }

  return data;
}

export function mapCurrentResponseLayerToRequest(responseLayer: ApiCalculationResponseLayer): ApiCalculationRequestLayer {
  const {
    id,
    instanceId,
    thicknessMillimetres,
    customReferenceLayer,
    layerBridging,
    mechanicalFastener,
    airGapsCorrection,
    vapourResistance,
    vapourResistivity,
    layerNotes,
    isReadOnly,
  } = responseLayer;

  if (isReadOnly) {
    return {
      isReadOnly: true,
      id: null,
      instanceId,
      thicknessMillimetres: null,
    };
  }

  return {
    id,
    instanceId,
    isReadOnly,
    thicknessMillimetres: thicknessMillimetres || null,
    customReferenceLayer:
      customReferenceLayer != null
        ? {
            name: customReferenceLayer.name,
            thermalResistance: customReferenceLayer.thermalResistance,
            thermalConductivity: customReferenceLayer.thermalConductivity,
            insideEmissivity: customReferenceLayer.insideEmissivity,
            outsideEmissivity: customReferenceLayer.outsideEmissivity,
            layerMaterialType: customReferenceLayer.layerMaterialType,
            isBlank: customReferenceLayer.isBlank,
            isCustom: true,
          }
        : undefined,
    layerNotes:
      layerNotes != null
        ? {
            notes: layerNotes.notes,
          }
        : undefined,
    layerBridging:
      layerBridging != null
        ? {
            id: layerBridging.id,
            bridgeWidthMillimetres: layerBridging.bridgeWidthMillimetres,
            centresDistanceMillimetres: layerBridging.centresDistanceMillimetres,
            nonBridgeHeightMillimetres: layerBridging.nonBridgeHeightMillimetres,
          }
        : undefined,
    mechanicalFastener:
      mechanicalFastener != null
        ? {
            id: mechanicalFastener.id,
            crossSectionalAreaMillimetresSquared: mechanicalFastener.crossSectionalAreaMillimetresSquared,
            fasteningsPerMetreSquared: mechanicalFastener.fasteningsPerMetreSquared,
          }
        : undefined,
    ...(airGapsCorrection?.calculatedCorrectionFactor && airGapsCorrection?.overrideCorrectionFactor
      ? {
          airGapsCorrectionOverride: {
            overriddenCalculatedCorrectionFactor: airGapsCorrection?.calculatedCorrectionFactor,
            overrideCorrectionFactor: airGapsCorrection?.overrideCorrectionFactor,
          },
        }
      : {}),
    vapourResistance,
    vapourResistivity,
  };
}

export const openCalculation = (calculationId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await Promise.resolve(); // Give the UI time to update, before doing the expensive `cloneDeep`

    const previousActiveCalculation = cloneDeep(getState().calculation.currentCalculation);

    const data = await callApi<ApiCalculationResponse>(dispatch, 'GET', `/Calculation/${calculationId}`);

    dispatch({
      type: actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE,
      payload: {
        previousActiveCalculation,
      },
    });

    const inactiveCalculations: InactiveCalculationsState = getState().inactiveCalculations;

    dispatch({
      type: actionTypes.SET_ACTIVE_CALCULATION_DATA,
      payload: {
        newCalculationData: data!,
        shouldUseExistingCalculationId: true,
        order: inactiveCalculations.length + 1,
      },
    });
  };
};

export const registerCalculation = (businessUnitId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const wallApplicationTypeUniqueReferenceId = 1; // This refers to the unique reference ID of the Application Type. These are hardcoded in the Calculation Engine. 1 means Wall.

    const requestData: ApiPostCalculationRequest = {
      businessUnitId,
      applicationDetails: {
        id: wallApplicationTypeUniqueReferenceId,
      },
      layers: [],
      saveCalculation: true,
    };

    const data = await requestCalculation<ApiPostCalculationRequest>(dispatch, 'POST', '/Calculation', requestData);

    if (!data) return;

    const previousActiveCalculation: Calculation = cloneDeep(getState().calculation.currentCalculation)!;

    dispatch({
      type: actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE,
      payload: {
        previousActiveCalculation,
      },
    });

    await Promise.resolve(); // Yield between dispatches

    // Ensure new calculations are always last in the tab list
    const order = getState().inactiveCalculations.length + 1;

    dispatch({
      type: actionTypes.REGISTER_CALCULATION,
      payload: {
        calculation: data,
        order,
      },
    });
  };
};

export const closeActiveCalculation = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const previousActiveCalculation: Calculation = cloneDeep(getState().calculation.currentCalculation)!;
    const inactiveCalculations: InactiveCalculationsState = cloneDeep(getState().inactiveCalculations);

    // Re-evaluate all "order" values to prevent there being gaps
    const newInactiveCalculations = [...inactiveCalculations]
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((c, index) => ({ ...c, order: index + 1 }));

    let newActiveCalculation: Calculation | undefined;

    if (newInactiveCalculations.length > 0) {
      // Move to the next tab if one exists, otherwise go to previous tab
      newActiveCalculation =
        newInactiveCalculations.find(c => c.order === previousActiveCalculation.order) ||
        newInactiveCalculations.find(c => c.order === previousActiveCalculation.order - 1);

      // Remove newly-active calculation from the inactive calculations array
      const indexOfCalculationToRemove = newInactiveCalculations.findIndex(c => c.calculationId === newActiveCalculation?.calculationId);

      newInactiveCalculations.splice(indexOfCalculationToRemove, 1);
    }

    dispatch({
      type: actionTypes.CLOSE_ACTIVE_CALCULATION,
      payload: {
        newActiveCalculation,
        newInactiveCalculations,
      },
    });
  };
};

export const handleAutomaticLayers = (
  currentCalculation: Calculation,
  request: ApiPutCalculationRequest
): ApiPutCalculationRequest => {
  const currentPitchedRoofDetails = currentCalculation.constructionDetails?.pitchedRoofWithLoftDetails;
  const requestPitchedRoofDetailsDetails = request.constructionDetails?.pitchedRoofWithLoftDetails;
  const currentFloorInsulationDetails = currentCalculation.constructionDetails?.floorInsulationDetails;
  const requestFloorInsulationDetails = request.constructionDetails?.floorInsulationDetails;

  // Adding pitchedRoofWithLoftDetails
  if (currentPitchedRoofDetails == null && requestPitchedRoofDetailsDetails != null) {
    return {
      ...request,
      layers: [
        ...request.layers,
        {
          instanceId: requestPitchedRoofDetailsDetails.loftLayerInstanceId,
          id: null,
          thicknessMillimetres: null,
          isReadOnly: true,
        },
      ],
    };
  }
  // Removing pitchedRoofWithLoftDetails
  if (currentPitchedRoofDetails != null && requestPitchedRoofDetailsDetails == null) {
    const loftLayerId = currentPitchedRoofDetails.loftLayerInstanceId;

    return {
      ...request,
      layers: request.layers.filter(layer => layer.instanceId !== loftLayerId),
    };
  }

  // Adding floorInsulationDetails
  if (currentFloorInsulationDetails?.insulationLayerInstanceId == null && requestFloorInsulationDetails?.insulationLayerInstanceId) {
    return {
      ...request,
      layers: [
        ...request.layers,
        {
          instanceId: requestFloorInsulationDetails.insulationLayerInstanceId,
          id: null,
          thicknessMillimetres: null,
          isReadOnly: true,
        },
      ],
    };
  }
  // Removing floorInsulationDetails
  if (currentFloorInsulationDetails?.insulationLayerInstanceId != null && !requestFloorInsulationDetails?.insulationLayerInstanceId) {
    const insulationLayerId = currentFloorInsulationDetails.insulationLayerInstanceId;

    return {
      ...request,
      layers: request.layers.filter(layer => layer.instanceId !== insulationLayerId),
    };
  }

  return request;
};

export const addConstructionDetailsToCalculation = (
  applicationTypeId: number,
  constructionDetails: ApiConstructionDetailsRequest | undefined,
  rainscreenCladdingDetails: ConstructionRainscreenCladdingDetails | undefined,
  calculationNotes: CalculationNotes | undefined,
  productSectorId: number | undefined,
  projectInsulationVolumeM2: number | undefined
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCalculation = getState().calculation.currentCalculation;

    if (!currentCalculation) return;

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: { id: applicationTypeId },
      layers: [...currentCalculation.layers.map(mapCurrentResponseLayerToRequest)],
      calculationNotes,
      constructionDetails,
      rainscreenCladdingDetails,
      envSettings: currentCalculation.envSettings,
      saveCalculation: true,
      project: currentCalculation.project,
      productSectorId: productSectorId,
      projectInsulationVolumeM2: projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const requestDataWithLoftDetails = handleAutomaticLayers(currentCalculation, requestData);

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestDataWithLoftDetails
    );

    if (!data) return;

    const payloadCalculation = {
      ...data,
      order: currentCalculation.order,
      layers: data.layers.map(
        (layer: ApiCalculationResponseLayer): CalculationLayer => ({
          ...layer,
        })
      ),
    };

    dispatch({
      type: actionTypes.ADD_CONSTRUCTION_DETAILS_TO_CALCULATION,
      payload: {
        calculation: payloadCalculation,
      },
    });
  };
};

export const addLayerToCalculation = (
  instanceId: string,
  layer: MasterDataLayer | null,
  thicknessMillimetres: string | null,
  customReferenceLayer?: CustomReferenceLayer,
  layerBridging?: ApiLayerBridgingRequest,
  mechanicalFastener?: ApiLayerMechanicalFastenerRequest,
  previousAirGapsCorrectionLevelOverride?: AirGapsCorrectionLevel,
  nextAirGapsCorrectionLevelOverride?: AirGapsCorrectionLevel,
  layerNotes?: ApiLayerNotesRequest,
  vapourResistance?: string | null,
  vapourResistivity?: string | null,
  isCalculationInterim: boolean = false
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCalculation = getState().calculation.currentCalculation;

    if (!currentCalculation) return;

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: currentCalculation.applicationDetails,
      calculationNotes: currentCalculation.calculationNotes,
      constructionDetails: currentCalculation.constructionDetails,
      rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
      layers: [
        ...currentCalculation.layers.map(mapCurrentResponseLayerToRequest),
        {
          id: (customReferenceLayer == null ? layer?.id : null) || null,
          instanceId: instanceId,
          thicknessMillimetres: thicknessMillimetres || null,
          customReferenceLayer,
          layerNotes,
          layerBridging,
          mechanicalFastener,
          ...(nextAirGapsCorrectionLevelOverride &&
          (layer?.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
            layer?.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation ||
            customReferenceLayer?.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
            customReferenceLayer?.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation)
            ? {
                airGapsCorrectionOverride: {
                  overriddenCalculatedCorrectionFactor: previousAirGapsCorrectionLevelOverride || AirGapsCorrectionLevel.Level0,
                  overrideCorrectionFactor: nextAirGapsCorrectionLevelOverride,
                },
              }
            : {}),
          vapourResistance: vapourResistance,
          vapourResistivity: vapourResistivity,
        },
      ],
      envSettings: currentCalculation.envSettings,
      saveCalculation: !isCalculationInterim,
      project: currentCalculation.project,
      productSectorId: currentCalculation.productSectorId,
      projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    const payloadCalculation = {
      ...data,
      layers: data.layers,
      project: currentCalculation.project,
    };

    if (isCalculationInterim) {
      dispatch({
        type: actionTypes.ADD_LAYER_TO_INTERIM_CALCULATION,
        payload: {
          calculation: {
            ...payloadCalculation,
            order: currentCalculation.order,
          },
        },
      });
    } else {
      dispatch({
        type: actionTypes.ADD_LAYER_TO_CALCULATION,
        payload: {
          calculation: {
            order: currentCalculation.order,
            ...payloadCalculation,
          },
        },
      });
    }
  };
};

export const editLayerInCalculation = (
  instanceId: string,
  layer: MasterDataLayer | null,
  thicknessMillimetres: string | null,
  customReferenceLayer?: CustomReferenceLayer,
  layerBridging?: ApiLayerBridgingRequest,
  mechanicalFastener?: ApiLayerMechanicalFastenerRequest,
  previousAirGapsCorrectionLevelOverride?: AirGapsCorrectionLevel,
  nextAirGapsCorrectionLevelOverride?: AirGapsCorrectionLevel,
  layerNotes?: ApiLayerNotesRequest,
  vapourResistance?: string | null,
  vapourResistivity?: string | null,
  isCalculationInterim: boolean = false
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCalculation = getState().calculation.currentCalculation;

    if (!currentCalculation) return;

    const layers = cloneDeep(currentCalculation.layers);

    const index = layers.findIndex((layer: CalculationLayer) => layer.instanceId === instanceId);

    const nextLayers = [...layers.map(mapCurrentResponseLayerToRequest)];

    nextLayers[index] = {
      id: (customReferenceLayer == null ? layer?.id : null) || null,
      instanceId,
      thicknessMillimetres: thicknessMillimetres || null,
      layerNotes,
      customReferenceLayer,
      layerBridging,
      mechanicalFastener,
      ...(nextAirGapsCorrectionLevelOverride &&
      (layer?.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
        layer?.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation ||
        customReferenceLayer?.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
        customReferenceLayer?.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation)
        ? {
            airGapsCorrectionOverride: {
              overriddenCalculatedCorrectionFactor: previousAirGapsCorrectionLevelOverride || AirGapsCorrectionLevel.Level0,
              overrideCorrectionFactor: nextAirGapsCorrectionLevelOverride,
            },
          }
        : {}),
      vapourResistance: vapourResistance,
      vapourResistivity: vapourResistivity,
    };

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: currentCalculation.applicationDetails,
      calculationNotes: currentCalculation.calculationNotes,
      constructionDetails: currentCalculation.constructionDetails,
      rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
      layers: nextLayers,
      envSettings: currentCalculation.envSettings,
      saveCalculation: !isCalculationInterim,
      project: currentCalculation.project,
      productSectorId: currentCalculation.productSectorId,
      projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    const payloadCalculation = {
      ...data,
      layers: data.layers.map(
        (layer: ApiCalculationResponseLayer): CalculationLayer => ({
          ...layer,
        })
      ),
    };

    if (isCalculationInterim) {
      dispatch({
        type: actionTypes.EDIT_LAYER_IN_INTERIM_CALCULATION,
        payload: {
          calculation: {
            ...payloadCalculation,
            order: currentCalculation.order,
          },
        },
      });
    } else {
      dispatch({
        type: actionTypes.EDIT_LAYER_IN_CALCULATION,
        payload: {
          calculation: {
            ...payloadCalculation,
            order: currentCalculation.order,
          },
        },
      });
    }
  };
};

export const clearInterimCalculation = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.CLEAR_INTERIM_CALCULATION,
    });
  };
};

export const moveLayerInCalculation = (movedLayerInstanceId: string, targetLayerInstanceId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (movedLayerInstanceId === targetLayerInstanceId) return;

    const { currentCalculation } = getState().calculation;

    if (!currentCalculation) return;

    const layers = cloneDeep(currentCalculation.layers);

    const movedLayerIndex = layers.findIndex((layer: CalculationLayer) => layer.instanceId === movedLayerInstanceId);
    const movedLayer = layers[movedLayerIndex];

    // Remove the moved layer from it's current position
    layers.splice(movedLayerIndex, 1);

    const targetLayerIndex = layers.findIndex((layer: CalculationLayer) => layer.instanceId === targetLayerInstanceId);

    // Insert the moved layer before the target layer
    if (targetLayerIndex >= 0) {
      layers.splice(targetLayerIndex, 0, movedLayer);
    } else {
      /**
       * If we can't find the target layer then the target is the Outside Surface,
       * so move the moved layer to the end of the layers list.
       */
      layers.push(movedLayer);
    }

    /**
     * We will update the layer order locally immediately while we wait for the API to
     * respond to prevent strange-looking behaviour where the moved layer pings back to
     * its original location for the interim period before the API responds.
     */
    dispatch({
      type: actionTypes.INTERIM_MOVE_LAYER_IN_CALCULATION,
      payload: {
        calculation: {
          ...currentCalculation,
          layers,
        },
      },
    });

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: currentCalculation.applicationDetails,
      calculationNotes: currentCalculation.calculationNotes,
      constructionDetails: currentCalculation.constructionDetails,
      rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
      layers: [...layers.map(mapCurrentResponseLayerToRequest)],
      envSettings: currentCalculation.envSettings,
      saveCalculation: true,
      project: currentCalculation.project,
      productSectorId: currentCalculation.productSectorId,
      projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    dispatch({
      type: actionTypes.MOVE_LAYER_IN_CALCULATION,
      payload: {
        calculation: {
          ...data,
          order: currentCalculation.order,
        },
      },
    });
  };
};

export const duplicateLayerInCalculation = (instanceId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCalculation: Calculation | null = getState().calculation.currentCalculation;

    if (!currentCalculation) return;

    const layers = cloneDeep(currentCalculation.layers);

    const index = layers.findIndex((layer: CalculationLayer) => layer.instanceId === instanceId);
    const layer = layers.find((layer: CalculationLayer) => layer.instanceId === instanceId)!;

    const clonedLayer = {
      ...cloneDeep(layer),
      instanceId: uuid(),
    };

    if (index >= 0) {
      // Insert new layer after selected one
      layers.splice(index + 1, 0, clonedLayer);
    }

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: currentCalculation.applicationDetails,
      calculationNotes: currentCalculation.calculationNotes,
      constructionDetails: currentCalculation.constructionDetails,
      rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
      layers: layers.map(mapCurrentResponseLayerToRequest),
      envSettings: currentCalculation.envSettings,
      saveCalculation: true,
      project: currentCalculation.project,
      productSectorId: currentCalculation.productSectorId,
      projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    dispatch({
      type: actionTypes.DUPLICATE_LAYER_IN_CALCULATION,
      payload: {
        calculation: {
          ...data,
          order: currentCalculation.order,
          layers: data.layers.map(
            (layer: ApiCalculationResponseLayer): CalculationLayer => ({
              ...layer,
            })
          ),
        },
      },
    });
  };
};

export const lockCalculation = (calculationId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    let isCurrentCalculation = true;
    let calculation: Calculation | null = getState().calculation.currentCalculation;
    if (calculationId !== calculation?.calculationId) {
      calculation = cloneDeep(getState().inactiveCalculations.find(c => c.calculationId === calculationId))!;
      isCurrentCalculation = false;
    }

    if (!calculation) return;

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: calculation.businessUnitId,
      applicationDetails: calculation.applicationDetails,
      calculationNotes: calculation.calculationNotes,
      constructionDetails: calculation.constructionDetails,
      rainscreenCladdingDetails: calculation.rainscreenCladdingDetails,
      layers: calculation.layers.map(mapCurrentResponseLayerToRequest),
      envSettings: calculation.envSettings,
      saveCalculation: true,
      project: calculation.project,
      locked: true,
      productSectorId: calculation.productSectorId,
      projectInsulationVolumeM2: calculation.projectInsulationVolumeM2,
      contact: calculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(dispatch, 'PUT', `/Calculation/${calculationId}`, requestData);

    if (!data) return;

    dispatch({
      type: actionTypes.LOCK_CALCULATION,
      payload: {
        isCurrentCalculation,
        calculation: data,
      },
    });
  };
};

export const removeLayerFromCalculation = (instanceId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCalculation: Calculation | null = getState().calculation.currentCalculation;

    if (!currentCalculation) return;

    const layers = cloneDeep(currentCalculation.layers);

    const index = layers.findIndex((layer: CalculationLayer) => layer.instanceId === instanceId);

    if (index >= 0) {
      layers.splice(index, 1);
    }

    const requestData: ApiPutCalculationRequest = {
      businessUnitId: currentCalculation.businessUnitId,
      applicationDetails: currentCalculation.applicationDetails,
      calculationNotes: currentCalculation.calculationNotes,
      constructionDetails: currentCalculation.constructionDetails,
      rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
      layers: layers.map(mapCurrentResponseLayerToRequest),
      envSettings: currentCalculation.envSettings,
      saveCalculation: true,
      project: currentCalculation.project,
      productSectorId: currentCalculation.productSectorId,
      projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
      contact: currentCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${currentCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    dispatch({
      type: actionTypes.REMOVE_LAYER_FROM_CALCULATION,
      payload: {
        calculation: {
          ...data,
          order: currentCalculation.order,
          layers: data.layers.map(
            (layer: ApiCalculationResponseLayer): CalculationLayer => ({
              ...layer,
            })
          ),
        },
      },
    });
  };
};

export const changeActiveCalculation = (calculationId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const previousActiveCalculation: Calculation = cloneDeep(getState().calculation.currentCalculation)!;
    const newActiveCalculation: Calculation = cloneDeep(getState().inactiveCalculations.find(c => c.calculationId === calculationId))!;

    dispatch({
      type: actionTypes.CHANGE_ACTIVE_CALCULATION,
      payload: {
        previousActiveCalculation,
        newActiveCalculation,
      },
    });
  };
};

export const createNewCalculation = (businessUnitId: number) => {
  return async (dispatch: AppDispatch) => {
    await dispatch(registerCalculation(businessUnitId));
  };
};

export const copyCalculation = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentState = getState();
    const previousActiveCalculation: Calculation = cloneDeep(currentState.calculation.currentCalculation)!;
    const { inactiveCalculations } = currentState;

    await dispatch(registerCalculation(previousActiveCalculation.businessUnitId));

    await dispatch({
      type: actionTypes.SET_ACTIVE_CALCULATION_DATA,
      payload: {
        newCalculationData: { ...previousActiveCalculation, ...{ locked: false } },
        shouldUseExistingCalculationId: false,
        /**
         * To put the copied calculation at the end of the list, we want the order to be 1 greater
         * than the combined length of all open calculations (including the active one).
         */
        order: inactiveCalculations.length + 2,
      },
    });

    const newActiveCalculation: Calculation = cloneDeep(getState().calculation.currentCalculation)!;

    await requestCalculation<ApiPutCalculationRequest>(dispatch, 'PUT', `/Calculation/${newActiveCalculation.calculationId}`, {
      businessUnitId: newActiveCalculation.businessUnitId,
      applicationDetails: newActiveCalculation.applicationDetails,
      calculationNotes: newActiveCalculation.calculationNotes,
      constructionDetails: newActiveCalculation.constructionDetails,
      rainscreenCladdingDetails: newActiveCalculation.rainscreenCladdingDetails,
      layers: [...newActiveCalculation.layers.map(mapCurrentResponseLayerToRequest)],
      envSettings: newActiveCalculation.envSettings,
      saveCalculation: true,
      locked: false,
      project: newActiveCalculation.project,
      contact: newActiveCalculation.contact ?? null,
    });

    /**
     * It may look like the new calculation has copied over the project correctly by this
     * point, but we need to make an association in the database for this to persist when
     * the calculation is closed and re-opened.
     */
    if (newActiveCalculation.project) {
      await dispatch(associateProject(newActiveCalculation.project, false, false));
    }
    if (newActiveCalculation.contact) {
      await dispatch(associateContact(newActiveCalculation.contact, false, false));
    }
  };
};

export const addEnvConditionOverrides = (envConditionOverrides: EnvConditionOverride[]) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const mergedOverrides = cloneDeep([...(interimCalculation.envSettings?.envConditionOverrides || [])]);
    envConditionOverrides.forEach(o => {
      const existingOverride = mergedOverrides.find(m => m.envConditionType === o.envConditionType);
      if (existingOverride) {
        o.months.forEach(om => {
          const existingMonth = existingOverride.months.find(em => em.id === om.id);
          if (existingMonth) {
            existingMonth.value = om.value;
          } else {
            existingOverride.months.push({ ...om });
          }
        });
      } else {
        mergedOverrides.push({ ...o, months: [...o.months] });
      }
    });

    const updatedEnvSettings = {
      ...interimCalculation.envSettings!,
      envConditionOverrides: mergedOverrides,
    };

    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

export const clearEnvConditionOverrides = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const updatedEnvSettings = {
      ...interimCalculation.envSettings!,
      envConditionOverrides: [],
    };

    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

export const setBuildingRegion = (buildingRegionId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const updatedOverrides = [...(interimCalculation.envSettings?.envConditionOverrides || [])].filter(
      o =>
        o.envConditionType !== EnvConditionType.ExternalRelativeHumidity &&
        o.envConditionType !== EnvConditionType.ExternalTemperature &&
        o.envConditionType !== EnvConditionType.InternalRelativeHumidity
    );

    const updatedEnvSettings: EnvSettings = {
      ...interimCalculation.envSettings,
      buildingRegionId: buildingRegionId,
      envConditionOverrides: updatedOverrides,
    };
    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

export const setBuildingType = (buildingTypeId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const updatedOverrides = [...(interimCalculation.envSettings?.envConditionOverrides || [])].filter(
      o => o.envConditionType !== EnvConditionType.InternalRelativeHumidity
    );

    const updatedEnvSettings: EnvSettings = {
      ...interimCalculation.envSettings,
      buildingTypeId: buildingTypeId,
      envConditionOverrides: updatedOverrides,
    };
    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

export const setRiskLevel = (riskLevelId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const updatedOverrides = [...(interimCalculation.envSettings?.envConditionOverrides || [])].filter(
      o =>
        o.envConditionType !== EnvConditionType.ExternalRelativeHumidity &&
        o.envConditionType !== EnvConditionType.ExternalTemperature &&
        o.envConditionType !== EnvConditionType.InternalRelativeHumidity
    );

    const updatedEnvSettings: EnvSettings = {
      ...interimCalculation.envSettings,
      riskLevelId: riskLevelId,
      envConditionOverrides: updatedOverrides,
    };
    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

export const setInternalTemperature = (internalTemperature: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

    if (!interimCalculation) return;

    const updatedOverrides = [...(interimCalculation.envSettings?.envConditionOverrides || [])].filter(
      o => o.envConditionType !== EnvConditionType.InternalRelativeHumidity && o.envConditionType !== EnvConditionType.InternalTemperature
    );

    const updatedEnvSettings: EnvSettings = {
      ...interimCalculation.envSettings,
      internalTemperature: internalTemperature,
      envConditionOverrides: updatedOverrides,
    };
    await editEnvSettings(dispatch, getState, updatedEnvSettings);
  };
};

const editEnvSettings = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  updatedEnvSettings: EnvSettings
) => {
  const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);

  if (!interimCalculation) return;

  dispatch({
    type: actionTypes.EDIT_ENV_SETTINGS,
    payload: {
      calculation: {
        ...interimCalculation,
        envSettings: { ...updatedEnvSettings },
      },
    },
  });

  if (
    updatedEnvSettings.buildingRegionId &&
    updatedEnvSettings.buildingTypeId &&
    updatedEnvSettings.riskLevelId &&
    updatedEnvSettings.internalTemperature &&
    !isNaN(Number(updatedEnvSettings.internalTemperature))
  ) {
    const requestData: ApiPutCalculationRequest = {
      businessUnitId: interimCalculation.businessUnitId,
      applicationDetails: interimCalculation.applicationDetails,
      calculationNotes: interimCalculation.calculationNotes,
      constructionDetails: interimCalculation.constructionDetails,
      rainscreenCladdingDetails: interimCalculation.rainscreenCladdingDetails,
      envSettings: updatedEnvSettings,
      layers: interimCalculation.layers.map(mapCurrentResponseLayerToRequest),
      saveCalculation: false,
      project: interimCalculation.project,
      productSectorId: interimCalculation.productSectorId,
      projectInsulationVolumeM2: interimCalculation.projectInsulationVolumeM2,
      contact: interimCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${interimCalculation.calculationId}`,
      requestData
    );

    if (!data) return;

    dispatch({
      type: actionTypes.EDIT_ENV_CONDITIONS,
      payload: {
        calculation: {
          ...data,
          order: interimCalculation.order,
        },
      },
    });
  }
};

export const saveEnvConditions = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const interimCalculation = getState().calculation.interimCalculation;

    if (!interimCalculation) return;

    const requestData: ApiPutCalculationRequest = {
      ...interimCalculation,
      layers: interimCalculation.layers.map(mapCurrentResponseLayerToRequest),
      saveCalculation: true,
      contact: interimCalculation.contact ?? null,
    };

    const data = await requestCalculation<ApiPutCalculationRequest>(
      dispatch,
      'PUT',
      `/Calculation/${interimCalculation.calculationId}`,
      requestData
    );

    if (!data) return;
    dispatch({
      type: actionTypes.SAVE_ENV_CONDITIONS,
      payload: {
        calculation: {
          ...data,
          order: interimCalculation.order,
        },
      },
    });
  };
};
