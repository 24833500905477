import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputLabel, Select, MenuItem, Paper, Button } from '@material-ui/core';
import MaterialTable, { MTableCell, Column } from 'material-table';
import { TabPanel } from '../LayerModal/TabPanel';
import { EnvConditionsEditorModal } from './EnvConditionsEditorModal';
import { TabIndexInfo } from '../../types/components/tabTypes';
import {
  buildingRegionSelector,
  buildingTypeSelector,
  riskLevelSelector,
  envConditionsSelector,
  envSettingsSelector,
} from '../../store/selectors';
import { MasterDataBuildingRegion, MasterDataBuildingType, MasterDataRiskLevel } from '../../types/store/masterDataTypes';
import { EnvCondition, EnvSettings, EnvConditionOverride } from '../../types/store/calculationTypes';
import {
  addEnvConditionOverrides,
  setBuildingRegion,
  setBuildingType,
  setInternalTemperature,
  setRiskLevel,
  clearEnvConditionOverrides,
} from '../../actions/calculationActions';

import styles from './CondensationRiskModal.module.scss';
import tableStyles from './EnvConditionsTab.module.scss';
import { DebouncedDecimalInput } from '../FormComponents/DebouncedDecimalInput/DebouncedDecimalInput';

export type CoreEnvConditionsTabProps = {
  setIsInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
};

export type EnvConditionsTabProps = CoreEnvConditionsTabProps & TabIndexInfo;

type State = {
  isEditorOpen: boolean;
  monthEditKey: number;
};

type KeyedColumn<RowData extends object> = Column<RowData> & {
  key: number;
};

const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'short' });
const monthColumns: KeyedColumn<EnvCondition>[] = new Array(12).fill({}).map((_, i) => ({
  field: `months[${i}].value`,
  key: i + 1,
  title: dateTimeFormat.format(new Date(1, i, 1)),
}));
const columns = [
  ...[],
  {
    field: 'name',
    key: 0,
    render: (rowdata: EnvCondition) => <span className={tableStyles.tableCellContainer}>{`${rowdata.name} (${rowdata.unit})`}</span>,
    title: '',
  },
  ...monthColumns,
];

export function EnvConditionsTab(props: EnvConditionsTabProps) {
  const { currentTabIndex, setIsInvalid, targetIndex } = props;
  const dispatch = useDispatch();
  const buildingRegions: MasterDataBuildingRegion[] = useSelector(buildingRegionSelector);
  const buildingTypes: MasterDataBuildingType[] = useSelector(buildingTypeSelector);
  const riskLevels: MasterDataRiskLevel[] = useSelector(riskLevelSelector);
  const environmentalConditions: EnvCondition[] | undefined = useSelector(envConditionsSelector);
  const envSettings: EnvSettings | undefined = useSelector(envSettingsSelector);

  const [state, setState] = React.useState<State>({
    isEditorOpen: false,
    monthEditKey: 1,
  });

  React.useEffect(() => {
    if (!envSettings) {
      dispatch(setRiskLevel(4));
      dispatch(setInternalTemperature('20'));
    }
  }, [dispatch, envSettings]);

  React.useEffect(() => {
    if (
      envSettings?.buildingRegionId &&
      envSettings?.buildingTypeId &&
      envSettings?.internalTemperature &&
      !isNaN(Number(envSettings?.internalTemperature))
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  }, [setIsInvalid, envSettings]);

  const handleBuildingRegionChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<any>) => {
      dispatch(setBuildingRegion(value));
    },
    [dispatch]
  );

  const handleBuildingTypeChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<any>) => {
      dispatch(setBuildingType(value));
    },
    [dispatch]
  );

  const handleInternalTemperatureChange = React.useCallback(
    (value: string) => {
      dispatch(setInternalTemperature(value));
    },
    [dispatch]
  );

  const handleRiskLevelChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<any>) => {
      dispatch(setRiskLevel(value));
    },
    [dispatch]
  );

  const getEnvConditions = React.useCallback(() => (environmentalConditions || []).map(ec => ({ ...ec })), [environmentalConditions]);

  const handleRowClick = React.useCallback(
    (key: number) => () => {
      if (key > 0) {
        setState(prevState => ({
          ...prevState,
          isEditorOpen: true,
          monthEditKey: key,
        }));
      }
    },
    []
  );

  const handleEditorClose = () => {
    setState({
      ...state,
      isEditorOpen: false,
    });
  };

  const handleEditorSubmit = React.useCallback(
    (envConditionOverrides: EnvConditionOverride[]) => {
      dispatch(addEnvConditionOverrides(envConditionOverrides));
    },
    [dispatch]
  );

  const handleResetClick = React.useCallback(() => {
    dispatch(clearEnvConditionOverrides());
  }, [dispatch]);

  const handleMoveToNextMonth = () => {
    setState(prevState => {
      const { monthEditKey } = prevState;
      if (monthEditKey < 12) {
        return {
          ...prevState,
          monthEditKey: monthEditKey + 1,
        };
      }
      return prevState;
    });
  };

  const handleMoveToPrevMonth = () => {
    setState(prevState => {
      const { monthEditKey } = prevState;
      if (monthEditKey > 1) {
        return {
          ...prevState,
          monthEditKey: monthEditKey - 1,
        };
      }
      return prevState;
    });
  };

  return (
    <TabPanel
      id="env-conditions-panel"
      data-qa-id="env-conditions-panel"
      targetIndex={targetIndex}
      currentTabIndex={currentTabIndex}
      aria-labelledby="env-conditions-tab"
    >
      <div className={styles.tabContent}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel data-qa-id="buildingRegionLabel" id="env-conditions-building-region-label">
              Building region
            </InputLabel>

            <Select
              fullWidth
              data-qa-id="buildingRegionInput"
              id="env-conditions-building-region"
              value={envSettings?.buildingRegionId || ''}
              onChange={handleBuildingRegionChange}
              variant="outlined"
              disabled={props.isLocked}
            >
              {buildingRegions.map(buildingRegion => {
                return (
                  <MenuItem key={buildingRegion.id} value={buildingRegion.id}>
                    {buildingRegion.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel data-qa-id="buildingTypeLabel" id="env-conditions-building-type-label">
              Internal humidity class
            </InputLabel>

            <Select
              fullWidth
              data-qa-id="buildingTypeInput"
              id="env-conditions-building-type"
              value={envSettings?.buildingTypeId || ''}
              onChange={handleBuildingTypeChange}
              variant="outlined"
              disabled={props.isLocked}
            >
              {buildingTypes.map(buildingType => {
                return (
                  <MenuItem key={buildingType.id} value={buildingType.id}>
                    {buildingType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel data-qa-id="riskLevelLabel" id="env-conditions-risk-level-label">
              Risk level
            </InputLabel>

            <Select
              fullWidth
              data-qa-id="riskLevelInput"
              id="env-conditions-risk-level"
              value={envSettings?.riskLevelId || ''}
              onChange={handleRiskLevelChange}
              variant="outlined"
              disabled={props.isLocked}
            >
              {riskLevels.map(riskLevel => {
                return (
                  <MenuItem key={riskLevel.id} value={riskLevel.id}>
                    {riskLevel.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel data-qa-id="internalTemperatureLabel" id="env-conditions-internal-temperature-label">
              Internal temp
            </InputLabel>

            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={2}>
                <DebouncedDecimalInput
                  data-qa-id="internalTemperatureInput"
                  delay={400}
                  onChange={handleInternalTemperatureChange}
                  precision={3}
                  textFieldId="env-conditions-internal-temperature"
                  value={envSettings?.internalTemperature || ''}
                  disabled={props.isLocked}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={styles.inputUnit} data-qa-id="internalTemperatureUnits">
                  °C
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columns}
              data={getEnvConditions()}
              options={{
                sorting: false,
                showTitle: false,
                search: false,
                paging: false,
                toolbar: false,
                grouping: false,
                selection: false,
                draggable: false, // Relates to draggable columns, not rows
              }}
              components={{
                Container: renderProps => (
                  <div className={tableStyles.tableContainer}>
                    <Paper {...renderProps} className={tableStyles.table} variant="outlined" />
                  </div>
                ),
                Cell: renderProps => {
                  const {
                    columnDef: { key },
                  } = renderProps;
                  return (
                    <td className={tableStyles.tableCellContainer} onClick={handleRowClick(key)}>
                      <MTableCell component="span" {...renderProps} />
                    </td>
                  );
                },
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'Please select a Building Region and Internal Humidity Class',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="flex-end">
              <Grid item xs={2}>
                <Button
                  data-qa-id="resetEnvConditionOverridesButton"
                  disabled={props.isLocked || !envSettings?.envConditionOverrides || envSettings.envConditionOverrides.length === 0}
                  variant="outlined"
                  onClick={handleResetClick}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {state.isEditorOpen && (
        <EnvConditionsEditorModal
          envConditions={getEnvConditions()}
          monthKey={state.monthEditKey}
          onClose={handleEditorClose}
          onMoveToNextMonth={handleMoveToNextMonth}
          onMoveToPrevMonth={handleMoveToPrevMonth}
          onSubmit={handleEditorSubmit}
          isLocked={props.isLocked}
        />
      )}
    </TabPanel>
  );
}
