import React from 'react';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { Info, Lock } from '@material-ui/icons';
import classnames from 'classnames';

import { BusinessUnitModel } from '../../types/store/userProfileTypes';
import styles from './CalculationResults.module.scss';

type Props = {
  uValue: string;
  uValue2DP: string;
  totalThermalResistance: string;
  isTotalCorrectionFactorBelowThreshold: boolean;
  condensationResult: string;
  isLocked?: boolean;
  businessUnit?: BusinessUnitModel | null;
  isLoadingMasterData?: boolean;
};

export function CalculationResults(props: Props) {
  return (
    <div className={styles.resultsContainer}>
      <div className={styles.results}>
        <Grid container>
          <Grid item xs={3}>
            <div className={`${styles.result} ${styles.businessUnitPanel}`}>
              {props.isLoadingMasterData === true
                ? <div className={styles.resultValueText}>
                    <CircularProgress />
                  </div>
                : <>
                    <div className={styles.resultTitle}>
                      Business Unit
                    </div>
                    <div className={styles.resultValueText}>
                      {props.businessUnit
                        ? <Tooltip title={
                            <>
                              Business Unit {props.businessUnit.businessUnitId}<br />
                              {props.businessUnit.countryName}
                            </>}
                          >
                            <span>
                              {props.businessUnit.name}
                            </span>
                          </Tooltip>
                        : '—'}
                    </div>
                  </>}
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={`${styles.result} ${styles.resultCondensation}`}>
              <div data-qa-id="condensationTitle" className={styles.resultTitle}>
                Condensation Risk
              </div>
              <div
                data-qa-id="condensationValue"
                className={classnames(styles.resultValue, { [styles.hasCondensation]: props.condensationResult === 'Condensation' })}
              >
                {props.condensationResult || '—'}
              </div>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={`${styles.result} ${styles.resultTotalThermalResistance}`}>
              <div data-qa-id="totalThermalResistanceTitle" className={styles.resultTitle}>
                Total Thermal Resistance
              </div>
              <div data-qa-id="totalThermalResistanceValue" className={styles.resultValue}>
                {props.totalThermalResistance || '—'}
              </div>
              <div data-qa-id="totalThermalResistanceUnit" className={styles.resultUnit}>
                m²K/W
              </div>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={styles.result}>
              <div data-qa-id="uValueTitle" className={styles.resultTitle}>
                U-Value
              </div>
              <div data-qa-id="uValueValue" className={styles.resultValue}>
                {props.uValue || '—'}

                {props.uValue2DP && (
                  <span data-qa-id="uValueValueSub" className={styles.resultValueSub}>
                    ({props.uValue2DP})
                  </span>
                )}

                {props.isTotalCorrectionFactorBelowThreshold && (
                  <span data-qa-id="uValueValueInfo" className={styles.resultValueTotalCorrectionInfo}>
                    <Tooltip
                      data-qa-id="uValueValueInfoTooltip"
                      title="Correction factors have been neglected in this calculation, as they are less than 3% of the uncorrected U-value when combined."
                      arrow
                    >
                      <Info data-qa-id="uValueValueInfoIcon" fontSize={'small'} />
                    </Tooltip>
                  </span>
                )}
                {props.isLocked && (
                  <span data-qa-id="uValueCalculationLocked" className={styles.resultValueCalculationLocked}>
                    <Tooltip
                      data-qa-id="uValueCalculationLockedTooltip"
                      title="This calculation is locked as it has already been exported"
                      arrow
                    >
                      <Lock data-qa-id="uValueCalculationLockedIcon" fontSize={'small'} />
                    </Tooltip>
                  </span>
                )}
              </div>
              <div data-qa-id="uValueUnit" className={styles.resultUnit}>
                W/m²K
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
